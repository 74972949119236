import React, { useState, useEffect, useContext, useRef } from "react";
import Geocode from "react-geocode";
import { Modal, Button } from 'react-bootstrap';
import axios from "axios";
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';
import {FormattedMessage, useIntl} from 'react-intl';
import MapContainer from "./mapcontainer";
import { RouteContext } from "../../../contexts/route.context";


const Step3Lessor = () => {
  const intl = useIntl();
  const cookies = new Cookies();
  Geocode.setApiKey("AIzaSyDJCS46EQMFDDPG0MFD34yO651MzTCtpZ4");
  const { baseURL, apiHeaders, LogOutUser, getMe, me, getAllSwiftCodes} = useContext(RouteContext);
  let otpCode = cookies.get('code');
  const [bannerIndex, setBannerIndex] = useState(0);
  const [photoUrl, setPhotoUrl] = useState();
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [mapShow, setMapShow] = useState(false);
  const [addressLatLng, setAddressLatLng] = useState();
  const [ibanNum, setIbanNum] = useState();
  const [addressName, setAddressName] = useState();
  const [loading, setLoading] = useState(true);
  const [swiftBankLogo, setSwiftBankLogo] = useState();
  
  const [swiftCodes, setSwiftCodes] = useState();
  const [userSwiftBank, setUserSwiftBank] = useState();
  const [showSwiftModal, setShowSwiftModal] = useState(false);

  const getSwift = async () => {
    setSwiftCodes(await getAllSwiftCodes());
  }

  useEffect(() => {
    getMe();
    getSwift();
    if(me?.name !== "" && !otpCode){
      window.location.href = "/";
    }
    else{
      setLoading(false)
    }
  }, []);

  useEffect(()=>{
    if(me) {
      console.log(me)
      if(me?.swift_code?.image){
        setUserSwiftBank(me?.swift_code)
      }
    }
  },[])

  useEffect(() => {
    if(addressLatLng) {
      Geocode.fromLatLng(addressLatLng.latVal, addressLatLng.lngVal).then(
        (response) => {
          const address = response.results[0].formatted_address;
          setAddressName(address);
        },
        (error) => {
          console.error(error);
        }
      );
    }

  }, [addressLatLng]);

  const changeSwiftBankLogo = () => {
    setShowSwiftModal(true);
    // swiftCodes.allSwiftCodes.map((data) => {
    //   if(data.id === val.value){
    //     setUserSwiftBank(data.image);
    //   }
    // })
  }

  const selectNewSwiftCode = (val) => {
    // swiftCodes?.allSwiftCodes.map((data) => {
    //   if(data.id === val.value){
    //     setUserSwiftBank(data);
    //   }
    // })
    setUserSwiftBank(val);
    setShowSwiftModal(false);
  }

  const onOpenMap = () => {
    setMapShow(true);
    if(addressLatLng === undefined) {
      navigator.geolocation.getCurrentPosition(function(position) {
        setAddressLatLng({
          latVal: position.coords.latitude,
          lngVal: position.coords.longitude,
        })
      });
    }
  }


  const handleSelectBanner = (selectedIndex, e) => {
    setBannerIndex(selectedIndex);
  };


  // profile photo
  const onChangeAvatar = (e) => {
    let reader = new FileReader();
    let url = reader.readAsDataURL(e.target.files[0])
    reader.onloadend = function (e) {
      setPhotoUrl([reader.result]);
    }.bind();
  }


  const onChangeIban = (e) => {
    // e.target.value = e.target.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
    setIbanNum(e.target.value);
  }


  // map pin location
  const onPinMyLocation = () => {
    navigator.geolocation.getCurrentPosition(function(position) {
      setAddressLatLng({
        latVal: position.coords.latitude,
        lngVal: position.coords.longitude,
      })
    });
    setMapShow(false);
  }

  const onChangeTerms = async (e) => {
    let stat = e.target.checked;
    setAgreeTerms(!agreeTerms);

    let result = await axios({
      url: baseURL,
      method: "post",
      headers: apiHeaders,
      data: {
        query: `
          mutation {
            agreementVerify(status:${stat}){
              id
              message
              status
            }
          }
        `
      }
    });
  }


  const onSubmitUser = async (e) => {
    e.preventDefault();
    let x = ibanNum.replaceAll(' ', '');
    if(x !== 22){
      window.alert(
        "IBAN Number should be 24 characters"
      );
    }
    else{
      const formData = new FormData(e.target);
      let formdataObj = Object.fromEntries(formData.entries());
  
  
      if(addressLatLng) {
        const query = `
          mutation($input: UpdateUserInput!) {
            updateUser(input:$input){
              name
              phone
              role {
                ar
                en
              }
              email
              gender
              id
              created_at
            }
          }
        `;
  
        const ibanN = ibanNum.replaceAll(' ', '');
        let ibanNumFinal = "SA" + ibanN;
  
        let input = {
          avatar: null,
          name: formdataObj.name,
          email: formdataObj.email,
          address: formdataObj.address,
          gender: formdataObj.gender === "female" ? `female` : `male`,
          latitude: addressLatLng.latVal,
          longitude: addressLatLng.lngVal,
          bank_name: formdataObj.bank_name,
          bank_account_num: formdataObj.bank_account_num,
          bank_account_name: formdataObj.bank_account_name,
          swift_code_id: userSwiftBank.id,
          iban_number: ibanNumFinal,
        };
    
  
        const operations = JSON.stringify({ query, variables: { input } });
        formData.append("operations", operations);
  
        const map = {
            "0": ["variables.input.avatar"],
        };
        formData.append("map", JSON.stringify(map));
  
        const file = formdataObj.avatar ? formdataObj.avatar : null;
        formData.append("0", file);
  
  
        let result = await axios({
            url: baseURL,
            headers: apiHeaders,
            method: "post",
            data: formData
        });
  
        if(result.data.data) {
          Swal.fire({
            title: 'Registration Complete',
            icon: 'success',
            confirmButtonColor: '#FF5833',
          }).then((e) => {
            cookies.remove('code', { path: '/' });
            window.location.href = "/";
          });
        }
        else {
  
          if(result.data.errors[0].extensions.category === "validation") {
            let errorMsg = result.data.errors[0].extensions.validation;
            let errorMsgArray = new Array();
            Object.keys(errorMsg).map((key) => {
              errorMsgArray.push(errorMsg[key][0]);
            });
  
  
            Swal.fire({
              title: 'Registration Failed',
              text: errorMsgArray.toString(),
              icon: 'warning',
              confirmButtonColor: '#FF5833',
            }).then((e) => {
              window.location.reload();
            });
          }
          else {
            Swal.fire({
              title: 'Registration Failed',
              text: result.data.errors[0].message,
              icon: 'warning',
              confirmButtonColor: '#FF5833',
            }).then((e) => {
              window.location.reload();
            });
          }
  
        }
      }
      else {
        Swal.fire({
          title: 'Registration Failed',
          text: 'Choose location on map',
          icon: 'warning',
          confirmButtonColor: '#FF5833',
        });
      }
    }
    
  }



  return (
    loading ?
    <div>

    </div>
    :
      
    <div>
      <div className="container-fluid no-header-div">
        <div className="row">
          {/* <div className="col-12 col-lg-6"> */}
            {/* <div className="reg-info-div"> */}
              {/* <Carousel
                controls={false}
                activeIndex={bannerIndex} 
                onSelect={handleSelectBanner}
              >
                <Carousel.Item>
                  <img alt="" src="assets/images/logo.svg" className="carousel-logo"/>
                  <img
                    className="d-block w-100"
                    src="assets/images/reginfo.png"
                    alt="First slide"
                  />
                  <Carousel.Caption>
                    <h3 className="fw700 cwhite ">
                      <FormattedMessage 
                        id="rentdonefinal.text"
                        defaultMessage="Renting Process Made Easy"
                        description="Renting Process Made Easy"
                      />
                    </h3> */}
                    {/* <p className="fw500 cwhite">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p> */}
                  {/* </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                <img alt="" src="assets/images/logo.svg" className="carousel-logo"/>
                  <img
                    className="d-block w-100"
                    src="assets/images/reginfo.png"
                    alt="Second slide"
                  />
                  <Carousel.Caption>
                    <h3 className="fw700 cwhite ">
                      <FormattedMessage 
                        id="rentdonefinal.text"
                        defaultMessage="Renting Process Made Easy"
                        description="Renting Process Made Easy"
                      />
                    </h3> */}
                    {/* <p className="fw500 cwhite">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p> */}
                  {/* </Carousel.Caption>
                </Carousel.Item>
              </Carousel> */}
            {/* </div> */}
          {/* </div> */}
          <div className="d-flex justify-content-center">
            
            <form className="reg-input-form-div" onSubmit={onSubmitUser}>
              <h1 className="cblue fw700 mb-4">
                <FormattedMessage 
                  id="information.text"
                  defaultMessage="Information"
                  description="Information"
                />
              </h1>
              <p className="fw700 cred mb-4 fs18">
                <FormattedMessage 
                  id="step3.text"
                  defaultMessage="Step <3> of 3"
                  description="Step <3> of 3"
                />
              </p>
   

              <div className="reg-photo-wrapper mb-4">
                <div className="reg-photo-div">
                  {photoUrl ? (
                    <img alt="" src={photoUrl} className="imgenclose"/>
                  ) : (
                    <svg width="52" height="55" viewBox="0 0 52 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M26.0008 22.3998C28.8651 22.3998 31.6121 21.262 33.6375 19.2366C35.6629 17.2112 36.8008 14.4641 36.8008 11.5998C36.8008 8.73547 35.6629 5.98844 33.6375 3.96305C31.6121 1.93766 28.8651 0.799805 26.0008 0.799805C23.1364 0.799805 20.3894 1.93766 18.364 3.96305C16.3386 5.98844 15.2008 8.73547 15.2008 11.5998C15.2008 14.4641 16.3386 17.2112 18.364 19.2366C20.3894 21.262 23.1364 22.3998 26.0008 22.3998ZM0.800781 54.7998C0.800781 51.4905 1.4526 48.2136 2.71902 45.1562C3.98544 42.0988 5.84165 39.3208 8.18169 36.9807C10.5217 34.6407 13.2998 32.7845 16.3572 31.518C19.4146 30.2516 22.6915 29.5998 26.0008 29.5998C29.3101 29.5998 32.587 30.2516 35.6444 31.518C38.7018 32.7845 41.4798 34.6407 43.8199 36.9807C46.1599 39.3208 48.0161 42.0988 49.2826 45.1562C50.549 48.2136 51.2008 51.4905 51.2008 54.7998H0.800781Z" fill="#AAAAAA"/>
                    </svg>
                  )}
                </div>

                <input type="file" name="avatar" accept="image/*" id="photo-btn" onChange={onChangeAvatar} hidden/>
                <label htmlFor="photo-btn" className="btn btn-change-photo">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" style={{position:"relative", top:"-2px"}} xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.5 7.50016C2.5 7.05814 2.67559 6.63421 2.98816 6.32165C3.30072 6.00909 3.72464 5.8335 4.16667 5.8335H4.94167C5.21599 5.83354 5.48608 5.76587 5.72797 5.63649C5.96987 5.50711 6.17609 5.32002 6.32833 5.09183L7.005 4.07516C7.15725 3.84697 7.36347 3.65988 7.60536 3.5305C7.84726 3.40113 8.11735 3.33346 8.39167 3.3335H11.6083C11.8827 3.33346 12.1527 3.40113 12.3946 3.5305C12.6365 3.65988 12.8428 3.84697 12.995 4.07516L13.6717 5.09183C13.8239 5.32002 14.0301 5.50711 14.272 5.63649C14.5139 5.76587 14.784 5.83354 15.0583 5.8335H15.8333C16.2754 5.8335 16.6993 6.00909 17.0118 6.32165C17.3244 6.63421 17.5 7.05814 17.5 7.50016V15.0002C17.5 15.4422 17.3244 15.8661 17.0118 16.1787C16.6993 16.4912 16.2754 16.6668 15.8333 16.6668H4.16667C3.72464 16.6668 3.30072 16.4912 2.98816 16.1787C2.67559 15.8661 2.5 15.4422 2.5 15.0002V7.50016Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12.5 10.8335C12.5 11.4965 12.2366 12.1324 11.7678 12.6013C11.2989 13.0701 10.663 13.3335 10 13.3335C9.33696 13.3335 8.70107 13.0701 8.23223 12.6013C7.76339 12.1324 7.5 11.4965 7.5 10.8335C7.5 10.1705 7.76339 9.53457 8.23223 9.06573C8.70107 8.59689 9.33696 8.3335 10 8.3335C10.663 8.3335 11.2989 8.59689 11.7678 9.06573C12.2366 9.53457 12.5 10.1705 12.5 10.8335V10.8335Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </label>
              </div>


              <div className="mb-4">
                <div className="d-flex">
                  <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M11 9.5C12.1935 9.5 13.3381 9.0259 14.182 8.18198C15.0259 7.33807 15.5 6.19347 15.5 5C15.5 3.80653 15.0259 2.66193 14.182 1.81802C13.3381 0.974106 12.1935 0.5 11 0.5C9.80653 0.5 8.66193 0.974106 7.81802 1.81802C6.97411 2.66193 6.5 3.80653 6.5 5C6.5 6.19347 6.97411 7.33807 7.81802 8.18198C8.66193 9.0259 9.80653 9.5 11 9.5ZM0.5 23C0.5 21.6211 0.771591 20.2557 1.29927 18.9818C1.82694 17.7079 2.60036 16.5504 3.57538 15.5754C4.55039 14.6004 5.70791 13.8269 6.98182 13.2993C8.25574 12.7716 9.62112 12.5 11 12.5C12.3789 12.5 13.7443 12.7716 15.0182 13.2993C16.2921 13.8269 17.4496 14.6004 18.4246 15.5754C19.3996 16.5504 20.1731 17.7079 20.7007 18.9818C21.2284 20.2557 21.5 21.6211 21.5 23H0.5Z" fill="#1036CE"/>
                  </svg>
                  <p className="cblue fw600 fs18 ms-3 mb-2">
                    <FormattedMessage 
                      id="fullname.text"
                      defaultMessage="Full Name"
                      description="Full Name"
                    />
                  </p>
                </div>

                <input type="text" name="name" className="form-control reg-input-form" placeholder={intl.formatMessage({
                  id: 'enterfullname.text',
                  defaultMessage: "Enter full name",
                  description: "Enter full name",
                })} required/>
              </div>


              <div className="mb-4">
                <div className="d-flex">
                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.00391 8.826L14.9994 14.823L26.9949 8.826C26.9505 8.06167 26.6155 7.34323 26.0586 6.81784C25.5017 6.29245 24.765 5.99986 23.9994 6H5.99941C5.23378 5.99986 4.49708 6.29245 3.94017 6.81784C3.38327 7.34323 3.04831 8.06167 3.00391 8.826Z" fill="#1036CE"/>
                    <path d="M27 12.1772L15 18.1772L3 12.1772V21.0002C3 21.7959 3.31607 22.559 3.87868 23.1216C4.44129 23.6842 5.20435 24.0002 6 24.0002H24C24.7956 24.0002 25.5587 23.6842 26.1213 23.1216C26.6839 22.559 27 21.7959 27 21.0002V12.1772Z" fill="#1036CE"/>
                  </svg>
                  <p className="cblue fw600 fs18 ms-3 mb-2">
                    <FormattedMessage 
                      id="emailadoptional.text"
                      defaultMessage="Email address"
                      description="Email address"
                    />
                  </p>
                </div>

                <input type="email" name="email" className="form-control reg-input-form" placeholder={intl.formatMessage({
                  id: 'enteremail.text',
                  defaultMessage: "Enter email address",
                  description: "Enter email address",
                })} required/>
              </div>


              <div className="mb-4">
                <div className="d-flex">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24ZM3.498 9.0405C4.05494 7.44557 5.04868 6.03916 6.366 4.9815C6.768 5.595 7.461 6 8.25 6C8.84674 6 9.41903 6.23705 9.84099 6.65901C10.2629 7.08097 10.5 7.65326 10.5 8.25V9C10.5 9.79565 10.8161 10.5587 11.3787 11.1213C11.9413 11.6839 12.7044 12 13.5 12C14.2956 12 15.0587 11.6839 15.6213 11.1213C16.1839 10.5587 16.5 9.79565 16.5 9C16.4998 8.32863 16.7247 7.67659 17.1389 7.1482C17.5531 6.61981 18.1325 6.24562 18.7845 6.0855C20.216 7.723 21.0034 9.82503 21 12C21 12.51 20.958 13.0125 20.8755 13.5H19.5C18.7044 13.5 17.9413 13.8161 17.3787 14.3787C16.8161 14.9413 16.5 15.7044 16.5 16.5V19.7955C15.1324 20.5868 13.58 21.0023 12 21V18C12 17.2044 11.6839 16.4413 11.1213 15.8787C10.5587 15.3161 9.79565 15 9 15C8.20435 15 7.44129 14.6839 6.87868 14.1213C6.31607 13.5587 6 12.7956 6 12C6.00027 11.2905 5.74906 10.6039 5.291 10.062C4.83293 9.52022 4.19766 9.15828 3.498 9.0405Z" fill="#1036CE"/>
                  </svg>
                  <p className="cblue fw600 fs18 ms-3 mb-2">
                    <FormattedMessage 
                      id="address.text"
                      defaultMessage="Address"
                      description="Address"
                    />
                  </p>
                </div>

                {/* <input type="text" name="address" className="form-control reg-input-form" placeholder="Enter Address Name" onClick={() => setMapShow(true)} /> */}
                <textarea className="form-control reg-input-form" name="address" defaultValue={addressName ? addressName : ""} placeholder={intl.formatMessage({
                  id: 'enteraddress.text',
                  defaultMessage: "Enter Address Name",
                  description: "Enter Address Name",
                })} rows={2} onClick={onOpenMap}></textarea>
              </div>


              <div className="mb-4">
                <div className="d-flex">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M24 12C24 15.1826 22.7357 18.2348 20.4853 20.4853C18.2348 22.7357 15.1826 24 12 24C8.8174 24 5.76516 22.7357 3.51472 20.4853C1.26428 18.2348 0 15.1826 0 12C0 8.8174 1.26428 5.76516 3.51472 3.51472C5.76516 1.26428 8.8174 0 12 0C15.1826 0 18.2348 1.26428 20.4853 3.51472C22.7357 5.76516 24 8.8174 24 12ZM13.5 6C13.5 6.39782 13.342 6.77936 13.0607 7.06066C12.7794 7.34196 12.3978 7.5 12 7.5C11.6022 7.5 11.2206 7.34196 10.9393 7.06066C10.658 6.77936 10.5 6.39782 10.5 6C10.5 5.60218 10.658 5.22064 10.9393 4.93934C11.2206 4.65804 11.6022 4.5 12 4.5C12.3978 4.5 12.7794 4.65804 13.0607 4.93934C13.342 5.22064 13.5 5.60218 13.5 6ZM10.5 10.5C10.1022 10.5 9.72064 10.658 9.43934 10.9393C9.15804 11.2206 9 11.6022 9 12C9 12.3978 9.15804 12.7794 9.43934 13.0607C9.72064 13.342 10.1022 13.5 10.5 13.5V18C10.5 18.3978 10.658 18.7794 10.9393 19.0607C11.2206 19.342 11.6022 19.5 12 19.5H13.5C13.8978 19.5 14.2794 19.342 14.5607 19.0607C14.842 18.7794 15 18.3978 15 18C15 17.6022 14.842 17.2206 14.5607 16.9393C14.2794 16.658 13.8978 16.5 13.5 16.5V12C13.5 11.6022 13.342 11.2206 13.0607 10.9393C12.7794 10.658 12.3978 10.5 12 10.5H10.5Z" fill="#1036CE"/>
                  </svg>
                  <p className="cblue fw600 fs18 ms-3 mb-2">
                    <FormattedMessage 
                      id="gender.text"
                      defaultMessage="Gender"
                      description="Gender"
                    />
                  </p>
                </div>

                <select className="form-select reg-select-form" name="gender">
                  <option value="">
                    {intl.formatMessage({
                      id: 'selectgender.text',
                      defaultMessage: 'Select a gender',
                      description: 'Select a gender',
                    })}
                  </option>
                  <option value="male">
                    {intl.formatMessage({
                      id: 'male.text',
                      defaultMessage: 'Male',
                      description: 'Male',
                    })}
                  </option>
                  <option value="female">
                    {intl.formatMessage({
                      id: 'female.text',
                      defaultMessage: 'Female',
                      description: 'Female',
                    })}
                  </option>
                </select>
              </div>


              {/* 
              


              <div className="mb-3">
                <div className="d-flex">
                  <svg width="25" height="25" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 6C5.20435 6 4.44129 6.31607 3.87868 6.87868C3.31607 7.44129 3 8.20435 3 9V10.5H27V9C27 8.20435 26.6839 7.44129 26.1213 6.87868C25.5587 6.31607 24.7956 6 24 6H6Z" fill="#1036CE"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M27 13.5H3V21C3 21.7956 3.31607 22.5587 3.87868 23.1213C4.44129 23.6839 5.20435 24 6 24H24C24.7956 24 25.5587 23.6839 26.1213 23.1213C26.6839 22.5587 27 21.7956 27 21V13.5ZM6 19.5C6 19.1022 6.15804 18.7206 6.43934 18.4393C6.72064 18.158 7.10218 18 7.5 18H9C9.39782 18 9.77936 18.158 10.0607 18.4393C10.342 18.7206 10.5 19.1022 10.5 19.5C10.5 19.8978 10.342 20.2794 10.0607 20.5607C9.77936 20.842 9.39782 21 9 21H7.5C7.10218 21 6.72064 20.842 6.43934 20.5607C6.15804 20.2794 6 19.8978 6 19.5ZM13.5 18C13.1022 18 12.7206 18.158 12.4393 18.4393C12.158 18.7206 12 19.1022 12 19.5C12 19.8978 12.158 20.2794 12.4393 20.5607C12.7206 20.842 13.1022 21 13.5 21H15C15.3978 21 15.7794 20.842 16.0607 20.5607C16.342 20.2794 16.5 19.8978 16.5 19.5C16.5 19.1022 16.342 18.7206 16.0607 18.4393C15.7794 18.158 15.3978 18 15 18H13.5Z" fill="#1036CE"/>
                  </svg>
                  <p className="cblue fw600 fs18 ms-3 mb-2">
                    <FormattedMessage 
                      id="interaccnum.text"
                      defaultMessage="International Bank Account Number"
                      description="International Bank Account Number"
                    />
                  </p>
                </div>

                <input type="number" name="bank_account_num" className="form-control reg-input-form" placeholder={intl.formatMessage({
                  id: 'interaccnum.text',
                  defaultMessage: "Account number",
                  description: "Account number",
                })} required/>
              </div>

               <div className="mb-3">
                <div className="d-flex">
                  <svg width="25" height="25" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 6C5.20435 6 4.44129 6.31607 3.87868 6.87868C3.31607 7.44129 3 8.20435 3 9V10.5H27V9C27 8.20435 26.6839 7.44129 26.1213 6.87868C25.5587 6.31607 24.7956 6 24 6H6Z" fill="#1036CE"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M27 13.5H3V21C3 21.7956 3.31607 22.5587 3.87868 23.1213C4.44129 23.6839 5.20435 24 6 24H24C24.7956 24 25.5587 23.6839 26.1213 23.1213C26.6839 22.5587 27 21.7956 27 21V13.5ZM6 19.5C6 19.1022 6.15804 18.7206 6.43934 18.4393C6.72064 18.158 7.10218 18 7.5 18H9C9.39782 18 9.77936 18.158 10.0607 18.4393C10.342 18.7206 10.5 19.1022 10.5 19.5C10.5 19.8978 10.342 20.2794 10.0607 20.5607C9.77936 20.842 9.39782 21 9 21H7.5C7.10218 21 6.72064 20.842 6.43934 20.5607C6.15804 20.2794 6 19.8978 6 19.5ZM13.5 18C13.1022 18 12.7206 18.158 12.4393 18.4393C12.158 18.7206 12 19.1022 12 19.5C12 19.8978 12.158 20.2794 12.4393 20.5607C12.7206 20.842 13.1022 21 13.5 21H15C15.3978 21 15.7794 20.842 16.0607 20.5607C16.342 20.2794 16.5 19.8978 16.5 19.5C16.5 19.1022 16.342 18.7206 16.0607 18.4393C15.7794 18.158 15.3978 18 15 18H13.5Z" fill="#1036CE"/>
                  </svg>
                  <p className="cblue fw600 fs18 ms-3 mb-2">
                    <FormattedMessage 
                      id="bankaccname.text"
                      defaultMessage="Bank Account Name"
                      description="Bank Account Name"
                    />
                  </p>
                </div>
              </div>

                <input type="text" name="bank_account_name" className="form-control reg-input-form" placeholder={intl.formatMessage({
                  id: 'bankaccname.text',
                  defaultMessage: "Enter bank account name",
                  description: "Enter bank account name",
                })} required/>
              
               */}

              <div className="mb-4">
              <div className="d-flex"> 
                <svg width="25" height="25" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"> 
                <path d="M6 6C5.20435 6 4.44129 6.31607 3.87868 6.87868C3.31607 7.44129 3 8.20435 3 9V10.5H27V9C27 8.20435 26.6839 7.44129 26.1213 6.87868C25.5587 6.31607 24.7956 6 24 6H6Z" fill="#1036CE"/> <path fillRule="evenodd" clipRule="evenodd" d="M27 13.5H3V21C3 21.7956 3.31607 22.5587 3.87868 23.1213C4.44129 23.6839 5.20435 24 6 24H24C24.7956 24 25.5587 23.6839 26.1213 23.1213C26.6839 22.5587 27 21.7956 27 21V13.5ZM6 19.5C6 19.1022 6.15804 18.7206 6.43934 18.4393C6.72064 18.158 7.10218 18 7.5 18H9C9.39782 18 9.77936 18.158 10.0607 18.4393C10.342 18.7206 10.5 19.1022 10.5 19.5C10.5 19.8978 10.342 20.2794 10.0607 20.5607C9.77936 20.842 9.39782 21 9 21H7.5C7.10218 21 6.72064 20.842 6.43934 20.5607C6.15804 20.2794 6 19.8978 6 19.5ZM13.5 18C13.1022 18 12.7206 18.158 12.4393 18.4393C12.158 18.7206 12 19.1022 12 19.5C12 19.8978 12.158 20.2794 12.4393 20.5607C12.7206 20.842 13.1022 21 13.5 21H15C15.3978 21 15.7794 20.842 16.0607 20.5607C16.342 20.2794 16.5 19.8978 16.5 19.5C16.5 19.1022 16.342 18.7206 16.0607 18.4393C15.7794 18.158 15.3978 18 15 18H13.5Z" fill="#1036CE"/> </svg> 
                <p className="cblue fw600 fs18 ms-3 mb-2"> <FormattedMessage id="ibannum.text" defaultMessage="IBAN Number" description="IBAN Number" /> </p> 
              </div>
              <div className="right swiftselect">
              {
                userSwiftBank && 
                <img className="swiftbanklogo" alt="bank-logo" src={userSwiftBank.image}/>
              }
                <input type="text" className="form-control" name="swiftcode" placeholder={intl.formatMessage({
                      id: 'selectswiftcode.text',
                      defaultMessage: 'Enter Bank Name (BIC)',
                      description: 'Enter Bank Name (BIC)',
                    })} defaultValue={me && intl.locale === "en" ? userSwiftBank?.bank_name : userSwiftBank?.bank_name_ar} onClick={()=>changeSwiftBankLogo()} readOnly required/>
                {/* <select className="form-select" name="swiftcode" defaultValue={me && me?.swift_code?.id} onChange={(e)=>changeSwiftBankLogo(e.target)} required>
                    <option value={""} disabled={me?.swift_code?.id ? true : false}>
                      {intl.formatMessage({
                        id: 'selectswiftcode.text',
                        defaultMessage: 'Enter Bank Name (BIC)',
                        description: 'Enter Bank Name (BIC)',
                      })}
                    </option>
                    {
                      me?.swift_code?.id && 
                    <option value={me?.swift_code?.id}>
                      {intl.locale === "en" ? me?.swift_code?.bank_name?.toUpperCase() : me?.swift_code?.bank_name_ar?.toUpperCase()}
                    </option>
                    }
                    {
                      swiftCodes?.allSwiftCodes?.map((swift, index)=>{
                        return(
                          <option key={index} value={swift.id}>
                            {intl.locale === "en" ? swift?.bank_name?.toUpperCase() : swift?.bank_name_ar?.toUpperCase()}
                          </option>
                        )
                      })
                    }
                  </select>
                  {
                    swiftBankLogo && 
                    <div className="swiftlogo-container">
                      <img className="swiftbanklogo mr-4" alt="bank-logo" src={swiftBankLogo}/>
                    </div>
                  } */}

                  {/* <input type="text" name="bank_account_name" className="form-control" defaultValue={me && me.bank_account_name} placeholder={intl.formatMessage({
                    id: 'swiftcode.text',
                    defaultMessage: "Swift Code",
                    description: "Swift Code",
                })} /> */}
              </div>

                {/* <input type="text" name="bank_name" className="form-control reg-input-form" placeholder={intl.formatMessage({
                  id: 'enterbankname.text',
                  defaultMessage: "Enter bank name",
                  description: "Enter bank name",
                })} required/> */}


              </div>

             

              <div className="mb-3">
                <div className="d-flex">
                  <svg width="25" height="25" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 6C5.20435 6 4.44129 6.31607 3.87868 6.87868C3.31607 7.44129 3 8.20435 3 9V10.5H27V9C27 8.20435 26.6839 7.44129 26.1213 6.87868C25.5587 6.31607 24.7956 6 24 6H6Z" fill="#1036CE"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M27 13.5H3V21C3 21.7956 3.31607 22.5587 3.87868 23.1213C4.44129 23.6839 5.20435 24 6 24H24C24.7956 24 25.5587 23.6839 26.1213 23.1213C26.6839 22.5587 27 21.7956 27 21V13.5ZM6 19.5C6 19.1022 6.15804 18.7206 6.43934 18.4393C6.72064 18.158 7.10218 18 7.5 18H9C9.39782 18 9.77936 18.158 10.0607 18.4393C10.342 18.7206 10.5 19.1022 10.5 19.5C10.5 19.8978 10.342 20.2794 10.0607 20.5607C9.77936 20.842 9.39782 21 9 21H7.5C7.10218 21 6.72064 20.842 6.43934 20.5607C6.15804 20.2794 6 19.8978 6 19.5ZM13.5 18C13.1022 18 12.7206 18.158 12.4393 18.4393C12.158 18.7206 12 19.1022 12 19.5C12 19.8978 12.158 20.2794 12.4393 20.5607C12.7206 20.842 13.1022 21 13.5 21H15C15.3978 21 15.7794 20.842 16.0607 20.5607C16.342 20.2794 16.5 19.8978 16.5 19.5C16.5 19.1022 16.342 18.7206 16.0607 18.4393C15.7794 18.158 15.3978 18 15 18H13.5Z" fill="#1036CE"/>
                  </svg>
                  <p className="cblue fw600 fs18 ms-3 mb-2">
                    <FormattedMessage 
                      id="ibannum.text"
                      defaultMessage="IBAN Number"
                      description="IBAN Number"
                    />
                  </p>
                </div>

                {/* <input type="text" name="iban_number" className="form-control reg-input-form" placeholder="Enter IBAN number"/> */}
                
                <div className="input-group iban-grp mb-3">
                  <span className="input-group-text" id="basic-addon1">SA</span>
                  <input type="text" maxLength={27} className="form-control mb-0" name="iban_number" placeholder={intl.formatMessage({
                    id: 'ibannum.text',
                    defaultMessage: "Enter IBAN number",
                    description: "Enter IBAN number",
                  })} defaultValue={ibanNum} onChange={onChangeIban} required/>
                </div>
              </div>


              <div className="form-check reg-checkbox-form">
                <input className="form-check-input" type="checkbox" defaultValue="" id="termsCheckbox" onChange={onChangeTerms} />
                <label className="form-check-label fw500 cblack text-center" htmlFor="termsCheckbox">
                  <FormattedMessage 
                    id="iagreeto.text"
                    defaultMessage="I agree to the"
                    description="I agree to the"
                  /> <a className="cred" href="/terms" target="_blank">
                  <FormattedMessage 
                    id="termscond.text"
                    defaultMessage="Terms and Conditions"
                    description="Terms and Conditions"
                  />
                  </a>
                </label>
              </div>


              <button type="submit" className="btn btn-verification mb-4 w-100 mt-5" disabled={agreeTerms === true ? false : true}>
                <FormattedMessage 
                  id="register.text"
                  defaultMessage="Register"
                  description="Register"
                />
              </button>


              <p className="fw500 cgray text-center">
                <FormattedMessage 
                  id="alreadyhaveacc.text"
                  defaultMessage="Already have an account?"
                  description="Already have an account?"
                />
                <a className="cblue" href="/login"> 
                <FormattedMessage 
                  id="login.text"
                  defaultMessage="Login"
                  description="Login"
                /></a>
              </p>

            </form>

            <Modal className="modalSwift" show={showSwiftModal} onHide={() => setShowSwiftModal(false)}>
              <Modal.Header className="flex-row-reverse justify-end">
                {/* <Modal.Title className="m-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className={`m-2`} width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
                  <FormattedMessage 
                      id="information.text"
                      defaultMessage="Information"
                      description="Information"
                    />  
                </Modal.Title> */}
                <Button className="justify-self-end" variant="secondary" onClick={()=>setShowSwiftModal(false)}>Close</Button>
              </Modal.Header>
              <Modal.Body>
                <div className={"swiftContainer"}>
                  {
                    swiftCodes?.allSwiftCodes?.map((swift, index)=>{
                      return(
                          <div className="swiftlist" key={index} value={swift.id} onClick={()=>selectNewSwiftCode(swift)}>
                            <img className="swiftbanklogoselect" alt="bank-logo" src={swift.image}/>
                            <p className="mytext ">{intl.locale === "en" ? swift?.bank_name?.toUpperCase() : swift?.bank_name_ar?.toUpperCase()}</p>
                          </div>
                      )
                    })
                  }
                </div>
              </Modal.Body>
            </Modal>



            {/* map modal */}
            <Modal className="modal-map-wrapper" show={mapShow} onHide={() => setMapShow(false)}>
              <Modal.Body className="modal-postshipment-div modal-map">

                <div className="map-modal-div">
                  <MapContainer setAddressLatLng={setAddressLatLng}/>
                </div>

                <div style={{marginTop:"30px"}}>
                  <button className="btn btn-sm btn-secondary" onClick={onPinMyLocation}>Pin My Location</button>
                </div>

                <div style={{marginTop:"50px", marginBottom:"50px"}}>
                  <p>
                    Drag pin to your desired location
                  </p>
                  <button className="btn btn-primary" onClick={() => setMapShow(false)}>Pick Location</button>
                </div>
              </Modal.Body>
            </Modal>


          </div>
        </div>
      </div>
    </div>
  )
}

export default Step3Lessor;

import React, { useState, useEffect, useContext, useRef } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import Cookies from 'universal-cookie';
import {FormattedMessage, useIntl} from 'react-intl';
import { QRCode } from 'react-qrcode-logo';
import renterLogo from '../../assets/images/qrlogo.png';
import { RouteContext } from "../../contexts/route.context";
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import { isMobile } from 'react-device-detect';

const NewQrProduct = () => {
    const cookies = new Cookies();
    const history = useHistory();
    const { getViewPost } = useContext(RouteContext);
    const intl = useIntl();
    const { id } = useParams();
    const logoSize = 800;
    const [downloadQRSize, setDownloadQRSize] = useState(1200);
    const [myProduct, setMyProduct] = useState();
    const [testm, setTestM] = useState(true);
    const [trueDL, setTrueDL] = useState(true);
    
    
    useEffect(() => {
        getMyPost();
        console.log(isMobile)
    }, [id]);

    const getMyPost = async () => {
        let x = await getViewPost(id);
        setMyProduct(x)
        console.log(x)
    }

    const downloadQr = () => {
            if(renterLogo && myProduct){
                var node = document.getElementById('my-node');
                htmlToImage.toPng(node)
                .then(function (dataUrl) {
                    // const url = window.URL.createObjectURL(new Blob([blob]));
                    // const link = document.createElement("a");
                    // link.href = url;
                    
                    // link.click();
                    // console.log("Check")
                  var img = new Image();
                  img.src = dataUrl;
                  document.body.appendChild(img);
                  const link = document.createElement('a');
                  link.href = dataUrl;
                  link.download = `product-${myProduct?.title}.png`;
                  link.click();
                  link.remove();
                  setTrueDL(false)
                })
                .catch(function (error) {
                  console.error('oops, something went wrong!', error);
                });
            }
        }

    const downloadCode = () => {
        downloadQr()
        // window.open("/some-link", "_blank");
        // if(isMobile){
        //     const win = window.open(`/gen-qr/product/${id}`, "_blank");
        //     win.focus();
        // }
        // else{
        //     const win = window.open(`/gen-qr/product/${id}`, "_blank");
        //     win.focus();
        // }
        
        // const canvas = document.getElementById("canvas");
        // if(canvas) {
        //     const pngUrl = canvas
        //       .toDataURL("image/png")
        //       .replace("image/png", "image/octet-stream");
        //     let downloadLink = document.createElement("a");
        //     downloadLink.href = pngUrl
        //     downloadLink.download = `qr.png`;
        //     document.body.appendChild(downloadLink);
        //     downloadLink.click();
        //     document.body.removeChild(downloadLink);
        //   }


        // var node = document.getElementById('my-node');
        // setTestM(false);

        // htmlToImage.toPng(node)
        // .then(function (dataUrl) {
        //   var img = new Image();
        //   img.src = dataUrl;
        //   document.body.appendChild(img);
        //   const link = document.createElement('a')
        //   link.download = `product-${myProduct?.title}.png`
        //   link.href = dataUrl
        //   link.click()
        // })
        // .catch(function (error) {
        //   console.error('oops, something went wrong!', error);
        // });
        

    }
    

    return(
        <div className="container">
            <div className="qr-container" >
                <div className="qr-box">
                    <div className="thisqr" id={"my-node"}>
                        <QRCode
                        size={300} 
                        enableCORS={true}
                        ecLevel={"L"} logoImage={renterLogo} 
                        logoHeight={300*0.25} 
                        logoWidth={300*0.25} 
                        logoPaddingStyle={"circle"} 
                        qrStyle={"fluid"} 
                        value={`https://www.renter.net/lease/${id}`} 
                        id={"largeqr"}
                        bgColor={"#ffffff"}
                        />
                        <p 
                        // className="thisqr-p"
                        style={{color: "black", marginTop: -40, fontSize: 30, right: 0, marginLeft: 10, textAlign: "left", padding: 0}}
                        >Renter.net</p>
                    </div>
                </div>
                
                <div className="displayqr">
                    <h3>Product Details</h3>
                    <p><span>Name: </span>{myProduct?.title}</p>
                    <p><span>Category: </span>{intl.locale === "en" ? myProduct?.category.en : myProduct?.category.ar}</p>
                    <p><span>Location: </span>{myProduct?.location}</p>
                    <p><span>Description: </span>{myProduct?.description}</p>
                </div>
            </div>
            <div className={"qrbuttons"}>
                <div className="downloadbutton" onClick={downloadCode}>
                    <p>
                        <FormattedMessage 
                            id="download.text"
                            defaultMessage="Download"
                            description="Download"
                        />
                    </p>
                </div>
            </div>
        </div>
    )
}

export default NewQrProduct;
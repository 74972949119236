import React, { useState, useEffect, useContext, useRef } from "react";
import { Modal, } from 'react-bootstrap';
import { DatePicker } from 'react-responsive-datepicker';
import 'react-responsive-datepicker/dist/index.css';
import DatePickerMulti from "react-multi-date-picker";
import "react-multi-date-picker/styles/layouts/mobile.css";
import Swal from 'sweetalert2';
import {FormattedMessage, useIntl} from 'react-intl';
import { RouteContext } from "../../contexts/route.context";
import { getDatesInRange, addMonths, determineTime } from "../../util/helper";
import LeasePayment from "./leasepayment";


const LeaseForm = (props) => {
  const intl = useIntl();
  let post = props.post;
  let term = props.term;
  let post_lease_term_id = props.chosenPostLeaseTermId;
  let dtTomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  const { getMe, me, getCheckRemainingQuantity, getLeaseTermAvailability, postLeaseTermAvailability, setPostLeaseTermAvailabilityLoading, postLeaseTermAvailabilityLoading,
    getLeaseTermUnavailability, postLeaseTermUnavailability, } = useContext(RouteContext);
  const [showModalStart, setShowModalStart] = useState(false);
  const [startTimeChosen, setStartTimeChosen] = useState("");

  const [showModalEnd, setShowModalEnd] = useState(false);
  const [endTimeChosen, setEndTimeChosen] = useState("");
  const [postDuration, setPostDuration] = useState();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [durationMonthly, setDurationMonthly] = useState("");
  const [firstItemTerm, setFirstItemTerm] = useState(0);
  const [lastItemTerm, setLastItemTerm] = useState(0);
  const [unAvailDates, setUnAvailDates] = useState();
  const [minDateMonthly, setMinDateMonthly] = useState();
  const [maxDateMonthly, setMaxDateMonthly] = useState();
  const [hourlyDates, setHourlyDates] = useState();
  const [hourlyDatesFinal, setHourlyDatesFinal] = useState();
  const [hourlyTime, setHourlyTime] = useState();
  const [endHourlyTime, setEndHourlyTime] = useState();
  const [hrsInbetween, setHrsInbetween] = useState();
  const [minDateDaily, setMinDateDaily] = useState();
  const [maxDateDaily, setMaxDateDaily] = useState();
  const [dailyDates, setDailyDates] = useState();
  const [dailyDatesFinal, setDailyDatesFinal] = useState();
  const [availDates, setAvailDates] = useState();

  // payment
  const [isPayment, setIsPayment] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState({
    user_id : "",
    post_lease_term_id : "",
    booking_date : "",
    end_date : "",
    time_in : "",
    time_out : "",
    deposit: "",
    daily_dates_final:[],
  });

  const [monthlyAvailDays, setMonthlyAvailDays] = useState()


  useEffect(() => {
    getMe();
  }, []);


  useEffect(() => {
    setPostLeaseTermAvailabilityLoading(true);
    if(term === "monthly") {
      getLeaseTermUnavailability(post.id, term);

      let monthlySet = post.post_lease_terms.filter((e) => e.lease_term === "monthly");
      setMinDateMonthly(monthlySet[0].post_availabilities[0]?.date !== undefined ? monthlySet[0].post_availabilities[0]?.date : new Date());
      setMaxDateMonthly(monthlySet[0].end_date);

      if(monthlySet[0].duration !== null || monthlySet[0].end_date !== null) {
        setPostDuration(monthlySet[0].duration);
      }
      else {
        setPostDuration(12);
      }
      getMonthDates()
    }
    else {
      getLeaseTermUnavailability(post.id, term);
      newGetAvailDates();
    }
    
  }, [post, term, post_lease_term_id]);

  const newGetAvailDates = async () => {
    let x = await getLeaseTermAvailability(post.id, term, post_lease_term_id);
    console.log("GEGE", x, postLeaseTermAvailability);
  }

  const getMonthDates = async () => {
    let result = await getLeaseTermAvailability(post.id, term, post_lease_term_id);
    let AvailableDatesMonthly = new Array();
    result?.data.map((un) => {
      AvailableDatesMonthly.push(un.date);
    });
    setMonthlyAvailDays(AvailableDatesMonthly)
  }

  const getAvail = async (dateString) =>  {
    let x;
    let postLT = `post_lease_term_id: "${post_lease_term_id}",`;
    let startD = startDate !== "" ? `booking_date: "${startDate}",`: "";
    let endD = endDate !== "" ? `end_date: "${endDate}"`: "";
    let startTimeC = endDate !== "" ? `time_in: "${startTimeChosen}",`: "";
    let endTimeC =  endTimeChosen !== "" ? `time_out: "${endTimeChosen}",`: "";
    let input = `{${postLT} ${startD} ${endD} ${startTimeC} ${endTimeC}}`;
    
    
    
    if(term === "daily"){
      x = await getCheckRemainingQuantity(input);
    }
    else if(term === "hourly"){
      x = await getCheckRemainingQuantity(input);
    }
    else{
      x = await getCheckRemainingQuantity(input);
    }
    
    try{
      if(x?.checkRemainingQuantity?.quantity === 0){
        Swal.fire({
          title: intl.locale === "en" ? "Not Available": "غير متاح",
          // text: intl.locale === "en" ? "Login as lessee to access lessor's profile" : "قم بتسجيل الدخول كمستأجر لاستعراض حساب المؤجر",
          text: intl.locale === "en" ? "The product is not available anymore": "المنتج غير متوفر بعد الآن",
          icon: 'warning',
          confirmButtonText: intl.locale === "en" ? "Ok" : "حسنا",
          confirmButtonColor: '#ff9035',
        }).then((e) => {
          window.location.reload();
        });
      }
    }
    catch(err){
      console.log(err);
    }
    
  }

  useEffect(() => {
    if(postLeaseTermAvailability && postLeaseTermAvailability?.data.length !== 0 && term !== "monthly") {

     
      
      // for max date
      let last = parseInt(postLeaseTermAvailability?.data.length) - 1;
      setLastItemTerm(last);
      // end for max date


      
      // for start and end date (daily)
      
      if(term === "daily") {
        setStartTimeChosen(postLeaseTermAvailability.data[0].post_lease_term.time_in)
        setEndTimeChosen(postLeaseTermAvailability.data[0].post_lease_term.time_out)

        // check if date tomorrow is greater than min date of date available
        if(new Date(dtTomorrow).getTime() >= new Date(postLeaseTermAvailability.data[firstItemTerm].date).getTime()) {
          setMinDateDaily(dtTomorrow);
        }
        else {
          setMinDateDaily(postLeaseTermAvailability.data[firstItemTerm].date);
        }


        // get daily dates
        let dailyDatesArr = new Array();
        postLeaseTermAvailability.data.map((dt) => {
          let dtSplit = dt.date.split("-");
          let monthSplit = dtSplit[1].split("");
          let daySplit = dtSplit[2].split("");

          
          // check if month start with 0 like 01,02...
          if(monthSplit[0] === "0") {

            // check if day start with 0 like 01,02...
            if(daySplit[0] === "0") {
              let newDate = dtSplit[0] + "-" + monthSplit[1] + "-" + daySplit[1];
              dailyDatesArr.push(newDate);
            }
            else {
              let newDate = dtSplit[0] + "-" + monthSplit[1] + "-" + dtSplit[2];
              dailyDatesArr.push(newDate);
            }

          } 
          else {
            // check if day start with 0 like 01,02...
            if(daySplit[0] === "0") {
              let newDate = dtSplit[0] + "-" + dtSplit[1] + "-" + daySplit[1];
              dailyDatesArr.push(newDate);
            }
            else {
              let newDate = dtSplit[0] + "-" + dtSplit[1] + "-" + dtSplit[2];
              dailyDatesArr.push(newDate);
            }
          }
          
        });

        setDailyDates(dailyDatesArr);

        console.log("MYYYY", dailyDatesArr)
        // end get hourly dates

        
      } // end for start and end date (daily)
      else if(term === "hourly") {
        // for time in time out (hourly)
        let hourlySet = post.post_lease_terms.filter((e) => e.lease_term === "hourly");
        let timeIn = hourlySet[0]?.time_in;
        let timeOut = hourlySet[0]?.time_out;
        let timeInStr = timeIn.split(":");
        let timeOutStr = timeOut.split(":");

        let ddArr = new Array();
        if(parseInt(timeInStr[0]) > 12 && parseInt(timeOutStr[0]) < 12) {
          for (let i = parseInt(timeInStr[0]); i <= 24; i++) {
            let iSplit = i.toString().split("");
            if(iSplit?.length === 1) {
              ddArr.push("0" + i + ":00")
            } else {
              ddArr.push(i + ":00")
            }
          }

          for (let i = 1; i <= timeOutStr[0]; i++) {
            let iSplit = i.toString().split("");
            if(iSplit?.length === 1) {
              ddArr.push("0" + i + ":00")
            } else {
              ddArr.push(i + ":00")
            }
          }
        }
        else if(parseInt(timeInStr[0]) < 12 && parseInt(timeOutStr[0]) < 12) {
          for (let i = parseInt(timeInStr[0]); i <= 24; i++) {
            let iSplit = i.toString().split("");
            if(iSplit.length === 1) {
              ddArr.push("0" + i + ":00")
            } else {
              ddArr.push(i + ":00")
            }
          }

          for (let i = 1; i <= timeOutStr[0]; i++) {
            let iSplit = i.toString().split("");
            if(iSplit.length === 1) {
              ddArr.push("0" + i + ":00")
            } else {
              ddArr.push(i + ":00")
            }
          }
        }
        else if(parseInt(timeInStr[0]) > 12 && parseInt(timeOutStr[0]) > 12) {
          for (let i = parseInt(timeInStr[0]); i <= parseInt(timeOutStr[0]); i++) {
            let iSplit = i.toString().split("");
            if(iSplit.length === 1) {
              ddArr.push("0" + i + ":00")
            } else {
              ddArr.push(i + ":00")
            }
          }
        }
        else {
          for (let i = parseInt(timeInStr[0]); i <= timeOutStr[0]; i++) {
            let iSplit = i.toString().split("");
            if(iSplit.length === 1) {
              ddArr.push("0" + i + ":00")
            } else {
              ddArr.push(i + ":00")
            }
          }
        }
        setHourlyTime(ddArr);
        // console.log(ddArr)
        // end for time in time out (hourly)



        // get hourly dates
        let hourlyDatesArr = new Array();
        postLeaseTermAvailability.data.map((dt) => {
          let dtSplit = dt.date.split("-");
          let monthSplit = dtSplit[1].split("");
          let daySplit = dtSplit[2].split("");

          // check if month start with 0 like 01,02...
          if(monthSplit[0] === "0") {

            // check if day start with 0 like 01,02...
            if(daySplit[0] === "0") {
              let newDate = dtSplit[0] + "-" + monthSplit[1] + "-" + daySplit[1];
              hourlyDatesArr.push(newDate);
            }
            else {
              let newDate = dtSplit[0] + "-" + monthSplit[1] + "-" + dtSplit[2];
              hourlyDatesArr.push(newDate);
            }

          } 
          else {
            // hourlyDates.push(dt.date);
            // check if day start with 0 like 01,02...
            if(daySplit[0] === "0") {
              let newDate = dtSplit[0] + "-" + dtSplit[1] + "-" + daySplit[1];
              hourlyDatesArr.push(newDate);
            }
            else {
              let newDate = dtSplit[0] + "-" + dtSplit[1] + "-" + dtSplit[2];
              hourlyDatesArr.push(newDate);
            }
          }
          
        });

        setHourlyDates(hourlyDatesArr);
        // end get hourly dates

      }
    }
  }, [postLeaseTermAvailability, post]);


  useEffect(() => {
    if(postLeaseTermUnavailability) {
      if(term === "monthly") {
        let unAvailableDatesMonthly = new Array();
        postLeaseTermUnavailability.data.map((un) => {
          let bkDt = new Date(un.booking_date);
          let enDt = new Date(un.end_date);
          let dts = getDatesInRange(bkDt, enDt);
          dts.map((e) => {
            unAvailableDatesMonthly.push(e);
          });
        });

        setUnAvailDates(unAvailableDatesMonthly);
      }
      else if(term === "daily") {
        let unAvailDailyDates = new Array();
        // removes all unavailable dates (hourly, daily, monthly)
        postLeaseTermUnavailability.data.map((dun) => {
          let dtInbetween = getDatesInRange(dun.booking_date, dun.end_date);
          dtInbetween.map((e) => unAvailDailyDates.push(e));
        });

        // if(dailyDates) {
        //   // remove the unavailable dates on term daily
        //   let newDailyDts = dailyDates.filter( ( el ) => !unAvailDailyDates.includes( el ) );
        //   setDailyDatesFinal(newDailyDts)
        // }
      }
      else if(term === "hourly") {
        let hourlySet = post.post_lease_terms.filter((e) => e.lease_term === "hourly");
        let timeIn = hourlySet[0]?.time_in;
        let timeOut = hourlySet[0]?.time_out;

        let unAvailHourlyDates = new Array();
        const filterHourlyUnavail = postLeaseTermUnavailability.data.filter((e) => e.lease_term === "hourly");
        filterHourlyUnavail.map((dun) => {
          if(timeIn === dun.time_in && timeOut === dun.time_out) {
            let dtInbetween = getDatesInRange(dun.booking_date, dun.end_date);
            dtInbetween.map((e) => unAvailHourlyDates.push(e));
          }
        });

        if(hourlyDates) {
          // remove the unavailable dates on term hourly
          let newHourlyDts = hourlyDates.filter( ( el ) => !unAvailHourlyDates.includes( el ) );
          setHourlyDatesFinal(newHourlyDts)
        }
      }
      
    }
  }, [postLeaseTermUnavailability, dailyDates, hourlyDates, post]);

  // console.log("postLeaseTermUnavailability", postLeaseTermUnavailability)
  // console.log("postLeaseTermAvailability", postLeaseTermAvailability)
  // console.log("hourlyTime", hourlyTime)


  // start time
  const handleCloseModalStart = () => setShowModalStart(false);

  const onClickContinueStart = () => {
    let indexSTime = hourlyTime.indexOf(startTimeChosen);
    let hourlyTimeLength = hourlyTime?.length;
    let endTimeHourlyArr = new Array();

    for (let i = parseInt(indexSTime + 1); i < hourlyTimeLength; i++) {
      endTimeHourlyArr.push(hourlyTime[i]);
    }

    setEndHourlyTime(endTimeHourlyArr);
    setShowModalStart(false);
  }


  // end time
  const handleCloseModalEnd = () => setShowModalEnd(false);
  const onClickContinueEnd = () => {
    let indexSTime = hourlyTime.indexOf(startTimeChosen);
    let indexETime = hourlyTime.indexOf(endTimeChosen);
    let hrsInbetweenArr = new Array();

    for (let i = parseInt(indexSTime + 1); i <= parseInt(indexETime); i++) {
      hrsInbetweenArr.push(hourlyTime[i]);
    }

    setHrsInbetween(hrsInbetweenArr);
    setShowModalEnd(false);
  }


  const onChangeStartDate = (e) => {
    // console.log(e)
    let dateString = e ? e.year + "-" + e.month.number + "-" + e.day : "";
    setStartDate(dateString);

    // const dstrSplit = dateString.split("-");
    // const strMonth = dstrSplit[1] - 1;
    // let endDateTom = new Date(new Date(dstrSplit[0], strMonth, dstrSplit[2]).getTime() + 24 * 60 * 60 * 1000);
    // setMaxDateDaily(endDateTom);
    setMaxDateDaily(dateString);

    if(term === "hourly") {
      setEndDate(dateString);

      let unavailHRFilter = postLeaseTermUnavailability.data.filter((plUn) => plUn.booking_date === dateString);
      if(unavailHRFilter.length !== 0) {

        let listUnavailHrs = new Array();
        let hourlySet = post.post_lease_terms.filter((e) => e.lease_term === "hourly");
        let posttimeIn = hourlySet[0]?.time_in;
        let posttimeOut = hourlySet[0]?.time_out;
        let posttimeInStr = posttimeIn.split(":");
        let posttimeOutStr = posttimeOut.split(":");

        unavailHRFilter.map((unHR) => {
          let timeIn = unHR?.time_in;
          let timeOut = unHR?.time_out;
          let timeInStr = timeIn.split(":");
          let timeOutStr = timeOut.split(":");

          if(parseInt(posttimeInStr[0]) > 12 && parseInt(posttimeOutStr[0]) < 12) {
            for (let i = parseInt(timeInStr[0]); i <= 24; i++) {
              let iSplit = i.toString().split("");
              if(iSplit.length === 1) {
                listUnavailHrs.push("0" + i + ":00")
              } else {
                listUnavailHrs.push(i + ":00")
              }
            }

            for (let i = 1; i <= timeOutStr[0]; i++) {
              let iSplit = i.toString().split("");
              if(iSplit.length === 1) {
                listUnavailHrs.push("0" + i + ":00")
              } else {
                listUnavailHrs.push(i + ":00")
              }
            }
          }
          else if(parseInt(posttimeInStr[0]) < 12 && parseInt(posttimeOutStr[0]) < 12) {
            for (let i = parseInt(timeInStr[0]); i <= 24; i++) {
              let iSplit = i.toString().split("");
              if(iSplit.length === 1) {
                listUnavailHrs.push("0" + i + ":00")
              } else {
                listUnavailHrs.push(i + ":00")
              }
            }

            for (let i = 1; i <= timeOutStr[0]; i++) {
              let iSplit = i.toString().split("");
              if(iSplit.length === 1) {
                listUnavailHrs.push("0" + i + ":00")
              } else {
                listUnavailHrs.push(i + ":00")
              }
            }
          }
          else if(parseInt(posttimeInStr[0]) > 12 && parseInt(posttimeOutStr[0]) > 12) {
            for (let i = parseInt(timeInStr[0]); i <= parseInt(timeOutStr[0]); i++) {
              let iSplit = i.toString().split("");
              if(iSplit.length === 1) {
                listUnavailHrs.push("0" + i + ":00")
              } else {
                listUnavailHrs.push(i + ":00")
              }
            }
          }
          else {
            for (let i = parseInt(timeInStr[0]); i <= timeOutStr[0]; i++) {
              let iSplit = i.toString().split("");
              if(iSplit.length === 1) {
                listUnavailHrs.push("0" + i + ":00")
              } else {
                listUnavailHrs.push(i + ":00")
              }
            }
          }
        })

        let newhourlyTime = hourlyTime.filter(function(val) {
          return listUnavailHrs.indexOf(val) == -1;
        });

        setHourlyTime(newhourlyTime);
      } else {
        let hourlySet = post.post_lease_terms.filter((e) => e.lease_term === "hourly");
        let timeIn = hourlySet[0]?.time_in;
        let timeOut = hourlySet[0]?.time_out;
        let timeInStr = timeIn.split(":");
        let timeOutStr = timeOut.split(":");

        let ddArr = new Array();
        if(parseInt(timeInStr[0]) > 12 && parseInt(timeOutStr[0]) < 12) {
          for (let i = parseInt(timeInStr[0]); i <= 24; i++) {
            let iSplit = i.toString().split("");
            if(iSplit.length === 1) {
              ddArr.push("0" + i + ":00")
            } else {
              ddArr.push(i + ":00")
            }
          }

          for (let i = 1; i <= timeOutStr[0]; i++) {
            let iSplit = i.toString().split("");
            if(iSplit.length === 1) {
              ddArr.push("0" + i + ":00")
            } else {
              ddArr.push(i + ":00")
            }
          }
        }
        else if(parseInt(timeInStr[0]) < 12 && parseInt(timeOutStr[0]) < 12) {
          for (let i = parseInt(timeInStr[0]); i <= 24; i++) {
            let iSplit = i.toString().split("");
            if(iSplit.length === 1) {
              ddArr.push("0" + i + ":00")
            } else {
              ddArr.push(i + ":00")
            }
          }

          for (let i = 1; i <= timeOutStr[0]; i++) {
            let iSplit = i.toString().split("");
            if(iSplit.length === 1) {
              ddArr.push("0" + i + ":00")
            } else {
              ddArr.push(i + ":00")
            }
          }
        }
        else if(parseInt(timeInStr[0]) > 12 && parseInt(timeOutStr[0]) > 12) {
          for (let i = parseInt(timeInStr[0]); i <= parseInt(timeOutStr[0]); i++) {
            let iSplit = i.toString().split("");
            if(iSplit.length === 1) {
              ddArr.push("0" + i + ":00")
            } else {
              ddArr.push(i + ":00")
            }
          }
        }
        else {
          for (let i = parseInt(timeInStr[0]); i <= timeOutStr[0]; i++) {
            let iSplit = i.toString().split("");
            if(iSplit.length === 1) {
              ddArr.push("0" + i + ":00")
            } else {
              ddArr.push(i + ":00")
            }
          }
        }
        setHourlyTime(ddArr);
      }
    }
    else if(term === "monthly") {
      setDurationMonthly();
      setEndDate();
    }
    else {
      setEndDate();
    }
    
  }

  const onChangeEndDate = (e) => {
    let dateString = e ? e.year + "-" + e.month.number + "-" + e.day : "";
    getAvail(dateString);
    setEndDate(dateString);
  }

  const onChangeDuration = (e) => {

    try{
      let duration = e.target.value;
      let newStartDate = new Date(startDate);

      let startDateSplit = startDate.split("-");
      let startDateSplitMonth = startDateSplit[1].split("");
      let startDateSplitDate = startDateSplit[2].split("");
      let finalMonth = startDateSplitMonth?.length === 1 ? "0" + startDateSplitMonth[0] : startDateSplit[1];
      let finalDate = startDateSplitDate?.length === 1 ? "0" + startDateSplitDate[0] : startDateSplit[2];
      let finalStartDate = startDateSplit[0] +"-"+ finalMonth +"-"+ finalDate;

      let finalEnd = addMonths(finalStartDate, duration);
      let newFinalEnd = new Date(finalEnd);
      let dateStartEnd = getDatesInRange(startDate, finalEnd);

      let finaleEndSplit = finalEnd.split("-");
      let finaleEndSplitMonth = finaleEndSplit[1].split("");
      let finaleEndSplitDate = finaleEndSplit[2].split("");
      let finaleEndFinalMonth = finaleEndSplitMonth?.length === 1 ? "0" + finaleEndSplitMonth[0] : finaleEndSplit[1];
      let finaleEndFinalDate = finaleEndSplitDate?.length === 1 ? "0" + finaleEndSplitDate[0] : finaleEndSplit[2];
      let finalFinaleEnd = finaleEndSplit[0] +"-"+ finaleEndFinalMonth +"-"+ finaleEndFinalDate;
      
      let dtArr = new Array();
      dateStartEnd.map((e) => {
        let isOccupied = unAvailDates.includes(e);
        dtArr.push(isOccupied);
      });


      // determine if all false
      let checkAvailability = dtArr.every(v => v === false);
      if(!checkAvailability) {
        Swal.fire({
          title: 'Error',
          text: "Conflict booking, choose other option for duration or change start date",
          icon: 'warning',
          confirmButtonColor: '#FF5833',
        }).then((e) => {
          window.location.reload();
        });
        
      }
      else {
        // if no end_date and unlimited on monthly
        if(maxDateMonthly !== null) {
          let finalEndSetByDuration = addMonths(minDateMonthly, postDuration);
          let finaleEndSetByDurationSplit = finalEndSetByDuration.split("-");
          let finaleEndSetByDurationSplitMonth = finaleEndSetByDurationSplit[1].split("");
          let finaleEndSetByDurationSplitDate = finaleEndSetByDurationSplit[2].split("");
          let finaleEndSetByDurationFinalMonth = finaleEndSetByDurationSplitMonth.length === 1 ? "0" + finaleEndSetByDurationSplitMonth[0] : finaleEndSetByDurationSplit[1];
          let finaleEndSetByDurationFinalDate = finaleEndSetByDurationSplitDate.length === 1 ? "0" + finaleEndSetByDurationSplitDate[0] : finaleEndSetByDurationSplit[2];
          let finalFinaleEndSetByDuration = finaleEndSetByDurationSplit[0] +"-"+ finaleEndSetByDurationFinalMonth +"-"+ finaleEndSetByDurationFinalDate;


          // console.log("finalFinaleEndSetByDuration", finalFinaleEndSetByDuration)
          // console.log("finalFinaleEnd", finalFinaleEnd)

          setEndDate(finalFinaleEnd);
          setDurationMonthly(duration);

          // check if duration is still with in range of max and min date
          // if(new Date(finalFinaleEndSetByDuration).getTime() >= new Date(finalFinaleEnd).getTime()) {
          //   setEndDate(finalFinaleEnd);
          //   setDurationMonthly(duration);
          // }
          // else {
          //   Swal.fire({
          //     title: 'Error',
          //     text: "Your chosen duration exceeded Lessor's settings.",
          //     icon: 'warning',
          //     confirmButtonColor: '#FF5833',
          //   });
          //   setEndDate();
          //   setDurationMonthly();
          // }
        }
        else {
          setEndDate(finalFinaleEnd);
          setDurationMonthly(duration);
          }
        }
    }
    catch(err){
      console.log(err)
    }

    
  }

  const invalidInputMessage = () => {
    Swal.fire({
      title: intl.locale === "en" ? "Something went wrong": "حدث خطأ ما",
      // text: intl.locale === "en" ? "Login as lessee to access lessor's profile" : "قم بتسجيل الدخول كمستأجر لاستعراض حساب المؤجر",
      text: intl.locale === "en" ? "Invalid or incomplete input": "إدخال غير صالح أو غير كامل",
      icon: 'warning',
      confirmButtonText: intl.locale === "en" ? "Ok" : "حسنا",
      confirmButtonColor: '#ff9035',
    }).then((e) => {
      window.location.reload();
    });;
  }


  const onClickPayment = async () => {
    try{
      let postLT = `post_lease_term_id: "${post_lease_term_id}",`;
      let startD = startDate !== "" ? `booking_date: "${startDate}",`: "";
      let endD = endDate !== "" ? `end_date: "${endDate}"`: "";
      let startTimeC = endDate !== "" ? `time_in: "${startTimeChosen}",`: "";
      let endTimeC =  endTimeChosen !== "" ? `time_out: "${endTimeChosen}",`: "";
      let input = `{${postLT} ${startD} ${endD} ${startTimeC} ${endTimeC}}`;
      
      console.log("Parameters", input)
      
      let x = await getCheckRemainingQuantity(input);
      console.log("result", x)
      

      if(term === "monthly" && (!durationMonthly || !startDate || !endDate )){
        invalidInputMessage();
      }
      else if(term === "hourly" && (!startDate || !startTimeChosen || !endTimeChosen)){
        invalidInputMessage();
      }
      else if(term === "daily" && (!startDate || !endDate)){
        invalidInputMessage();
      }
      else{
        console.log(startDate, startTimeChosen, endTimeChosen)

      if(x.checkRemainingQuantity.quantity === 0){
        console.log("Test here")
        Swal.fire({
          title: intl.locale === "en" ? "Not Available": "غير متاح",
          // text: intl.locale === "en" ? "Login as lessee to access lessor's profile" : "قم بتسجيل الدخول كمستأجر لاستعراض حساب المؤجر",
          text: intl.locale === "en" ? "The product is not available anymore": "المنتج غير متوفر بعد الآن",
          icon: 'warning',
          confirmButtonText: intl.locale === "en" ? "Ok" : "حسنا",
          confirmButtonColor: '#ff9035',
        }).then((e) => {
          window.location.reload();
        });;
        
      }
      else{

        let termFil = post.post_lease_terms.filter((e) => e.lease_term === term);

        setPaymentDetails({
          user_id : me.id,
          post_lease_term_id : termFil[0].id,
          booking_date : startDate,
          end_date : endDate,
          // daily_dates_final: dailyDatesFinal,
          daily_dates_final: dailyDates,
          time_in : startTimeChosen,
          time_out : endTimeChosen,
          duration : durationMonthly,
          price : termFil[0].price,
          deposit: termFil[0].deposit,
          term_type: term,
          hrs_Inbetween: hrsInbetween,
        });
    
        setIsPayment(true);
        setStartDate();
        setEndDate();
        setStartTimeChosen();
        setEndTimeChosen();
      }
      }
    }
    catch(err){
      console.log(err);
    }
    
  }


  return (
    <div>
    {isPayment ? (
      <LeasePayment paymentDetails={paymentDetails} />
    ) : (
      <div className="container-fluid py-2 leases-single-div">
        <div className="container main-wrap">
          <div className="row">
            <div className="col-12 col-lg-6 d-flex justify-content-center">
              <img src="../assets/images/lease-form-img.png" alt="" className="img-fluid mb-5" />
            </div>
            <div className="col-12 col-lg-6 d-flex justify-content-center">
              {!postLeaseTermAvailabilityLoading && term !== "monthly" && postLeaseTermAvailability && postLeaseTermAvailability?.data.length !== 0 ? (
                <div className="reg-input-form-div pt-0">
                  <h1 className="cblue fw700 mb-5">
                    <FormattedMessage 
                      id="plsfillupform.text"
                      defaultMessage="Please, fill up this form"
                      description="Please, fill up this form"
                    />:
                  </h1>
      

                  <div className="mb-4">
                    <div className="d-flex">
                      <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0 3C0 2.20435 0.31607 1.44129 0.87868 0.87868C1.44129 0.31607 2.20435 0 3 0H9.879C10.6746 0.000169912 11.4375 0.316352 12 0.879L17.121 6C17.6836 6.56248 17.9998 7.32542 18 8.121V21C18 21.7956 17.6839 22.5587 17.1213 23.1213C16.5587 23.6839 15.7956 24 15 24H3C2.20435 24 1.44129 23.6839 0.87868 23.1213C0.31607 22.5587 0 21.7956 0 21V3ZM3 12C3 11.6022 3.15804 11.2206 3.43934 10.9393C3.72064 10.658 4.10218 10.5 4.5 10.5H13.5C13.8978 10.5 14.2794 10.658 14.5607 10.9393C14.842 11.2206 15 11.6022 15 12C15 12.3978 14.842 12.7794 14.5607 13.0607C14.2794 13.342 13.8978 13.5 13.5 13.5H4.5C4.10218 13.5 3.72064 13.342 3.43934 13.0607C3.15804 12.7794 3 12.3978 3 12ZM4.5 16.5C4.10218 16.5 3.72064 16.658 3.43934 16.9393C3.15804 17.2206 3 17.6022 3 18C3 18.3978 3.15804 18.7794 3.43934 19.0607C3.72064 19.342 4.10218 19.5 4.5 19.5H13.5C13.8978 19.5 14.2794 19.342 14.5607 19.0607C14.842 18.7794 15 18.3978 15 18C15 17.6022 14.842 17.2206 14.5607 16.9393C14.2794 16.658 13.8978 16.5 13.5 16.5H4.5Z" fill="#1036CE"/>
                      </svg>
                      <p className="cblue fw600 fs18 ms-3 me-3 mb-2">
                        <FormattedMessage 
                          id="leaseterm.text"
                          defaultMessage="Lease Term"
                          description="Lease Term"
                        />
                      </p>
                    </div>

                    <input type="text" className="form-control reg-input-form text-uppercase" value={term} disabled/>
                  </div>

                  
                  

                  {term === "daily" && (
                  <>
                    <div className="mb-4">
                      <div className="d-flex">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M6 0C5.60218 0 5.22064 0.158035 4.93934 0.43934C4.65804 0.720644 4.5 1.10218 4.5 1.5V3H3C2.20435 3 1.44129 3.31607 0.87868 3.87868C0.31607 4.44129 0 5.20435 0 6V21C0 21.7956 0.31607 22.5587 0.87868 23.1213C1.44129 23.6839 2.20435 24 3 24H21C21.7956 24 22.5587 23.6839 23.1213 23.1213C23.6839 22.5587 24 21.7956 24 21V6C24 5.20435 23.6839 4.44129 23.1213 3.87868C22.5587 3.31607 21.7956 3 21 3H19.5V1.5C19.5 1.10218 19.342 0.720644 19.0607 0.43934C18.7794 0.158035 18.3978 0 18 0C17.6022 0 17.2206 0.158035 16.9393 0.43934C16.658 0.720644 16.5 1.10218 16.5 1.5V3H7.5V1.5C7.5 1.10218 7.34196 0.720644 7.06066 0.43934C6.77936 0.158035 6.39782 0 6 0ZM6 7.5C5.60218 7.5 5.22064 7.65804 4.93934 7.93934C4.65804 8.22064 4.5 8.60218 4.5 9C4.5 9.39782 4.65804 9.77936 4.93934 10.0607C5.22064 10.342 5.60218 10.5 6 10.5H18C18.3978 10.5 18.7794 10.342 19.0607 10.0607C19.342 9.77936 19.5 9.39782 19.5 9C19.5 8.60218 19.342 8.22064 19.0607 7.93934C18.7794 7.65804 18.3978 7.5 18 7.5H6Z" fill="#1036CE"/>
                        </svg>
                        <p className="cblue fw600 fs18 ms-3 me-3 mb-2">
                          <FormattedMessage 
                            id="startdate.text"
                            defaultMessage="Start Date"
                            description="Start Date"
                          />
                        </p>
                      </div>

                      <div className="position-relative leases-date-form">
                        <DatePickerMulti 
                          // multiple
                          value={startDate} 
                          onChange={(e) => onChangeStartDate(e)}
                          className="rmdp-mobile"
                          minDate={new Date()}
                          // maxDate={postLeaseTermAvailability.data[lastItemTerm].date}
                          style={{
                            color: "#212529",
                            fontSize: "1rem",
                            background: "#fff",
                            border: "1px solid #AAAAAA",
                            borderRadius:"4px",
                            height: "48px",
                            display: "block",
                            width: "100%",
                            padding: ".375rem .75rem",
                          }}
                          mapDays={({ date }) => {
                            let dtCurrent = date.year + "-" + date.month.number + "-" + date.day;
                            let filteredResult = !dailyDates.includes(dtCurrent);
                            
                            // let filteredResult = !dailyDatesFinal.includes(dtCurrent);
                            // console.log(dtCurrent)
                            // console.log(filteredResult)
                            

                            if (filteredResult) return {
                              disabled: true,
                              style: { color: "#ccc" },
                              onClick: () => alert("Unavailable Date")
                            }
                          }}
                        />
                        <svg width="24" height="24" viewBox="0 0 24 24" style={{position:"absolute", top:"11px", right:"20px"}} fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M6 0C5.60218 0 5.22064 0.158035 4.93934 0.43934C4.65804 0.720644 4.5 1.10218 4.5 1.5V3H3C2.20435 3 1.44129 3.31607 0.87868 3.87868C0.31607 4.44129 0 5.20435 0 6V21C0 21.7956 0.31607 22.5587 0.87868 23.1213C1.44129 23.6839 2.20435 24 3 24H21C21.7956 24 22.5587 23.6839 23.1213 23.1213C23.6839 22.5587 24 21.7956 24 21V6C24 5.20435 23.6839 4.44129 23.1213 3.87868C22.5587 3.31607 21.7956 3 21 3H19.5V1.5C19.5 1.10218 19.342 0.720644 19.0607 0.43934C18.7794 0.158035 18.3978 0 18 0C17.6022 0 17.2206 0.158035 16.9393 0.43934C16.658 0.720644 16.5 1.10218 16.5 1.5V3H7.5V1.5C7.5 1.10218 7.34196 0.720644 7.06066 0.43934C6.77936 0.158035 6.39782 0 6 0ZM6 7.5C5.60218 7.5 5.22064 7.65804 4.93934 7.93934C4.65804 8.22064 4.5 8.60218 4.5 9C4.5 9.39782 4.65804 9.77936 4.93934 10.0607C5.22064 10.342 5.60218 10.5 6 10.5H18C18.3978 10.5 18.7794 10.342 19.0607 10.0607C19.342 9.77936 19.5 9.39782 19.5 9C19.5 8.60218 19.342 8.22064 19.0607 7.93934C18.7794 7.65804 18.3978 7.5 18 7.5H6Z" fill="#FF5833"/>
                        </svg>
                      </div>
                    </div>

                    <div className="mb-4">
                      <div className="d-flex">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M6 0C5.60218 0 5.22064 0.158035 4.93934 0.43934C4.65804 0.720644 4.5 1.10218 4.5 1.5V3H3C2.20435 3 1.44129 3.31607 0.87868 3.87868C0.31607 4.44129 0 5.20435 0 6V21C0 21.7956 0.31607 22.5587 0.87868 23.1213C1.44129 23.6839 2.20435 24 3 24H21C21.7956 24 22.5587 23.6839 23.1213 23.1213C23.6839 22.5587 24 21.7956 24 21V6C24 5.20435 23.6839 4.44129 23.1213 3.87868C22.5587 3.31607 21.7956 3 21 3H19.5V1.5C19.5 1.10218 19.342 0.720644 19.0607 0.43934C18.7794 0.158035 18.3978 0 18 0C17.6022 0 17.2206 0.158035 16.9393 0.43934C16.658 0.720644 16.5 1.10218 16.5 1.5V3H7.5V1.5C7.5 1.10218 7.34196 0.720644 7.06066 0.43934C6.77936 0.158035 6.39782 0 6 0ZM6 7.5C5.60218 7.5 5.22064 7.65804 4.93934 7.93934C4.65804 8.22064 4.5 8.60218 4.5 9C4.5 9.39782 4.65804 9.77936 4.93934 10.0607C5.22064 10.342 5.60218 10.5 6 10.5H18C18.3978 10.5 18.7794 10.342 19.0607 10.0607C19.342 9.77936 19.5 9.39782 19.5 9C19.5 8.60218 19.342 8.22064 19.0607 7.93934C18.7794 7.65804 18.3978 7.5 18 7.5H6Z" fill="#1036CE"/>
                        </svg>
                        <p className="cblue fw600 fs18 ms-3 me-3 mb-2">
                          <FormattedMessage 
                            id="enddate.text"
                            defaultMessage="End Date"
                            description="End Date"
                          />
                        </p>
                      </div>

                      <div className="position-relative leases-date-form">
                        <DatePickerMulti 
                          // multiple
                          value={endDate} 
                          onChange={(e) => onChangeEndDate(e)}
                          minDate={maxDateDaily}
                          maxDate={postLeaseTermAvailability.data[lastItemTerm].date}
                          className="rmdp-mobile"
                          style={{
                            color: "#212529",
                            fontSize: "1rem",
                            background: "#fff",
                            border: "1px solid #AAAAAA",
                            borderRadius:"4px",
                            height: "48px",
                            display: "block",
                            width: "100%",
                            padding: ".375rem .75rem",
                          }}
                          mapDays={({ date }) => {
                            let dtCurrent = date.year + "-" + date.month.number + "-" + date.day;
                            let filteredResult = !dailyDates.includes(dtCurrent);
                            // console.log(dtCurrent)
                            // console.log(filteredResult)
                            

                            if (filteredResult) return {
                              disabled: true,
                              style: { color: "#ccc" },
                              onClick: () => alert("Unavailable Date")
                            }
                          }}
                          disabled={startDate === undefined ? true : false}
                        />
                        <svg width="24" height="24" viewBox="0 0 24 24" style={{position:"absolute", top:"11px", right:"20px"}} fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M6 0C5.60218 0 5.22064 0.158035 4.93934 0.43934C4.65804 0.720644 4.5 1.10218 4.5 1.5V3H3C2.20435 3 1.44129 3.31607 0.87868 3.87868C0.31607 4.44129 0 5.20435 0 6V21C0 21.7956 0.31607 22.5587 0.87868 23.1213C1.44129 23.6839 2.20435 24 3 24H21C21.7956 24 22.5587 23.6839 23.1213 23.1213C23.6839 22.5587 24 21.7956 24 21V6C24 5.20435 23.6839 4.44129 23.1213 3.87868C22.5587 3.31607 21.7956 3 21 3H19.5V1.5C19.5 1.10218 19.342 0.720644 19.0607 0.43934C18.7794 0.158035 18.3978 0 18 0C17.6022 0 17.2206 0.158035 16.9393 0.43934C16.658 0.720644 16.5 1.10218 16.5 1.5V3H7.5V1.5C7.5 1.10218 7.34196 0.720644 7.06066 0.43934C6.77936 0.158035 6.39782 0 6 0ZM6 7.5C5.60218 7.5 5.22064 7.65804 4.93934 7.93934C4.65804 8.22064 4.5 8.60218 4.5 9C4.5 9.39782 4.65804 9.77936 4.93934 10.0607C5.22064 10.342 5.60218 10.5 6 10.5H18C18.3978 10.5 18.7794 10.342 19.0607 10.0607C19.342 9.77936 19.5 9.39782 19.5 9C19.5 8.60218 19.342 8.22064 19.0607 7.93934C18.7794 7.65804 18.3978 7.5 18 7.5H6Z" fill="#FF5833"/>
                        </svg>
                      </div>
                    </div>
                  </>
                  )}


                  {term === "hourly" && (
                  <>
                    <div className="mb-4">
                      <div className="d-flex">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M6 0C5.60218 0 5.22064 0.158035 4.93934 0.43934C4.65804 0.720644 4.5 1.10218 4.5 1.5V3H3C2.20435 3 1.44129 3.31607 0.87868 3.87868C0.31607 4.44129 0 5.20435 0 6V21C0 21.7956 0.31607 22.5587 0.87868 23.1213C1.44129 23.6839 2.20435 24 3 24H21C21.7956 24 22.5587 23.6839 23.1213 23.1213C23.6839 22.5587 24 21.7956 24 21V6C24 5.20435 23.6839 4.44129 23.1213 3.87868C22.5587 3.31607 21.7956 3 21 3H19.5V1.5C19.5 1.10218 19.342 0.720644 19.0607 0.43934C18.7794 0.158035 18.3978 0 18 0C17.6022 0 17.2206 0.158035 16.9393 0.43934C16.658 0.720644 16.5 1.10218 16.5 1.5V3H7.5V1.5C7.5 1.10218 7.34196 0.720644 7.06066 0.43934C6.77936 0.158035 6.39782 0 6 0ZM6 7.5C5.60218 7.5 5.22064 7.65804 4.93934 7.93934C4.65804 8.22064 4.5 8.60218 4.5 9C4.5 9.39782 4.65804 9.77936 4.93934 10.0607C5.22064 10.342 5.60218 10.5 6 10.5H18C18.3978 10.5 18.7794 10.342 19.0607 10.0607C19.342 9.77936 19.5 9.39782 19.5 9C19.5 8.60218 19.342 8.22064 19.0607 7.93934C18.7794 7.65804 18.3978 7.5 18 7.5H6Z" fill="#1036CE"/>
                        </svg>
                        <p className="cblue fw600 fs18 ms-3 me-3 mb-2">
                          <FormattedMessage 
                            id="startdate.text"
                            defaultMessage="Start Date"
                            description="Start Date"
                          />
                        </p>
                      </div>

                      <div className="position-relative leases-date-form">
                        <DatePickerMulti 
                          // multiple
                          value={startDate} 
                          minDate={new Date()}
                          onChange={(e) => onChangeStartDate(e)}
                          className="rmdp-mobile"
                          style={{
                            color: "#212529",
                            fontSize: "1rem",
                            background: "#fff",
                            border: "1px solid #AAAAAA",
                            borderRadius:"4px",
                            height: "48px",
                            display: "block",
                            width: "100%",
                            padding: ".375rem .75rem",
                          }}
                          mapDays={({ date }) => {
                            let dtCurrent = date.year + "-" + date.month.number + "-" + date.day;
                            let filteredResult = !hourlyDatesFinal.includes(dtCurrent);
                            // console.log(dtCurrent)
                            // console.log(filteredResult)
                            

                            if (filteredResult) return {
                              disabled: true,
                              style: { color: "#ccc" },
                              onClick: () => alert("Unavailable Date")
                            }
                          }}
                        />
                        <svg width="24" height="24" viewBox="0 0 24 24" style={{position:"absolute", top:"11px", right:"20px"}} fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M6 0C5.60218 0 5.22064 0.158035 4.93934 0.43934C4.65804 0.720644 4.5 1.10218 4.5 1.5V3H3C2.20435 3 1.44129 3.31607 0.87868 3.87868C0.31607 4.44129 0 5.20435 0 6V21C0 21.7956 0.31607 22.5587 0.87868 23.1213C1.44129 23.6839 2.20435 24 3 24H21C21.7956 24 22.5587 23.6839 23.1213 23.1213C23.6839 22.5587 24 21.7956 24 21V6C24 5.20435 23.6839 4.44129 23.1213 3.87868C22.5587 3.31607 21.7956 3 21 3H19.5V1.5C19.5 1.10218 19.342 0.720644 19.0607 0.43934C18.7794 0.158035 18.3978 0 18 0C17.6022 0 17.2206 0.158035 16.9393 0.43934C16.658 0.720644 16.5 1.10218 16.5 1.5V3H7.5V1.5C7.5 1.10218 7.34196 0.720644 7.06066 0.43934C6.77936 0.158035 6.39782 0 6 0ZM6 7.5C5.60218 7.5 5.22064 7.65804 4.93934 7.93934C4.65804 8.22064 4.5 8.60218 4.5 9C4.5 9.39782 4.65804 9.77936 4.93934 10.0607C5.22064 10.342 5.60218 10.5 6 10.5H18C18.3978 10.5 18.7794 10.342 19.0607 10.0607C19.342 9.77936 19.5 9.39782 19.5 9C19.5 8.60218 19.342 8.22064 19.0607 7.93934C18.7794 7.65804 18.3978 7.5 18 7.5H6Z" fill="#FF5833"/>
                        </svg>
                      </div>
                    </div>

                    <div className="mb-4">
                      <div className="d-flex">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24ZM13.5 6C13.5 5.60218 13.342 5.22064 13.0607 4.93934C12.7794 4.65804 12.3978 4.5 12 4.5C11.6022 4.5 11.2206 4.65804 10.9393 4.93934C10.658 5.22064 10.5 5.60218 10.5 6V12C10.5001 12.3978 10.6582 12.7793 10.9395 13.0605L15.1815 17.304C15.3209 17.4434 15.4863 17.5539 15.6684 17.6293C15.8505 17.7048 16.0457 17.7436 16.2428 17.7436C16.4398 17.7436 16.635 17.7048 16.8171 17.6293C16.9992 17.5539 17.1646 17.4434 17.304 17.304C17.4434 17.1646 17.5539 16.9992 17.6293 16.8171C17.7048 16.635 17.7436 16.4398 17.7436 16.2428C17.7436 16.0457 17.7048 15.8505 17.6293 15.6684C17.5539 15.4863 17.4434 15.3209 17.304 15.1815L13.5 11.379V6Z" fill="#1036CE"/>
                        </svg>
                        <p className="cblue fw600 fs18 ms-3 me-3 mb-2">
                          <FormattedMessage 
                            id="starttime.text"
                            defaultMessage="Start Time"
                            description="Start Time"
                          />
                        </p>
                      </div>

                      <div className="position-relative leaseform-time">
                        <input type="text" className="form-control reg-input-form" placeholder="HR : MIN" onClick={() => setShowModalStart(true)} value={startTimeChosen} disabled={term === "daily" ? true : false}/>
                        <svg width="24" height="24" viewBox="0 0 24 24" style={{position:"absolute", top:"11px", right:"20px"}} fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24ZM13.5 6C13.5 5.60218 13.342 5.22064 13.0607 4.93934C12.7794 4.65804 12.3978 4.5 12 4.5C11.6022 4.5 11.2206 4.65804 10.9393 4.93934C10.658 5.22064 10.5 5.60218 10.5 6V12C10.5001 12.3978 10.6582 12.7793 10.9395 13.0605L15.1815 17.304C15.3209 17.4434 15.4863 17.5539 15.6684 17.6293C15.8505 17.7048 16.0457 17.7436 16.2428 17.7436C16.4398 17.7436 16.635 17.7048 16.8171 17.6293C16.9992 17.5539 17.1646 17.4434 17.304 17.304C17.4434 17.1646 17.5539 16.9992 17.6293 16.8171C17.7048 16.635 17.7436 16.4398 17.7436 16.2428C17.7436 16.0457 17.7048 15.8505 17.6293 15.6684C17.5539 15.4863 17.4434 15.3209 17.304 15.1815L13.5 11.379V6Z" fill="#FF5833"/>
                        </svg>
                      </div>
                    </div>

                    <div className="mb-4">
                      <div className="d-flex">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24ZM13.5 6C13.5 5.60218 13.342 5.22064 13.0607 4.93934C12.7794 4.65804 12.3978 4.5 12 4.5C11.6022 4.5 11.2206 4.65804 10.9393 4.93934C10.658 5.22064 10.5 5.60218 10.5 6V12C10.5001 12.3978 10.6582 12.7793 10.9395 13.0605L15.1815 17.304C15.3209 17.4434 15.4863 17.5539 15.6684 17.6293C15.8505 17.7048 16.0457 17.7436 16.2428 17.7436C16.4398 17.7436 16.635 17.7048 16.8171 17.6293C16.9992 17.5539 17.1646 17.4434 17.304 17.304C17.4434 17.1646 17.5539 16.9992 17.6293 16.8171C17.7048 16.635 17.7436 16.4398 17.7436 16.2428C17.7436 16.0457 17.7048 15.8505 17.6293 15.6684C17.5539 15.4863 17.4434 15.3209 17.304 15.1815L13.5 11.379V6Z" fill="#1036CE"/>
                        </svg>
                        <p className="cblue fw600 fs18 ms-3 me-3 mb-2">
                          <FormattedMessage 
                            id="endtime.text"
                            defaultMessage="End Time"
                            description="End Time"
                          />
                        </p>
                      </div>

                      <div className="position-relative leaseform-time">
                        <input type="text" className="form-control reg-input-form" placeholder="HR : MIN" onClick={() => setShowModalEnd(true)} value={endTimeChosen} disabled={term === "daily" ? true : false}/>
                        <svg width="24" height="24" viewBox="0 0 24 24" style={{position:"absolute", top:"11px", right:"20px"}} fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24ZM13.5 6C13.5 5.60218 13.342 5.22064 13.0607 4.93934C12.7794 4.65804 12.3978 4.5 12 4.5C11.6022 4.5 11.2206 4.65804 10.9393 4.93934C10.658 5.22064 10.5 5.60218 10.5 6V12C10.5001 12.3978 10.6582 12.7793 10.9395 13.0605L15.1815 17.304C15.3209 17.4434 15.4863 17.5539 15.6684 17.6293C15.8505 17.7048 16.0457 17.7436 16.2428 17.7436C16.4398 17.7436 16.635 17.7048 16.8171 17.6293C16.9992 17.5539 17.1646 17.4434 17.304 17.304C17.4434 17.1646 17.5539 16.9992 17.6293 16.8171C17.7048 16.635 17.7436 16.4398 17.7436 16.2428C17.7436 16.0457 17.7048 15.8505 17.6293 15.6684C17.5539 15.4863 17.4434 15.3209 17.304 15.1815L13.5 11.379V6Z" fill="#FF5833"/>
                        </svg>
                      </div>
                    </div>
                  </>
                  )}

                  <button className="btn btn-payment mb-4 w-100 mt-4" onClick={onClickPayment} disabled={startDate === undefined || endDate === undefined ? true : false}>
                    <FormattedMessage 
                      id="proceedpayment.text"
                      defaultMessage="Proceed to Payment"
                      description="Proceed to Payment"
                    />
                  </button>

                  {/* Modal start time */}
                  <Modal className="modalChat" show={showModalStart} onHide={handleCloseModalStart}>
                    <Modal.Body>
                      <p className="fw700 cblue fs16">
                        <FormattedMessage 
                          id="selecttime.text"
                          defaultMessage="Select a Time"
                          description="Select a Time"
                        />
                      </p>
                      {/* <div className="form-check form-switch select-time-toggle">
                        <p className={`label1 fw700 fs16 ${startTime ? 'cgray' : 'cblue'}`}>AM</p>
                        <input className="form-check-input" type="checkbox" id="toggleTimeStart" onChange={onChangeTimeStart} checked={startTime} />
                        <label className={`form-check-label fw700 fs16 ${startTime ? 'cred' : 'cgray'}`} htmlFor="toggleTimeStart">PM</label>
                      </div> */}

                      <div className="time-list-wrapper lease-form">
                        {hourlyTime && hourlyTime.map((ht, index) => (
                          <span key={index} className={`${startTimeChosen === ht ? "active" : ""} timebox`} onClick={() => setStartTimeChosen(ht)}>
                            {determineTime(ht)}
                          </span>
                        ))}
                      </div>

                      <button className="btn btn-orang-def w-100 btn-continue-time mt-3" onClick={onClickContinueStart}>
                        <FormattedMessage 
                          id="continue.text"
                          defaultMessage="Continue"
                          description="Continue"
                        />
                      </button>
                    </Modal.Body>
                  </Modal>


                  {/* Modal end time */}
                  <Modal className="modalChat" show={showModalEnd} onHide={handleCloseModalEnd}>
                    <Modal.Body>
                      <p className="fw700 cblue fs16">
                        <FormattedMessage 
                          id="selecttime.text"
                          defaultMessage="Select a Time"
                          description="Select a Time"
                        />
                      </p>
                      {/* <div className="form-check form-switch select-time-toggle">
                        <p className={`label1 fw700 fs16 ${endTime ? 'cgray' : 'cblue'}`}>AM</p>
                        <input className="form-check-input" type="checkbox" id="toggleTimeStart" onChange={onChangeTimeEnd} checked={endTime} />
                        <label className={`form-check-label fw700 fs16 ${endTime ? 'cred' : 'cgray'}`} htmlFor="toggleTimeStart">PM</label>
                      </div> */}


                      <div className="time-list-wrapper lease-form">
                        {endHourlyTime && endHourlyTime.map((ht, index) => (
                          <span key={index} className={`${endTimeChosen === ht ? "active" : ""} timebox`} onClick={() => setEndTimeChosen(ht)}>
                            {determineTime(ht)}
                          </span>
                        ))}
                      </div>

                      <button className="btn btn-orang-def w-100 btn-continue-time mt-3" onClick={onClickContinueEnd}>
                        <FormattedMessage 
                          id="continue.text"
                          defaultMessage="Continue"
                          description="Continue"
                        />
                      </button>
                    </Modal.Body>
                  </Modal>



                </div>
              ) : 
              !postLeaseTermAvailabilityLoading && term === "monthly" && postLeaseTermUnavailability ? (
                <div className="reg-input-form-div pt-0">
                  <h1 className="cblue fw700 mb-5">
                    <FormattedMessage 
                      id="plsfillupform.text"
                      defaultMessage="Please, fill up this form"
                      description="Please, fill up this form"
                    />:
                  </h1>
      

                  <div className="mb-4">
                    <div className="d-flex">
                      <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0 3C0 2.20435 0.31607 1.44129 0.87868 0.87868C1.44129 0.31607 2.20435 0 3 0H9.879C10.6746 0.000169912 11.4375 0.316352 12 0.879L17.121 6C17.6836 6.56248 17.9998 7.32542 18 8.121V21C18 21.7956 17.6839 22.5587 17.1213 23.1213C16.5587 23.6839 15.7956 24 15 24H3C2.20435 24 1.44129 23.6839 0.87868 23.1213C0.31607 22.5587 0 21.7956 0 21V3ZM3 12C3 11.6022 3.15804 11.2206 3.43934 10.9393C3.72064 10.658 4.10218 10.5 4.5 10.5H13.5C13.8978 10.5 14.2794 10.658 14.5607 10.9393C14.842 11.2206 15 11.6022 15 12C15 12.3978 14.842 12.7794 14.5607 13.0607C14.2794 13.342 13.8978 13.5 13.5 13.5H4.5C4.10218 13.5 3.72064 13.342 3.43934 13.0607C3.15804 12.7794 3 12.3978 3 12ZM4.5 16.5C4.10218 16.5 3.72064 16.658 3.43934 16.9393C3.15804 17.2206 3 17.6022 3 18C3 18.3978 3.15804 18.7794 3.43934 19.0607C3.72064 19.342 4.10218 19.5 4.5 19.5H13.5C13.8978 19.5 14.2794 19.342 14.5607 19.0607C14.842 18.7794 15 18.3978 15 18C15 17.6022 14.842 17.2206 14.5607 16.9393C14.2794 16.658 13.8978 16.5 13.5 16.5H4.5Z" fill="#1036CE"/>
                      </svg>
                      <p className="cblue fw600 fs18 ms-3 me-3 mb-2">
                        <FormattedMessage 
                          id="leaseterm.text"
                          defaultMessage="Lease Term"
                          description="Lease Term"
                        />
                      </p>
                    </div>

                    <input type="text" className="form-control reg-input-form text-uppercase" value={term} disabled/>
                  </div>

                  
                  <div className="mb-4">
                    <div className="d-flex">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M6 0C5.60218 0 5.22064 0.158035 4.93934 0.43934C4.65804 0.720644 4.5 1.10218 4.5 1.5V3H3C2.20435 3 1.44129 3.31607 0.87868 3.87868C0.31607 4.44129 0 5.20435 0 6V21C0 21.7956 0.31607 22.5587 0.87868 23.1213C1.44129 23.6839 2.20435 24 3 24H21C21.7956 24 22.5587 23.6839 23.1213 23.1213C23.6839 22.5587 24 21.7956 24 21V6C24 5.20435 23.6839 4.44129 23.1213 3.87868C22.5587 3.31607 21.7956 3 21 3H19.5V1.5C19.5 1.10218 19.342 0.720644 19.0607 0.43934C18.7794 0.158035 18.3978 0 18 0C17.6022 0 17.2206 0.158035 16.9393 0.43934C16.658 0.720644 16.5 1.10218 16.5 1.5V3H7.5V1.5C7.5 1.10218 7.34196 0.720644 7.06066 0.43934C6.77936 0.158035 6.39782 0 6 0ZM6 7.5C5.60218 7.5 5.22064 7.65804 4.93934 7.93934C4.65804 8.22064 4.5 8.60218 4.5 9C4.5 9.39782 4.65804 9.77936 4.93934 10.0607C5.22064 10.342 5.60218 10.5 6 10.5H18C18.3978 10.5 18.7794 10.342 19.0607 10.0607C19.342 9.77936 19.5 9.39782 19.5 9C19.5 8.60218 19.342 8.22064 19.0607 7.93934C18.7794 7.65804 18.3978 7.5 18 7.5H6Z" fill="#1036CE"/>
                      </svg>
                      <p className="cblue fw600 fs18 ms-3 me-3 mb-2">
                        <FormattedMessage 
                          id="startdate.text"
                          defaultMessage="Start Date"
                          description="Start Date"
                        />
                      </p>
                    </div>

                    <div className="position-relative leases-date-form">
                      <DatePickerMulti 
                        // multiple
                        value={startDate} 
                        onChange={(e) => onChangeStartDate(e)}
                        // minDate={minDateMonthly}
                        // maxDate={maxDateMonthly}
                        minDate={new Date()}
                        className="rmdp-mobile"
                        style={{
                          color: "#212529",
                          fontSize: "1rem",
                          background: "#fff",
                          border: "1px solid #AAAAAA",
                          borderRadius:"4px",
                          height: "48px",
                          display: "block",
                          width: "100%",
                          padding: ".375rem .75rem",
                        }}
                        mapDays={({ date }) => {
                          let dtCurrent = date.year + "-" + ('0' + date.month.number).slice(-2) + "-" + ('0' + date.day).slice(-2);
                          // let undt = unAvailDates.includes(dtCurrent);
                          let undt = monthlyAvailDays.includes(dtCurrent.toString());
                          if (!undt) return {
                            disabled: true,
                            style: { color: "#ccc" },
                            // onClick: () => alert("Already Booked Date")
                          }
                        }}
                      />
                      <svg width="24" height="24" viewBox="0 0 24 24" style={{position:"absolute", top:"11px", right:"20px"}} fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M6 0C5.60218 0 5.22064 0.158035 4.93934 0.43934C4.65804 0.720644 4.5 1.10218 4.5 1.5V3H3C2.20435 3 1.44129 3.31607 0.87868 3.87868C0.31607 4.44129 0 5.20435 0 6V21C0 21.7956 0.31607 22.5587 0.87868 23.1213C1.44129 23.6839 2.20435 24 3 24H21C21.7956 24 22.5587 23.6839 23.1213 23.1213C23.6839 22.5587 24 21.7956 24 21V6C24 5.20435 23.6839 4.44129 23.1213 3.87868C22.5587 3.31607 21.7956 3 21 3H19.5V1.5C19.5 1.10218 19.342 0.720644 19.0607 0.43934C18.7794 0.158035 18.3978 0 18 0C17.6022 0 17.2206 0.158035 16.9393 0.43934C16.658 0.720644 16.5 1.10218 16.5 1.5V3H7.5V1.5C7.5 1.10218 7.34196 0.720644 7.06066 0.43934C6.77936 0.158035 6.39782 0 6 0ZM6 7.5C5.60218 7.5 5.22064 7.65804 4.93934 7.93934C4.65804 8.22064 4.5 8.60218 4.5 9C4.5 9.39782 4.65804 9.77936 4.93934 10.0607C5.22064 10.342 5.60218 10.5 6 10.5H18C18.3978 10.5 18.7794 10.342 19.0607 10.0607C19.342 9.77936 19.5 9.39782 19.5 9C19.5 8.60218 19.342 8.22064 19.0607 7.93934C18.7794 7.65804 18.3978 7.5 18 7.5H6Z" fill="#FF5833"/>
                      </svg>
                    </div>

                    
                  </div>


                  <div className="mb-4">
                    <div className="d-flex">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M6 0C5.60218 0 5.22064 0.158035 4.93934 0.43934C4.65804 0.720644 4.5 1.10218 4.5 1.5V3H3C2.20435 3 1.44129 3.31607 0.87868 3.87868C0.31607 4.44129 0 5.20435 0 6V21C0 21.7956 0.31607 22.5587 0.87868 23.1213C1.44129 23.6839 2.20435 24 3 24H21C21.7956 24 22.5587 23.6839 23.1213 23.1213C23.6839 22.5587 24 21.7956 24 21V6C24 5.20435 23.6839 4.44129 23.1213 3.87868C22.5587 3.31607 21.7956 3 21 3H19.5V1.5C19.5 1.10218 19.342 0.720644 19.0607 0.43934C18.7794 0.158035 18.3978 0 18 0C17.6022 0 17.2206 0.158035 16.9393 0.43934C16.658 0.720644 16.5 1.10218 16.5 1.5V3H7.5V1.5C7.5 1.10218 7.34196 0.720644 7.06066 0.43934C6.77936 0.158035 6.39782 0 6 0ZM6 7.5C5.60218 7.5 5.22064 7.65804 4.93934 7.93934C4.65804 8.22064 4.5 8.60218 4.5 9C4.5 9.39782 4.65804 9.77936 4.93934 10.0607C5.22064 10.342 5.60218 10.5 6 10.5H18C18.3978 10.5 18.7794 10.342 19.0607 10.0607C19.342 9.77936 19.5 9.39782 19.5 9C19.5 8.60218 19.342 8.22064 19.0607 7.93934C18.7794 7.65804 18.3978 7.5 18 7.5H6Z" fill="#1036CE"/>
                      </svg>
                      <p className="cblue fw600 fs18 ms-3 me-3 mb-2">
                        <FormattedMessage 
                          id="duration.text"
                          defaultMessage="Duration"
                          description="Duration"
                        />
                      </p>
                    </div>

                    <select className="form-select reg-select-form" onChange={onChangeDuration}>
                      <option value="">Select a duration</option>
                      {postDuration && postDuration >= 1 && (<option value="1">1 Month</option>)}
                      {postDuration && postDuration >= 2 && (<option value="2">2 Months</option>)}
                      {postDuration && postDuration >= 3 && (<option value="3">3 Months</option>)}
                      {postDuration && postDuration >= 4 && (<option value="4">4 Months</option>)}
                      {postDuration && postDuration >= 5 && (<option value="5">5 Months</option>)}
                      {postDuration && postDuration >= 6 && (<option value="6">6 Months</option>)}
                      {postDuration && postDuration >= 7 && (<option value="7">7 Months</option>)}
                      {postDuration && postDuration >= 8 && (<option value="8">8 Months</option>)}
                      {postDuration && postDuration >= 9 && (<option value="9">9 Months</option>)}
                      {postDuration && postDuration >= 10 && (<option value="10">10 Months</option>)}
                      {postDuration && postDuration >= 11 && (<option value="11">11 Months</option>)}
                      {postDuration && postDuration >= 12 && (<option value="12">12 Months</option>)}
                    </select>
                  </div>


                  <div className="mb-4">
                    <div className="d-flex">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M6 0C5.60218 0 5.22064 0.158035 4.93934 0.43934C4.65804 0.720644 4.5 1.10218 4.5 1.5V3H3C2.20435 3 1.44129 3.31607 0.87868 3.87868C0.31607 4.44129 0 5.20435 0 6V21C0 21.7956 0.31607 22.5587 0.87868 23.1213C1.44129 23.6839 2.20435 24 3 24H21C21.7956 24 22.5587 23.6839 23.1213 23.1213C23.6839 22.5587 24 21.7956 24 21V6C24 5.20435 23.6839 4.44129 23.1213 3.87868C22.5587 3.31607 21.7956 3 21 3H19.5V1.5C19.5 1.10218 19.342 0.720644 19.0607 0.43934C18.7794 0.158035 18.3978 0 18 0C17.6022 0 17.2206 0.158035 16.9393 0.43934C16.658 0.720644 16.5 1.10218 16.5 1.5V3H7.5V1.5C7.5 1.10218 7.34196 0.720644 7.06066 0.43934C6.77936 0.158035 6.39782 0 6 0ZM6 7.5C5.60218 7.5 5.22064 7.65804 4.93934 7.93934C4.65804 8.22064 4.5 8.60218 4.5 9C4.5 9.39782 4.65804 9.77936 4.93934 10.0607C5.22064 10.342 5.60218 10.5 6 10.5H18C18.3978 10.5 18.7794 10.342 19.0607 10.0607C19.342 9.77936 19.5 9.39782 19.5 9C19.5 8.60218 19.342 8.22064 19.0607 7.93934C18.7794 7.65804 18.3978 7.5 18 7.5H6Z" fill="#1036CE"/>
                      </svg>
                      <p className="cblue fw600 fs18 ms-3 me-3 mb-2">
                        <FormattedMessage 
                          id="enddate.text"
                          defaultMessage="End Date"
                          description="End Date"
                        />
                      </p>
                    </div>

                    <div className="position-relative leases-date-form">
                      <DatePickerMulti 
                        // multiple
                        value={endDate} 
                        onChange={(e) => onChangeEndDate(e)}
                        className="rmdp-mobile"
                        disabled
                        style={{
                          color: "#212529",
                          fontSize: "1rem",
                          background: "#fff",
                          border: "1px solid #AAAAAA",
                          borderRadius:"4px",
                          height: "48px",
                          display: "block",
                          width: "100%",
                          padding: ".375rem .75rem",
                        }}
                      />
                      <svg width="24" height="24" viewBox="0 0 24 24" style={{position:"absolute", top:"11px", right:"20px"}} fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M6 0C5.60218 0 5.22064 0.158035 4.93934 0.43934C4.65804 0.720644 4.5 1.10218 4.5 1.5V3H3C2.20435 3 1.44129 3.31607 0.87868 3.87868C0.31607 4.44129 0 5.20435 0 6V21C0 21.7956 0.31607 22.5587 0.87868 23.1213C1.44129 23.6839 2.20435 24 3 24H21C21.7956 24 22.5587 23.6839 23.1213 23.1213C23.6839 22.5587 24 21.7956 24 21V6C24 5.20435 23.6839 4.44129 23.1213 3.87868C22.5587 3.31607 21.7956 3 21 3H19.5V1.5C19.5 1.10218 19.342 0.720644 19.0607 0.43934C18.7794 0.158035 18.3978 0 18 0C17.6022 0 17.2206 0.158035 16.9393 0.43934C16.658 0.720644 16.5 1.10218 16.5 1.5V3H7.5V1.5C7.5 1.10218 7.34196 0.720644 7.06066 0.43934C6.77936 0.158035 6.39782 0 6 0ZM6 7.5C5.60218 7.5 5.22064 7.65804 4.93934 7.93934C4.65804 8.22064 4.5 8.60218 4.5 9C4.5 9.39782 4.65804 9.77936 4.93934 10.0607C5.22064 10.342 5.60218 10.5 6 10.5H18C18.3978 10.5 18.7794 10.342 19.0607 10.0607C19.342 9.77936 19.5 9.39782 19.5 9C19.5 8.60218 19.342 8.22064 19.0607 7.93934C18.7794 7.65804 18.3978 7.5 18 7.5H6Z" fill="#FF5833"/>
                      </svg>
                    </div>
                  </div>



                  <button className="btn btn-payment mb-4 w-100 mt-4" onClick={onClickPayment}>
                    <FormattedMessage 
                      id="proceedpayment.text"
                      defaultMessage="Proceed to Payment"
                      description="Proceed to Payment"
                    />
                  </button>

                </div>
              ) :
              !postLeaseTermAvailabilityLoading && postLeaseTermAvailability && postLeaseTermAvailability?.data.length === 0 ? (
                <p className="cblue fw600 fs18">
                  <FormattedMessage 
                    id="noavailability.text"
                    defaultMessage="No Availability"
                    description="No Availability"
                  />
                </p>
              ) : (
                <div className="loader"></div>
              )}
              

            </div>
          </div>
        </div>
      </div>
    )}

    </div>
  )
}

export default LeaseForm;

import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams, generatePath, useHistory } from "react-router-dom";
import { Carousel, Modal, Button } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import LeaseMap from "./leasemap";
import LeaseForm from "./leaseform";
import { scrollToTop, translateTerm } from "../../util/helper";
import { DateTime } from "luxon";
import Swal from 'sweetalert2';
import {FormattedMessage, useIntl} from 'react-intl';
import { FirebaseContext } from "../../contexts/firebase.context";
import { RouteContext } from "../../contexts/route.context";
import nophoto from "../../assets/images/renterlogo.svg";
import navicon from "../../assets/images/navicon.png";
import { useGeolocated } from "react-geolocated";
import PickUpIcon from "../../assets/images/pick-up-icon.svg"
import DropOffIcon from "../../assets/images/drop-off-icon.svg"

const LeaseSingle = () => {
  const cookies = new Cookies();
  const history = useHistory();
  const intl = useIntl();
  const { id } = useParams();
  // let post = props.post;
  const ref = useRef(null);
  const { baseURL, apiHeaders, getMe, me, getChatList, chatList, getViewPost, viewPost } = useContext(RouteContext);
  const { database } = useContext(FirebaseContext);
  const [bannerIndex, setBannerIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [chosenChatMsg, setChosenChatMsg] = useState("");
  const [mapShowModal, setMapShowModal] = useState(false);
  const [leaseFormShowModal, setLeaseFormShowModal] = useState(false);
  const [leaseShowModal, setLeaseShowModal] = useState(false);
  const [chosenPost, setChosenPost] = useState();
  const [chosenTerm, setChosenTerm] = useState();
  const [chosenPostLeaseTermId, setChosenPostLeaseTermId] = useState();
  const [chosenChatLoading, setChosenChatLoading] = useState(false);
  const [slideInterval, setSlideInterval] = useState(true);

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
  useGeolocated({
      positionOptions: {
          enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
  });
  
  useEffect(() => {
    getViewPost(id);
  }, [id]);
  
  useEffect(() => {
    getMe();
  }, []);


  useEffect(() => {
    if(me && viewPost) {
      getChatList(me.id, viewPost.user.id);
    }
  }, [viewPost, me]);
  

  const handleSelectBanner = (selectedIndex, e) => {
    setBannerIndex(selectedIndex);
  };
  const onPrevClick = () => {
    ref.current.prev();
  };
  const onNextClick = () => {
    ref.current.next();
  };

  const handleCloseModal = () => setShowModal(false);
  const handleCloseMapModal = () => setMapShowModal(false);
  const handleCloseLeaseModal = () => setLeaseShowModal(false);
  const handleCloseLeaseFormModal = () => 
    {
      setLeaseFormShowModal(false);
      setSlideInterval(true)
    }


  const onClickLease = () => {
    if(me) {
      setLeaseShowModal(true);
    }
    else {
      window.location.href = "/login";
      localStorage.setItem("login_booked_id", id);
    }
  }


  const onClickLeaseNow = (e, pt, term, post_lease_term_id) => {
    e.preventDefault();
    setChosenPost(pt);
    setChosenTerm(term);
    setChosenPostLeaseTermId(post_lease_term_id);
    setLeaseShowModal(false);
    setLeaseFormShowModal(true);
    setSlideInterval(false);
    scrollToTop(0);
  }

  const changeMsg = (msg) => {
    setChosenChatLoading(true);
    setChosenChatMsg(msg);
    setTimeout(() => setChosenChatLoading(false), 200);
  }

  function updateLastMsgUser() {
    let refUsers = "users/" + me.id;
    let usersFilteredRef = database
      .ref(refUsers)
      .orderByChild("user_id")
      .equalTo(viewPost.user.id);
    let userKey;

    usersFilteredRef.once("value", (snapshot) => {
      const usersObj = (snapshot && snapshot.val()) || {};

      const usersArr =
        (usersObj &&
          Object.keys(usersObj) &&
          Object.keys(usersObj).length &&
          Object.keys(usersObj).map((key) => {
            usersObj[key].key = key;
            // delete usersObj[key].children;
            return usersObj[key];
          })) ||
        [];

      userKey = usersArr[0].key;

      let refUserFinal = refUsers + "/" + userKey;
  
      database
        .ref(refUserFinal)
        .update({
          date: DateTime.now().toFormat('MMMM dd, yyyy'),
          last_message: chosenChatMsg,
          time: new Date().toLocaleTimeString().replace(/(.*)\D\d+/, '$1'),
        });
    });
    
  }

  const onSubmitChat = () => {
    if(chatList && chatList.length !== 0) {
      if(chosenChatMsg !== "") {
        let chatDetails = {
          attachment: "",
          customer_id: viewPost.user.id,
          date: DateTime.now().toFormat('MMMM dd, yyyy'),
          message: chosenChatMsg,
          sender_id: me.id,
          time: new Date().toLocaleTimeString().replace(/(.*)\D\d+/, '$1'),
        }
        
        let refChat = "room/fb_user" + me.id + "_fb_user" + viewPost.user.id;
        database.ref(refChat).push(chatDetails);
        updateLastMsgUser();
        setChosenChatMsg("");
        setShowModal(false);
      }
    }
    else {
      let chatUserDetailsLessee = {
        chat_count:1,
        date: DateTime.now().toFormat('MMMM dd, yyyy'),
        last_message: chosenChatMsg,
        time: new Date().toLocaleTimeString().replace(/(.*)\D\d+/, '$1'),
        user_id: viewPost.user.id,
      }
      let chatUserDetailsLessor = {
        chat_count:1,
        date: DateTime.now().toFormat('MMMM dd, yyyy'),
        last_message: chosenChatMsg,
        time: new Date().toLocaleTimeString().replace(/(.*)\D\d+/, '$1'),
        user_id: me.id,
      }
      
      let refLesseeUserChat = "users/" + me.id;
      database.ref(refLesseeUserChat).push(chatUserDetailsLessee);

      let refLessorUserChat = "users/" + viewPost.user.id;
      database.ref(refLessorUserChat).push(chatUserDetailsLessor);

      
      if(chosenChatMsg !== "") {
        let chatDetails = {
          attachment: "",
          customer_id: viewPost.user.id,
          date: DateTime.now().toFormat('MMMM dd, yyyy'),
          message: chosenChatMsg,
          sender_id: me.id,
          time: new Date().toLocaleTimeString().replace(/(.*)\D\d+/, '$1'),
        }
        
        let refChat = "room/fb_user" + me.id + "_fb_user" + viewPost.user.id;
        database.ref(refChat).push(chatDetails);
        updateLastMsgUser();
        setChosenChatMsg("");
        setShowModal(false);
      }
    }

    getChatList(me.id, viewPost.user.id);
  }

  const onClickLessor = () => {
    if(me) {
      cookies.set('view_lessor_id', viewPost.user.id, { path: '/'});
      // window.location.href = "/lessor-profile";
      
      history.push(generatePath("/lessor-profile/:id", {id: viewPost.user.id}));
    } else {
      Swal.fire({
        title: intl.locale === "en" ? 'Need to login' : 'يتطلب تسجيل الدخول',
        text: intl.locale === "en" ? "Login as lessee to access lessor's profile" : "قم بتسجيل الدخول كمستأجر لاستعراض حساب المؤجر",
        icon: 'warning',
        confirmButtonText: intl.locale === "en" ? "Ok" : "حسنا",
        confirmButtonColor: '#ff9035',
      });
    }
  }

  // const onClickNavigate = () => {
    
  // }

  const onMapClicked = () => {
    if(isGeolocationAvailable){
      if(isGeolocationEnabled){
        window.open("https://www.google.com/maps/dir/"+coords?.latitude+","+coords?.longitude+"/"+viewPost?.latitude+","+viewPost?.longitude)
      }
      else{
         window.open("https://maps.google.com?q="+viewPost?.latitude+","+viewPost?.longitude);
      }
    }
    else{
      window.open("https://maps.google.com?q="+viewPost?.latitude+","+viewPost?.longitude);
    }
  }

  // console.log(viewPost)
  

  const ClaimComponent = ({PickUpIcon, DropOffIcon, claim}) => {
    if(claim === "pick_up")
    {
      return(
        <div className="claimTypeLease d-flex content-center mb-2 rounded"> 
          <div className="mx-1 my-1">
            <FormattedMessage 
            id="pickup.text"
            defaultMessage="Pick up"
            description="Pick up"
            />
          </div>
          <img className="claimIcon" src={PickUpIcon}/>
        </div>
      )
    }
    else if(claim === "drop_off"){
      return(
      <div className="claimTypeLease d-flex content-center mb-2 rounded"> 
          <div className="mx-1 my-1">
            <FormattedMessage 
            id="dropoff.text"
            defaultMessage="Drop off"
            description="Drop off"
            />
          </div>
          <img className="claimIcon" src={DropOffIcon}/>
        </div>
      )
    }
    else{
      return(null)
    }
  
  }

  return (
    <div>
      {/* {chosenPost ? (
        <LeaseForm post={chosenPost} term={chosenTerm} chosenPostLeaseTermId={chosenPostLeaseTermId} />
      ) : ( */}
        <div className="container-fluid leases-single-div">
          <div className="row d-flex">
            <div className="col-lg-6 px-0 position-relative carousel-lease-single-div">
              <Carousel 
                ref={ref} 
                controls={false} 
                indicators={false} 
                activeIndex={bannerIndex} 
                interval={slideInterval ? 3000 : null}
                onSelect={handleSelectBanner}
              >
                {viewPost && viewPost.post_images.map((img, index) => (
                  <Carousel.Item key={index} className="object-fit-contain">
                    <img
                      className="d-block w-100 object-fit-contain"
                      src={img.image}
                      alt="First slide"
                    />
                  </Carousel.Item>
                ))}
              </Carousel>

              <div className="carousel-lease-single">
                <button className="btn btn-back" onClick={onPrevClick}>
                  <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M7.24863 11.6483C7.4736 11.4233 7.59998 11.1181 7.59998 10.7999C7.59998 10.4817 7.4736 10.1765 7.24863 9.9515L3.29703 5.9999L7.24863 2.0483C7.46722 1.82198 7.58818 1.51886 7.58544 1.20422C7.58271 0.889585 7.4565 0.58861 7.23401 0.366121C7.01152 0.143631 6.71055 0.0174279 6.39591 0.0146938C6.08128 0.0119597 5.77815 0.132913 5.55183 0.351503L0.751832 5.1515C0.526866 5.37654 0.400488 5.68171 0.400488 5.9999C0.400488 6.3181 0.526866 6.62327 0.751832 6.8483L5.55183 11.6483C5.77687 11.8733 6.08203 11.9996 6.40023 11.9996C6.71843 11.9996 7.0236 11.8733 7.24863 11.6483Z" fill="#AAAAAA"/>
                  </svg>
                </button>
                <button className="btn btn-next" onClick={onNextClick}>
                  <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0.751368 11.6483C0.526403 11.4233 0.400024 11.1181 0.400024 10.7999C0.400024 10.4817 0.526403 10.1765 0.751368 9.9515L4.70297 5.9999L0.751368 2.0483C0.532779 1.82198 0.411825 1.51886 0.414559 1.20422C0.417293 0.889585 0.543496 0.58861 0.765986 0.366121C0.988476 0.143631 1.28945 0.0174279 1.60409 0.0146938C1.91872 0.0119597 2.22185 0.132913 2.44817 0.351503L7.24817 5.1515C7.47313 5.37654 7.59951 5.68171 7.59951 5.9999C7.59951 6.3181 7.47313 6.62327 7.24817 6.8483L2.44817 11.6483C2.22313 11.8733 1.91797 11.9996 1.59977 11.9996C1.28157 11.9996 0.976402 11.8733 0.751368 11.6483Z" fill="#FF5833"/>
                  </svg>
                </button>
              </div>
            </div>
            <div className="col-12 col-lg-6 d-flex justify-content-center">
              <div className="lease-single-content-div">
              {/* claim_type */}
                <div className="d-flex justify-content-between">
                  {/* {
                  viewPost?.claim_type === "pick_up"
                  &&
                  <div className="claimTypeLease d-flex content-center mb-2"> 
                    <div className="mx-1">
                      Pick Up
                    </div>
                  <img className="claimIcon" src={PickUpIcon}/>
                  </div>
                  }
                  {
                  viewPost?.claim_type === "drop_off"
                  &&
                  <div className="claimTypeLease d-flex content-center mb-2"> 
                    <div className="mx-1">
                      Drop Off
                    </div>
                  <img className="claimIcon" src={DropOffIcon}/>
                  </div>
                  } */}
                  <ClaimComponent PickUpIcon={PickUpIcon} DropOffIcon={DropOffIcon} claim={viewPost?.claim_type}/>
                </div>
                <p className="cred fw700 fs14 mb-0">{viewPost ? (intl.locale === "en" ? viewPost.category.en : viewPost.category.ar) : ""}</p>
                <div className="d-flex justify-content-between">
                  <p className="cblue fw600 mb-0 lease-single-name">
                    {viewPost && viewPost.title}
                  </p>
                  <div className="d-flex">
                    {/* {
                      viewPost &&
                    <div className="cur-pointer mx-2" onClick={onClickNavigate}>
                      <img width="44" height="44" src={navicon} alt="navicon"/>
                    </div>
                    }
                     */}
                    {/* <div className="cur-pointer" onClick={() => setMapShowModal(true)}>
                      <svg width="22" height="24" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M16.2 0.537598L9.80001 6.9376V27.4624L16.2 21.0624V0.537598ZM2.93121 3.2688C2.70745 3.0451 2.42239 2.89277 2.11206 2.83106C1.80174 2.76936 1.48008 2.80104 1.18776 2.92211C0.895445 3.04319 0.645585 3.24821 0.46977 3.51126C0.293955 3.77432 0.20008 4.0836 0.200012 4.4V20.4C0.200103 20.8243 0.368733 21.2312 0.668812 21.5312L6.60001 27.4624V6.9376L2.93121 3.2688ZM25.3312 6.4688L19.4 0.537598V21.0624L23.0688 24.7312C23.2926 24.9549 23.5776 25.1072 23.888 25.1689C24.1983 25.2306 24.5199 25.199 24.8123 25.0779C25.1046 24.9568 25.3544 24.7518 25.5303 24.4887C25.7061 24.2257 25.7999 23.9164 25.8 23.6V7.6C25.7999 7.17569 25.6313 6.76879 25.3312 6.4688Z" fill="white"/>
                      </svg>
                      <img width="44" height="44" src="https://img.icons8.com/external-flatart-icons-flat-flatarticons/64/external-map-hotel-services-and-city-elements-flatart-icons-flat-flatarticons.png" alt="external-map-hotel-services-and-city-elements-flatart-icons-flat-flatarticons"/>
                    </div> */}
                  </div>
                </div>
                {viewPost && viewPost.post_lease_terms.map((lt, index) => (
                      <div key={index}>
                        <h3 className="cblack fs20 fw800">SAR {lt.price} <span className="fs16">/ {translateTerm(lt.lease_term)}</span></h3>
                        {/* <p><FormattedMessage
                          id="available.text"
                          defaultMessage="Available:"
                          description="Available:"
                          /> {lt.quantity}
                        </p> */}
                      </div>
                    ))}
                <p className="cgray mb-3"><span className="fw700 cblack"><FormattedMessage 
                  id="description.text"
                  defaultMessage="Description"
                  description="Description"
                />:</span> {viewPost && viewPost.description}</p>
                
                
                <div className="">
                  {
                    viewPost && 
                      <LeaseMap onMapClicked={onMapClicked} post={viewPost} containerStyle={{position: 'relative', width: '100%', height: '240px'}} style={{display: "flex", position: 'relative', width: '100%', height: "240px"}}/>
                  }
                </div>
                <p className="cgray fs14 mb-3 mt-3">
                  <svg width="18" height="21" style={{marginRight:"10px", marginLeft:"10px"}} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M3.06022 2.85984C4.6356 1.28446 6.77228 0.399414 9.00021 0.399414C11.2281 0.399414 13.3648 1.28446 14.9402 2.85984C16.5156 4.43523 17.4006 6.57191 17.4006 8.79984C17.4006 11.0278 16.5156 13.1645 14.9402 14.7398L9.00021 20.6798L3.06022 14.7398C2.28011 13.9598 1.6613 13.0338 1.2391 12.0146C0.81691 10.9954 0.599609 9.90302 0.599609 8.79984C0.599609 7.69667 0.81691 6.6043 1.2391 5.5851C1.6613 4.56591 2.28011 3.63987 3.06022 2.85984ZM9.00021 11.1998C9.63673 11.1998 10.2472 10.947 10.6973 10.4969C11.1474 10.0468 11.4002 9.43636 11.4002 8.79984C11.4002 8.16332 11.1474 7.55287 10.6973 7.10279C10.2472 6.6527 9.63673 6.39984 9.00021 6.39984C8.3637 6.39984 7.75325 6.6527 7.30316 7.10279C6.85307 7.55287 6.60021 8.16332 6.60021 8.79984C6.60021 9.43636 6.85307 10.0468 7.30316 10.4969C7.75325 10.947 8.3637 11.1998 9.00021 11.1998Z" fill="#AAAAAA"/>
                  </svg>
                  {/* {viewPost ? (intl.locale === "en" ? viewPost.area.en : viewPost.area.ar) : ""} */}
                  {viewPost?.location}
                </p>
                <div className="lease-divider">
                  <span></span>
                </div>

                <div className="lease-single-owner-div">
                  <div className="d-flex justify-content-center align-items-center cur-pointer" onClick={onClickLessor}>
                    <div className="ls-owner-img-div me-3">
                      <img src={viewPost && viewPost.user.avatar ? viewPost.user.avatar : "../assets/images/profpic.svg"} className="imgenclose" />
                    </div>
                    <div>
                      <p className="fw700 cblack mb-0">{viewPost && viewPost.user.name}</p>
                      <p className="cgray fs14 mb-0">
                        <FormattedMessage 
                          id="owner.text"
                          defaultMessage="Owner"
                          description="Owner"
                        />
                      </p>
                    </div>
                  </div>
                  {me && (
                    <span className="chat-icon-div" onClick={() => setShowModal(true)}>
                      <svg width="22" height="20" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M25.8 12C25.8 18.1856 20.0688 23.2 13 23.2C10.7281 23.2096 8.4873 22.6718 6.46721 21.632L0.200012 23.2L2.34081 18.2032C0.988812 16.4272 0.200012 14.2944 0.200012 12C0.200012 5.81445 5.93121 0.800049 13 0.800049C20.0688 0.800049 25.8 5.81445 25.8 12ZM8.20001 10.4H5.00001V13.6H8.20001V10.4ZM21 10.4H17.8V13.6H21V10.4ZM11.4 10.4H14.6V13.6H11.4V10.4Z" fill="white"/>
                      </svg>
                    </span>
                  )}
                </div>

                <a className="btn btn-orang-def w-100 mb-4" onClick={onClickLease}>
                  <FormattedMessage 
                    id="leasenow.text"
                    defaultMessage="Lease now"
                    description="Lease now"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      {/* // ) */}
      {/* } */}
      

      {/* Modal chat */}
      <Modal className="modalChat" show={showModal} onHide={handleCloseModal}>
        <Modal.Body>
          <div className="lease-single-owner-div mb-4">
            <div className="d-flex justify-content-center align-items-center">
              <div className="ls-owner-img-div me-3">
                <img src={viewPost && viewPost.user.avatar ? viewPost.user.avatar : "../assets/images/profpic.svg"} className="imgenclose" />
              </div>
              <div>
                <p className="fw700 cblack mb-0">{viewPost && viewPost.user.name}</p>
                <p className="cgray fs14 mb-0">
                  <FormattedMessage 
                    id="owner.text"
                    defaultMessage="Owner"
                    description="Owner"
                  />
                </p>
              </div>
            </div>
            <a href="javascript:void(0);" onClick={() => setShowModal(false)}>
              <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.868483 0.868832C1.16853 0.568879 1.57542 0.400374 1.99968 0.400374C2.42395 0.400374 2.83084 0.568879 3.13088 0.868832L9.99968 7.73763L16.8685 0.868832C17.0161 0.716016 17.1926 0.594124 17.3878 0.51027C17.583 0.426416 17.793 0.382278 18.0054 0.380432C18.2179 0.378585 18.4286 0.419068 18.6252 0.499517C18.8218 0.579967 19.0005 0.698772 19.1507 0.849C19.3009 0.999228 19.4197 1.17787 19.5002 1.37451C19.5806 1.57114 19.6211 1.78183 19.6193 1.99427C19.6174 2.20672 19.5733 2.41667 19.4894 2.61188C19.4056 2.80709 19.2837 2.98364 19.1309 3.13123L12.2621 10L19.1309 16.8688C19.4223 17.1706 19.5836 17.5748 19.58 17.9943C19.5763 18.4138 19.408 18.8151 19.1114 19.1117C18.8147 19.4084 18.4134 19.5767 17.9939 19.5803C17.5744 19.584 17.1702 19.4227 16.8685 19.1312L9.99968 12.2624L3.13088 19.1312C2.82912 19.4227 2.42496 19.584 2.00544 19.5803C1.58593 19.5767 1.18463 19.4084 0.887973 19.1117C0.59132 18.8151 0.423049 18.4138 0.419404 17.9943C0.415758 17.5748 0.57703 17.1706 0.868483 16.8688L7.73728 10L0.868483 3.13123C0.568529 2.83119 0.400024 2.42429 0.400024 2.00003C0.400024 1.57577 0.568529 1.16888 0.868483 0.868832Z" fill="#FF5833"/>
              </svg>
            </a>
          </div>

          {!chosenChatLoading ? (
            <textarea className="form-control textarea-chat" placeholder="Write a custom message" rows="5" defaultValue={chosenChatMsg} onChange={(e) => setChosenChatMsg(e.target.value)}></textarea>
          ) : (
            <div className="loader"></div>
          )}

          {/* <div className="mt-4">
            <div 
              className={`chat-auto-msg ${chosenChatMsg === "Can I see more photos?" ? 'active' : ''}`}
              onClick={() => changeMsg("Can I see more photos?")}
            >
              <FormattedMessage 
                id="caniseemore.text"
                defaultMessage="Can I see more photos?"
                description="Can I see more photos?"
              />
            </div>
            <div
              className={`chat-auto-msg ${chosenChatMsg === "Can you provide me a map location?" ? 'active' : ''}`}
              onClick={() => changeMsg("Can you provide me a map location?")}
            >
              <FormattedMessage 
                id="canprovmap.text"
                defaultMessage="Can you provide me a map location?"
                description="Can you provide me a map location?"
              />
            </div>
            <div
              className={`chat-auto-msg ${chosenChatMsg === "I want to make a request" ? 'active' : ''}`}
              onClick={() => changeMsg("I want to make a request")}
            >
              <FormattedMessage 
                id="makereq.text"
                defaultMessage="I want to make a request"
                description="I want to make a request"
              />
            </div>
          </div> */}

          <button className="btn w-100 btn-chat-submit" disabled={chosenChatMsg === "" ? true : false} onClick={onSubmitChat}>
            <FormattedMessage 
              id="submit.text"
              defaultMessage="Submit"
              description="Submit"
            />
          </button>
        </Modal.Body>
      </Modal>


      {/* map modal */}
      <Modal className="modalChat" show={mapShowModal} onHide={handleCloseMapModal}>
        <Modal.Body className="modal-postshipment-div modal-map">
                  
          <div className="map-modal-div">
            <LeaseMap post={viewPost}/>
          </div>

        </Modal.Body>
      </Modal>


      {/* modal lease */}
      <Modal className="modalChat" show={leaseShowModal} onHide={handleCloseLeaseModal}>
        <Modal.Body>
          {viewPost && viewPost.post_lease_terms.map((plt, index) => (
            <a key={index} href="#" className="btn btn-payment w-100 mt-3 mb-4 fw700" onClick={(e) => onClickLeaseNow(e, viewPost, plt.lease_term, plt.id)}>{translateTerm(plt.lease_term)}</a>
          ))}
        </Modal.Body>
      </Modal>

      <Modal className={"modalLeaseForm modal-xl"} show={leaseFormShowModal && chosenPost} onHide={handleCloseLeaseFormModal}>
        {/* <Modal.Header className="justify-content-end">
          <Button type="submit" className="btn btn-modalLeaseFormButton" onClick={handleCloseLeaseFormModal}>
            Close
          </Button>
        </Modal.Header> */}
        <Modal.Body>
          <LeaseForm post={chosenPost} term={chosenTerm} chosenPostLeaseTermId={chosenPostLeaseTermId} />
        </Modal.Body>
      </Modal>

      
    </div>
  )
}

export default LeaseSingle;

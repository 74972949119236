import React, { useState, useEffect, useContext, useRef } from "react";
import { Tab, Tabs, Modal } from 'react-bootstrap';
import { generatePath, useHistory, useParams } from "react-router-dom";
import Cookies from 'universal-cookie';
import {FormattedMessage, useIntl} from 'react-intl';
import Pagination from "./pagination";
import { translateTerm, scrollToTop } from "../../util/helper";
import { DateTime } from "luxon";
import { FirebaseContext } from "../../contexts/firebase.context";
import { RouteContext } from "../../contexts/route.context";
import { useIntersection } from "../../util/useIntersection";
import defaultImage from "../../assets/images/profpic.svg"


const LessorProfile = () => {
  const history = useHistory();
  const { id } = useParams();
  const { database } = useContext(FirebaseContext);
  const intl = useIntl();
  const cookies = new Cookies();
  let lessor_id = cookies.get('view_lessor_id');
  const { getViewUser, viewUser, getPostReviews, postReviews, getAllPost, postReviewsLoading, setPostReviewsLoading, getUserPosts, 
    userPosts, userPostsLoading, setUserPostsLoading, getChatList, chatList, getMe, me, } = useContext(RouteContext);
  const [numLease, setNumLease] = useState(12);
  const [pageLease, setpageLease] = useState(1);
  const [showModalReview, setShowModalReview] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [starRateClick, setStarRateClick] = useState();
  const [showModal, setShowModal] = useState(false);
  const [chosenChatLoading, setChosenChatLoading] = useState(false);
  const [chosenChatMsg, setChosenChatMsg] = useState("");
  const [key, setKey] = useState('posts');
  const [numReview, setNumReview] = useState(5);
  const [pageReview, setpageReview] = useState(1);
  const [userProducts, setUserProducts] = useState();
  const [moreLoading, setMoreLoading] = useState(false);
  const [noMorePosts, setNoMorePosts] = useState(false);

  const elementRef = useRef(null);
  const isVisible = useIntersection(elementRef, "0px 0px 40% 0px");

  useEffect(() => {
    getViewUser(id);
    console.log("ASddsad",id, lessor_id)
    setNoMorePosts(false);
    getPostReviews(numReview, pageReview, id);

    setUserPostsLoading(true);
    let inp = `{user_id: "${id}"}`;
    getUserPosts(numLease, pageLease, inp);
    getProducts(inp)
  }, [lessor_id]);

  useEffect(() => {
    getMe();
  }, []);

  useEffect(() => {
    if (isVisible && userProducts) {
    // Trigger a function when the div is visible on view port
    triggerFunc();
    }
  }, [isVisible]);

  const triggerFunc = () => {
    console.log("EHHHH")
   let inp = `{user_id: "${id}"}`;
     if(userProducts){
         if(userProducts.paginatorInfo.hasMorePages){
           scrollPosts(inp);
           }
           else{
             setNoMorePosts(true);
           }
       }
  }

  const getProducts = async (inp) =>{
    let result = await getUserPosts(numLease, pageLease, inp);
    setUserProducts(result);
  }

  // useEffect(()=>{
  //   // if(!products){setProducts(posts);}
  //   if(userProducts){
  //     window.addEventListener('scroll', handleScroll);
  //     return () => {
  //         window.removeEventListener('scroll', handleScroll)
  //     };
  //   }
   
  // },[userProducts]);

  // const handleScroll = async () => {
  //   let inp = `{user_id: "${lessor_id}"}`;
  //   console.log(document.documentElement.offsetHeight);
  //     if(userProducts){
  //       if (Math.ceil(window.innerHeight  + document.documentElement.scrollTop) === document.documentElement.offsetHeight ) {
  //         if(userProducts.paginatorInfo.hasMorePages){
  //           scrollPosts(inp);
  //           }
  //           else{
  //             setNoMorePosts(true);
  //           }
  //         }
  //       }
  // };

  const scrollPosts = async (inp) => {
    if(moreLoading == false){
      setMoreLoading(true);
      let result = await getUserPosts(numLease, userProducts.paginatorInfo.currentPage+1, inp);
      if(userProducts.paginatorInfo.hasMorePages){
          result.data.map((dat)=>{
            userProducts.data.push(dat);
          })
          userProducts.paginatorInfo = result.paginatorInfo;
          if(result.paginatorInfo.count < numLease || !result.paginatorInfo.hasMorePages){
            setNoMorePosts(true);
        }
      }
      setMoreLoading(false);
    }
  }


  useEffect(() => {
    if(me && viewUser) {
      getChatList(me.id, viewUser.id);
    }
  }, [viewUser, me]);

  const handleCloseModal = () => setShowModal(false);
  const handleCloseModalReview = () => setShowModalReview(false);
  const handleCloseModalSuccess = () => setShowModalSuccess(false);

  const onClickStarRate = (rate) => {
    setStarRateClick(rate);
  }

  const onSubmitRate = () => {
    handleCloseModalReview();
    setShowModalSuccess(true);
  }


  function starRate(rev) {
    let numStar = parseInt(rev.star);
    const rows = [];
    for (let i = 0; i < numStar; i++) {
        rows.push(
          <svg key={i} width="26" height="25" style={{marginRight:"5px"}} viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.2186 1.56079C13.6861 0.125569 15.7166 0.125569 16.1825 1.56079L18.5496 8.84444C18.6515 9.15687 18.8496 9.4291 19.1154 9.62222C19.3813 9.81533 19.7014 9.91945 20.0301 9.91969H27.6893C29.1993 9.91969 29.8257 11.852 28.6056 12.7403L22.4096 17.2407C22.1435 17.4342 21.9454 17.707 21.8438 18.02C21.7422 18.3329 21.7422 18.67 21.844 18.983L24.2095 26.2666C24.677 27.7034 23.033 28.8971 21.8128 28.0088L15.6169 23.5084C15.3506 23.3148 15.0298 23.2105 14.7006 23.2105C14.3713 23.2105 14.0506 23.3148 13.7843 23.5084L7.58833 28.0088C6.36816 28.8971 4.72411 27.7018 5.19161 26.2666L7.55716 18.983C7.65889 18.67 7.65895 18.3329 7.55733 18.02C7.45572 17.707 7.25764 17.4342 6.99149 17.2407L0.795555 12.7403C-0.426178 11.852 0.203389 9.91969 1.71186 9.91969H9.36951C9.69839 9.91978 10.0189 9.81581 10.2851 9.62267C10.5513 9.42953 10.7495 9.15712 10.8515 8.84444L13.2186 1.56079Z" fill="#FCE412"/>
          </svg>
        );
    }
    return rows;
  }
  

  const onClickPost = (id) => {
    scrollToTop(0);
    id && history.push(generatePath("/lease/:id", { id }));
  }

  function updateLastMsgUser() {
    let refUsers = "users/" + me.id;
    let usersFilteredRef = database
      .ref(refUsers)
      .orderByChild("user_id")
      .equalTo(viewUser.id);
    let userKey;

    usersFilteredRef.once("value", (snapshot) => {
      const usersObj = (snapshot && snapshot.val()) || {};

      const usersArr =
        (usersObj &&
          Object.keys(usersObj) &&
          Object.keys(usersObj).length &&
          Object.keys(usersObj).map((key) => {
            usersObj[key].key = key;
            // delete usersObj[key].children;
            return usersObj[key];
          })) ||
        [];

      userKey = usersArr[0].key;

      let refUserFinal = refUsers + "/" + userKey;
  
      database
        .ref(refUserFinal)
        .update({
          date: DateTime.now().toFormat('MMMM dd, yyyy'),
          last_message: chosenChatMsg,
          time: new Date().toLocaleTimeString().replace(/(.*)\D\d+/, '$1'),
        });
    });
    
  }

  const onSubmitChat = () => {
    if(chatList && chatList.length !== 0) {
      if(chosenChatMsg !== "") {
        let chatDetails = {
          attachment: "",
          customer_id: viewUser.id,
          date: DateTime.now().toFormat('MMMM dd, yyyy'),
          message: chosenChatMsg,
          sender_id: me.id,
          time: new Date().toLocaleTimeString().replace(/(.*)\D\d+/, '$1'),
        }
        
        let refChat = "room/fb_user" + me.id + "_fb_user" + viewUser.id;
        database.ref(refChat).push(chatDetails);
        updateLastMsgUser();
        setChosenChatMsg("");
        setShowModal(false);
      }
    }
    else {
      let chatUserDetailsLessee = {
        chat_count:1,
        date: DateTime.now().toFormat('MMMM dd, yyyy'),
        last_message: chosenChatMsg,
        time: new Date().toLocaleTimeString().replace(/(.*)\D\d+/, '$1'),
        user_id: viewUser.id,
      }
      let chatUserDetailsLessor = {
        chat_count:1,
        date: DateTime.now().toFormat('MMMM dd, yyyy'),
        last_message: chosenChatMsg,
        time: new Date().toLocaleTimeString().replace(/(.*)\D\d+/, '$1'),
        user_id: me.id,
      }
      
      let refLesseeUserChat = "users/" + me.id;
      database.ref(refLesseeUserChat).push(chatUserDetailsLessee);

      let refLessorUserChat = "users/" + viewUser.id;
      database.ref(refLessorUserChat).push(chatUserDetailsLessor);

      
      if(chosenChatMsg !== "") {
        let chatDetails = {
          attachment: "",
          customer_id: viewUser.id,
          date: DateTime.now().toFormat('MMMM dd, yyyy'),
          message: chosenChatMsg,
          sender_id: me.id,
          time: new Date().toLocaleTimeString().replace(/(.*)\D\d+/, '$1'),
        }
        
        let refChat = "room/fb_user" + me.id + "_fb_user" + viewUser.id;
        database.ref(refChat).push(chatDetails);
        updateLastMsgUser();
        setChosenChatMsg("");
        setShowModal(false);
      }
    }

    getChatList(me.id, viewUser.id);
  }

  return (
    <div>
      <div className="container-fluid lessor-profile-top-div">
        <div className="container main-wrap">
          <div className="row">
            <div className="col-12 col-lg-7 d-flex">
              
              <div className="lessor-profile-img-div-lessee border">
                <img src={viewUser && viewUser.avatar ? viewUser.avatar : defaultImage} className="imgenclose" />
              </div>
              <div className="lessor-name-div-lessee">
                <p className="mb-2 fw700 cwhite fs30">{viewUser && viewUser.name}</p>
                <p className="mb-0 cwhite fs14 d-flex">
                  <svg width="18" height="18" style={{marginRight: intl.locale === "en" ? "6px" : 0, marginLeft: intl.locale === "ar" ? "6px" : "0px"}} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.2428 12.4925L10.0605 15.6748C9.92133 15.8141 9.75608 15.9246 9.57417 16C9.39227 16.0754 9.19729 16.1142 9.00038 16.1142C8.80347 16.1142 8.60849 16.0754 8.42658 16C8.24468 15.9246 8.07942 15.8141 7.94025 15.6748L4.75725 12.4925C3.91817 11.6534 3.34675 10.5843 3.11527 9.42043C2.88378 8.25655 3.00262 7.05017 3.45676 5.95383C3.91089 4.85749 4.67993 3.92044 5.66661 3.26116C6.6533 2.60189 7.81333 2.25 9 2.25C10.1867 2.25 11.3467 2.60189 12.3334 3.26116C13.3201 3.92044 14.0891 4.85749 14.5433 5.95383C14.9974 7.05017 15.1162 8.25655 14.8847 9.42043C14.6533 10.5843 14.0818 11.6534 13.2428 12.4925V12.4925Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M11.25 8.25C11.25 8.84674 11.0129 9.41903 10.591 9.84099C10.169 10.2629 9.59674 10.5 9 10.5C8.40326 10.5 7.83097 10.2629 7.40901 9.84099C6.98705 9.41903 6.75 8.84674 6.75 8.25C6.75 7.65326 6.98705 7.08097 7.40901 6.65901C7.83097 6.23705 8.40326 6 9 6C9.59674 6 10.169 6.23705 10.591 6.65901C11.0129 7.08097 11.25 7.65326 11.25 8.25V8.25Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  {viewUser && viewUser.address}
                </p>
              </div>

            </div>
            <div className="col-12 col-lg-5">
              
            <div className="d-flex lessor-profile-btn-div justify-content-end">
              <button
                onClick={() => setShowModal(true)}
                className="btn btn-post-review w-50 me-3"
              >
                <FormattedMessage 
                  id="msgnow.text"
                  defaultMessage="Message Now"
                  description="Message Now"
                />
              </button>
              {/* <button className="btn btn-cancel-lease w-50 cred" onClick={() => setShowModalReview(true)}>Rate Now</button> */}
            </div>

            </div>
          </div>
        </div>
      </div>

      <div className="container main-wrap pb-5">
        <div className="row">
          <div className="col-12">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className={`product-categories profile-product-categories justify-content-start ${intl.locale === "ar" && "lessor-tabs"} `}
            >
              <Tab eventKey="posts" title={intl.formatMessage({
                id: 'posts.text',
                defaultMessage: 'Posts',
                description: 'Posts',
              })}>

                {!userPostsLoading ? (
                  userProducts && userProducts.data.length !== 0 ? (
                    <div className="row pt-5">
                      {userProducts.data.map((post, index) => (
                        <div key={index} className="col-12 col-lg-4 cur-pointer" onClick={() => onClickPost(post.id)}>
                          <div className="prod-list-single-div">
                            <div className="prod-img-div">
                              <img src={post.post_images.length !== 0 ? post.post_images[0].image : "assets/images/prod1.png"} className="imgenclose prod-img-content" />
                            </div>
                            <div className="prod-upper-text-div">
                              {post.post_lease_terms.map((term, index) => (
                                <div key={index} className="p-2">
                                  <p key={index} className="price fw800 cveryblack">SR {term.price} <span className="fw500">/{translateTerm(term.lease_term)}</span></p>
                                  {/* <p className="fw500">/{translateTerm(term.lease_term)}</p> */}
                                </div>
                              ))}
                              <div>
                                <p className="fw500 cblack mb-0 fs12">
                                  <svg width="18" height="17" style={{marginRight:"8px", marginTop:"-4px"}} viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.0491 0.927078C9.3491 0.00607812 10.6521 0.00607812 10.9511 0.927078L12.4701 5.60108C12.5355 5.80157 12.6625 5.97626 12.8332 6.10018C13.0038 6.22411 13.2092 6.29092 13.4201 6.29108H18.3351C19.3041 6.29108 19.7061 7.53108 18.9231 8.10108L14.9471 10.9891C14.7763 11.1132 14.6492 11.2883 14.584 11.4891C14.5188 11.6899 14.5188 11.9063 14.5841 12.1071L16.1021 16.7811C16.4021 17.7031 15.3471 18.4691 14.5641 17.8991L10.5881 15.0111C10.4172 14.8869 10.2114 14.8199 10.0001 14.8199C9.78883 14.8199 9.58298 14.8869 9.4121 15.0111L5.4361 17.8991C4.6531 18.4691 3.5981 17.7021 3.8981 16.7811L5.4161 12.1071C5.48137 11.9063 5.48141 11.6899 5.4162 11.4891C5.351 11.2883 5.22389 11.1132 5.0531 10.9891L1.0771 8.10108C0.293095 7.53108 0.697095 6.29108 1.6651 6.29108H6.5791C6.79014 6.29113 6.9958 6.22442 7.16662 6.10048C7.33744 5.97654 7.46467 5.80173 7.5301 5.60108L9.0491 0.927078Z" fill="#FCE412"/>
                                  </svg>
                                  {post.review_average}
                                </p>
                                <p className="cgray fs12 mb-0">({post.review_count} 
                                  <FormattedMessage 
                                    id="reviews.text"
                                    defaultMessage="Reviews"
                                    description="Reviews"
                                  />)</p>
                              </div>
                            </div>

                            <p className="cblack fs18 mb-2">
                              <a href="#" className="cblack">{post.title}</a>
                            </p>
                            <p className="cgray fs14">
                              <svg width="18" height="21" style={{marginRight:"10px"}} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M3.06022 2.85984C4.6356 1.28446 6.77228 0.399414 9.00021 0.399414C11.2281 0.399414 13.3648 1.28446 14.9402 2.85984C16.5156 4.43523 17.4006 6.57191 17.4006 8.79984C17.4006 11.0278 16.5156 13.1645 14.9402 14.7398L9.00021 20.6798L3.06022 14.7398C2.28011 13.9598 1.6613 13.0338 1.2391 12.0146C0.81691 10.9954 0.599609 9.90302 0.599609 8.79984C0.599609 7.69667 0.81691 6.6043 1.2391 5.5851C1.6613 4.56591 2.28011 3.63987 3.06022 2.85984ZM9.00021 11.1998C9.63673 11.1998 10.2472 10.947 10.6973 10.4969C11.1474 10.0468 11.4002 9.43636 11.4002 8.79984C11.4002 8.16332 11.1474 7.55287 10.6973 7.10279C10.2472 6.6527 9.63673 6.39984 9.00021 6.39984C8.3637 6.39984 7.75325 6.6527 7.30316 7.10279C6.85307 7.55287 6.60021 8.16332 6.60021 8.79984C6.60021 9.43636 6.85307 10.0468 7.30316 10.4969C7.75325 10.947 8.3637 11.1998 9.00021 11.1998Z" fill="#AAAAAA"/>
                              </svg>
                              {intl.locale === "en" ? post.area.en : post.area.ar}
                            </p>
                          </div>
                        </div>
                      ))}
                        {moreLoading &&
                          <div className="loader"></div>
                        }
                        {noMorePosts && userProducts && 
                            <div className="nomoreposts d-flex justify-content-center">
                                <span className="cgray fs12">
                                    <FormattedMessage 
                                        id="nomoreposts.text"
                                        defaultMessage="No more available posts"
                                        description="No more available posts"
                                    />
                                </span>
                            </div>}

                      {/* <Pagination useFunction="getUserPosts" posts={userPosts} setPostsLoading={setUserPostsLoading} numLease={numLease} lessor_id={lessor_id} /> */}
                    </div>
                    
                  ) : (
                    // when no active lease
                    <div className="row mt-5">
                      <div className="col-12">
                        <div className="text-center">
                          <img src="assets/images/no-lease-img.png" className="img-fluid pt-5 mb-4" />
                          <p className="fs30 fw700 cgray">
                            <FormattedMessage 
                              id="havenoactivelease.text"
                              defaultMessage="You have no active lease"
                              description="You have no active lease"
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  )
                ) : (
                  <div className="loader"></div>
                )}

              </Tab>
              <Tab eventKey="reviews" title={intl.formatMessage({
                id: 'reviews.text',
                defaultMessage: 'Reviews',
                description: 'Reviews',
              })}>
                
                <div className="row pt-5">
                  {!postReviewsLoading ? (
                    postReviews && postReviews.data.length !== 0 && (
                      <>
                        {postReviews.data.map((rev, index) => {
                          return (
                            <div key={index} className="col-12 col-lg-6">
                              <div className="review-div">
                                <div className="d-flex">
                                  <div className="review-img-div">
                                    <img src={rev.user.avatar ? rev.user.avatar : defaultImage} className="imgenclose" />
                                  </div>
                                  <div className="review-content-div">
                                    <p className="mb-2 fw700 fs18 cblack d-flex justify-content-between">
                                      {rev.user.name}
                                      <span  className="fw400 fs14 cgray">{rev.created_at}</span>
                                    </p>
                                    <p className="mb-4 d-flex">
                                      {starRate(rev)}
                                    </p>
                                    <p className="fs14 cblack">{rev.comment}</p>
                                    
                                  </div>
                                </div>
                                <div className="review-post-img-div">
                                  <img src={rev.post.post_images ? rev.post.post_images[0].image : "assets/images/review-post-img.png"} className="imgenclose" />
                                </div>
                              </div>
                            </div>
                          )
                        })}
    
                        {/* <Pagination useFunction="getPostReviews" posts={postReviews} numLease={numReview} setPostsLoading={setPostReviewsLoading} lessor_id={lessor_id} /> */}
                      </>
                    )
                  ) : (
                    <div className="loader"></div>
                  )}
                  
                  
                  
                </div>

              </Tab>
            </Tabs>



            {/* Modal review */}
            <Modal className="" show={showModalReview} onHide={handleCloseModalReview}>
              <Modal.Body>
                <div className="modal-review-div">
                  <div className="modal-review-img-div">
                    <img src="assets/images/profpic.svg" className="imgenclose" />
                  </div>
                  <p className="mb-5 fw700 fs20 cblack text-center mt-4">Maryam Ahmad</p>

                  <div className="lease-divider-light">
                    <span></span>
                  </div>

                  <div className="review-rate-div">
                    <p className="fs16 cblack mb-4 text-center mt-5">Rate overall performance</p>
                    <p className="mb-4 text-center review-stars mb-5">
                      <svg width="46" height="42" className={`${starRateClick && starRateClick >= 1 ? "star-svg" : ""}`} onClick={() => onClickStarRate(1)} viewBox="0 0 86 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M38.3464 4.17185C39.6964 0.0273516 45.5599 0.0273516 46.9054 4.17185L53.7409 25.2049C54.0351 26.107 54.6069 26.8932 55.3747 27.4508C56.1425 28.0085 57.067 28.3092 58.0159 28.3099H80.1334C84.4939 28.3099 86.3029 33.8899 82.7794 36.4549L64.8874 49.4509C64.1189 50.0096 63.5469 50.7973 63.2534 51.701C62.96 52.6048 62.9602 53.5782 63.2539 54.4819L70.0849 75.5149C71.4349 79.6639 66.6874 83.1109 63.1639 80.5459L45.2719 67.5499C44.5029 66.9908 43.5766 66.6897 42.6259 66.6897C41.6752 66.6897 40.7489 66.9908 39.9799 67.5499L22.0879 80.5459C18.5644 83.1109 13.8169 79.6594 15.1669 75.5149L21.9979 54.4819C22.2917 53.5782 22.2918 52.6048 21.9984 51.701C21.705 50.7973 21.133 50.0096 20.3644 49.4509L2.47242 36.4549C-1.05558 33.8899 0.762418 28.3099 5.11842 28.3099H27.2314C28.1811 28.3101 29.1066 28.0099 29.8753 27.4521C30.644 26.8944 31.2165 26.1078 31.5109 25.2049L38.3464 4.17185V4.17185Z" stroke="#AAAAAA" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                      <svg width="46" height="42" className={`${starRateClick && starRateClick >= 2 ? "star-svg" : ""}`} onClick={() => onClickStarRate(2)} viewBox="0 0 86 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M38.3464 4.17185C39.6964 0.0273516 45.5599 0.0273516 46.9054 4.17185L53.7409 25.2049C54.0351 26.107 54.6069 26.8932 55.3747 27.4508C56.1425 28.0085 57.067 28.3092 58.0159 28.3099H80.1334C84.4939 28.3099 86.3029 33.8899 82.7794 36.4549L64.8874 49.4509C64.1189 50.0096 63.5469 50.7973 63.2534 51.701C62.96 52.6048 62.9602 53.5782 63.2539 54.4819L70.0849 75.5149C71.4349 79.6639 66.6874 83.1109 63.1639 80.5459L45.2719 67.5499C44.5029 66.9908 43.5766 66.6897 42.6259 66.6897C41.6752 66.6897 40.7489 66.9908 39.9799 67.5499L22.0879 80.5459C18.5644 83.1109 13.8169 79.6594 15.1669 75.5149L21.9979 54.4819C22.2917 53.5782 22.2918 52.6048 21.9984 51.701C21.705 50.7973 21.133 50.0096 20.3644 49.4509L2.47242 36.4549C-1.05558 33.8899 0.762418 28.3099 5.11842 28.3099H27.2314C28.1811 28.3101 29.1066 28.0099 29.8753 27.4521C30.644 26.8944 31.2165 26.1078 31.5109 25.2049L38.3464 4.17185V4.17185Z" stroke="#AAAAAA" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                      <svg width="46" height="42" className={`${starRateClick && starRateClick >= 3 ? "star-svg" : ""}`} onClick={() => onClickStarRate(3)} viewBox="0 0 86 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M38.3464 4.17185C39.6964 0.0273516 45.5599 0.0273516 46.9054 4.17185L53.7409 25.2049C54.0351 26.107 54.6069 26.8932 55.3747 27.4508C56.1425 28.0085 57.067 28.3092 58.0159 28.3099H80.1334C84.4939 28.3099 86.3029 33.8899 82.7794 36.4549L64.8874 49.4509C64.1189 50.0096 63.5469 50.7973 63.2534 51.701C62.96 52.6048 62.9602 53.5782 63.2539 54.4819L70.0849 75.5149C71.4349 79.6639 66.6874 83.1109 63.1639 80.5459L45.2719 67.5499C44.5029 66.9908 43.5766 66.6897 42.6259 66.6897C41.6752 66.6897 40.7489 66.9908 39.9799 67.5499L22.0879 80.5459C18.5644 83.1109 13.8169 79.6594 15.1669 75.5149L21.9979 54.4819C22.2917 53.5782 22.2918 52.6048 21.9984 51.701C21.705 50.7973 21.133 50.0096 20.3644 49.4509L2.47242 36.4549C-1.05558 33.8899 0.762418 28.3099 5.11842 28.3099H27.2314C28.1811 28.3101 29.1066 28.0099 29.8753 27.4521C30.644 26.8944 31.2165 26.1078 31.5109 25.2049L38.3464 4.17185V4.17185Z" stroke="#AAAAAA" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                      <svg width="46" height="42" className={`${starRateClick && starRateClick >= 4 ? "star-svg" : ""}`} onClick={() => onClickStarRate(4)} viewBox="0 0 86 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M38.3464 4.17185C39.6964 0.0273516 45.5599 0.0273516 46.9054 4.17185L53.7409 25.2049C54.0351 26.107 54.6069 26.8932 55.3747 27.4508C56.1425 28.0085 57.067 28.3092 58.0159 28.3099H80.1334C84.4939 28.3099 86.3029 33.8899 82.7794 36.4549L64.8874 49.4509C64.1189 50.0096 63.5469 50.7973 63.2534 51.701C62.96 52.6048 62.9602 53.5782 63.2539 54.4819L70.0849 75.5149C71.4349 79.6639 66.6874 83.1109 63.1639 80.5459L45.2719 67.5499C44.5029 66.9908 43.5766 66.6897 42.6259 66.6897C41.6752 66.6897 40.7489 66.9908 39.9799 67.5499L22.0879 80.5459C18.5644 83.1109 13.8169 79.6594 15.1669 75.5149L21.9979 54.4819C22.2917 53.5782 22.2918 52.6048 21.9984 51.701C21.705 50.7973 21.133 50.0096 20.3644 49.4509L2.47242 36.4549C-1.05558 33.8899 0.762418 28.3099 5.11842 28.3099H27.2314C28.1811 28.3101 29.1066 28.0099 29.8753 27.4521C30.644 26.8944 31.2165 26.1078 31.5109 25.2049L38.3464 4.17185V4.17185Z" stroke="#AAAAAA" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                      <svg width="46" height="42" className={`${starRateClick && starRateClick >= 5 ? "star-svg" : ""}`} onClick={() => onClickStarRate(5)} viewBox="0 0 86 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M38.3464 4.17185C39.6964 0.0273516 45.5599 0.0273516 46.9054 4.17185L53.7409 25.2049C54.0351 26.107 54.6069 26.8932 55.3747 27.4508C56.1425 28.0085 57.067 28.3092 58.0159 28.3099H80.1334C84.4939 28.3099 86.3029 33.8899 82.7794 36.4549L64.8874 49.4509C64.1189 50.0096 63.5469 50.7973 63.2534 51.701C62.96 52.6048 62.9602 53.5782 63.2539 54.4819L70.0849 75.5149C71.4349 79.6639 66.6874 83.1109 63.1639 80.5459L45.2719 67.5499C44.5029 66.9908 43.5766 66.6897 42.6259 66.6897C41.6752 66.6897 40.7489 66.9908 39.9799 67.5499L22.0879 80.5459C18.5644 83.1109 13.8169 79.6594 15.1669 75.5149L21.9979 54.4819C22.2917 53.5782 22.2918 52.6048 21.9984 51.701C21.705 50.7973 21.133 50.0096 20.3644 49.4509L2.47242 36.4549C-1.05558 33.8899 0.762418 28.3099 5.11842 28.3099H27.2314C28.1811 28.3101 29.1066 28.0099 29.8753 27.4521C30.644 26.8944 31.2165 26.1078 31.5109 25.2049L38.3464 4.17185V4.17185Z" stroke="#AAAAAA" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                    </p>

                    <textarea className="form-control mb-4" placeholder="Write a review (optional)" rows="2"></textarea>
                    <button className="btn btn-payment w-100 mt-3 mb-5" onClick={onSubmitRate}>Submit</button>
                  </div>


                </div>
              </Modal.Body>
            </Modal>


            {/* Modal success */}
            <Modal className="modalChat" show={showModalSuccess} onHide={handleCloseModalSuccess}>
              <Modal.Body>
                <div className="d-flex justify-content-center py-5">
                  <svg width="126" height="108" viewBox="0 0 176 158" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M68.1989 157.854C105.695 157.854 136.091 127.458 136.091 89.9616C136.091 52.4658 105.695 22.0693 68.1989 22.0693C30.7031 22.0693 0.306641 52.4658 0.306641 89.9616C0.306641 127.458 30.7031 157.854 68.1989 157.854Z" fill="#DFE4FE"/>
                    <path d="M68.9656 142.437L68.1044 140.97C54.9506 118.558 19.996 70.9977 19.6429 70.52L19.1387 69.835L31.0475 58.0657L68.7444 84.3884C92.4794 53.5888 114.623 32.4342 129.066 20.0807C144.867 6.56726 155.152 0.346101 155.256 0.286485L155.489 0.146484H175.69L173.761 1.86495C124.134 46.0673 70.3441 140.015 69.8083 140.959L68.9656 142.437Z" fill="#1036CE"/>
                  </svg>
                </div>

                <h3 className="fw700 fs30 text-center cblue mb-4">
                  <FormattedMessage 
                    id="success.text"
                    defaultMessage="Success!"
                    description="Success!"
                  />
                </h3>
                <p className="fs16 px-2 text-center cblack">
                  <FormattedMessage 
                    id="sucsubreview.text"
                    defaultMessage="You have successfully submitted your review."
                    description="You have successfully submitted your review."
                  />
                </p>
                

                <button className="btn btn-payment w-100 mt-3 mb-5">
                  <FormattedMessage 
                    id="gotohome.text"
                    defaultMessage="Go to Home"
                    description="Go to Home"
                  />
                </button>
              </Modal.Body>
            </Modal>

            {/* Modal chat */}
            <Modal className="modalChat" show={showModal} onHide={handleCloseModal}>
              <Modal.Body>
                <div className="lease-single-owner-div mb-4">
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="ls-owner-img-div me-3">
                      <img src={viewUser && viewUser.avatar ? viewUser.avatar : defaultImage} className="imgenclose" />
                    </div>
                    <div>
                      <p className="fw700 cblack mb-0">{viewUser && viewUser.name}</p>
                      <p className="cgray fs14 mb-0">
                        <FormattedMessage 
                          id="owner.text"
                          defaultMessage="Owner"
                          description="Owner"
                        />
                      </p>
                    </div>
                  </div>
                  <a href="javascript:void(0);" onClick={() => setShowModal(false)}>
                    <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clipRule="evenodd" d="M0.868483 0.868832C1.16853 0.568879 1.57542 0.400374 1.99968 0.400374C2.42395 0.400374 2.83084 0.568879 3.13088 0.868832L9.99968 7.73763L16.8685 0.868832C17.0161 0.716016 17.1926 0.594124 17.3878 0.51027C17.583 0.426416 17.793 0.382278 18.0054 0.380432C18.2179 0.378585 18.4286 0.419068 18.6252 0.499517C18.8218 0.579967 19.0005 0.698772 19.1507 0.849C19.3009 0.999228 19.4197 1.17787 19.5002 1.37451C19.5806 1.57114 19.6211 1.78183 19.6193 1.99427C19.6174 2.20672 19.5733 2.41667 19.4894 2.61188C19.4056 2.80709 19.2837 2.98364 19.1309 3.13123L12.2621 10L19.1309 16.8688C19.4223 17.1706 19.5836 17.5748 19.58 17.9943C19.5763 18.4138 19.408 18.8151 19.1114 19.1117C18.8147 19.4084 18.4134 19.5767 17.9939 19.5803C17.5744 19.584 17.1702 19.4227 16.8685 19.1312L9.99968 12.2624L3.13088 19.1312C2.82912 19.4227 2.42496 19.584 2.00544 19.5803C1.58593 19.5767 1.18463 19.4084 0.887973 19.1117C0.59132 18.8151 0.423049 18.4138 0.419404 17.9943C0.415758 17.5748 0.57703 17.1706 0.868483 16.8688L7.73728 10L0.868483 3.13123C0.568529 2.83119 0.400024 2.42429 0.400024 2.00003C0.400024 1.57577 0.568529 1.16888 0.868483 0.868832Z" fill="#FF5833"/>
                    </svg>
                  </a>
                </div>

                {!chosenChatLoading ? (
                  <textarea className="form-control textarea-chat" placeholder="Write a custom message" rows="5" defaultValue={chosenChatMsg} onChange={(e) => setChosenChatMsg(e.target.value)}></textarea>
                ) : (
                  <div className="loader"></div>
                )}

                <button className="btn w-100 btn-chat-submit" disabled={chosenChatMsg === "" ? true : false} onClick={onSubmitChat}>
                  <FormattedMessage 
                    id="submit.text"
                    defaultMessage="Submit"
                    description="Submit"
                  />
                </button>
              </Modal.Body>
            </Modal>


          </div>
        </div>
      </div>
      <div ref={elementRef}></div>
    </div>
  )
}

export default LessorProfile;

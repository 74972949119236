import React, { useState, useEffect, useContext, useRef } from "react";
import Geocode from "react-geocode";
import { Modal, } from 'react-bootstrap';
import DatePickerMulti from "react-multi-date-picker";
import "react-multi-date-picker/styles/layouts/mobile.css";
import Swal from 'sweetalert2';
import axios from "axios";
import Cookies from 'universal-cookie';
import {FormattedMessage, useIntl} from 'react-intl';
import MapContainer from "./mapcontainer";
import { RouteContext } from "../../contexts/route.context";
import Select from 'react-select';
import MyCalendar from "./calendar/mycalendar";
import MonthlyCalendar from "./monthlycalendar/monthlycalendar";
import DropOffIcon from "../../assets/images/drop-off-icon.svg";
import PickUpIcon from "../../assets/images/pick-up-icon.svg";

const AddPost = () => {
  Geocode.setApiKey("AIzaSyDJCS46EQMFDDPG0MFD34yO651MzTCtpZ4");
  const intl = useIntl();
  let dtTomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  const { getCategories, categories, getSubCategories, subCategories, getArea, area, getCity, city, baseURL, apiHeaders } = useContext(RouteContext);
  const cookies = new Cookies();
  // const [priceVal, setPriceVal] = useState("");
  const [showModalTimeStart, setShowModalTimeStart] = useState(false);
  const [timeStart, setTimeStart] = useState(false);
  const [timeChosenStart, setTimeChosenStart] = useState("");
  const [loader, setLoader] = useState(false);
  let [photoUrl, setPhotoUrl] = useState([]);
  let [listPhoto, setListPhoto] = useState([]);
  const [changeClaimType, setChangeClaimType] = useState("pick_up");
  const [leaseTerm, setLeaseTerm] = useState([
    {
      term: "hourly",
      status: true,
    },
    {
      term: "daily",
      status: true,
    },
    {
      term: "monthly",
      status: true,
    }
  ]);
  const [leaseTermChosen, setLeaseTermChosen] = useState([
    {
      term: "",
      price: "",
      deposit: 0,
      date_avail: [],
      time_in: "",
      time_out: "",
      duration: "",
    },
  ]);
  const [leaseTermLoading, setLeaseTermLoading] = useState(false);
  const [timeChosenLeaseType, setTimeChosenLeaseType] = useState();
  const [timeChosenLeaseIndex, setTimeChosenLeaseIndex] = useState();
  const [mapShow, setMapShow] = useState(false);
  const [numTermShow, setNumTermShow] = useState(false);
  const [addressLatLng, setAddressLatLng] = useState();
  const [monthlyAvailability, setMonthlyAvailability] = useState("unlimited");
  const [addressName, setAddressName] = useState();
  const [subCategoryValue, setSubCategoryValue] = useState();
  const [customQuantity, setCustomQuantity] = useState(true);
  let customChoices = [{en: '1', id: 1, ar: '1'}, {en: '5', id: 5, ar: '5'}, {en: '10', id: 10, ar: '10'}, {en: 'Custom', id: 0, ar: 'مخصص'} ]

  const [addDeposit, setAddDeposit] = useState(!false);
  const [toolTipHidden0, setToolTipHidden0] = useState(!false);
  const [toolTipHidden1, setToolTipHidden1] = useState(!false);
  const [toolTipHidden2, setToolTipHidden2] = useState(!false);

  const [addDates0, setAddDates0] = useState();
  const [addDates1, setAddDates1] = useState();
  const [addDates2, setAddDates2] = useState();
  
  const customStyles = {
    control: base => ({
      ...base,
      height: 46,
      minHeight: 35,
      marginBottom: 30,
    })
  };

  useEffect(() => {
    if(localStorage.getItem("deposit") !== ""){
      setAddDeposit(!addDeposit)
    }
    getCategories();
    getCity();
    
    window.scrollTo(0, 0);
  }, []);

  // depositToggle
  const depositToggle = (index) => {
    setAddDeposit(!addDeposit)
    localStorage.setItem(`depositToggle${index}`, !addDeposit, 600000);
  }

  const setPostDesc = (e) => {
    localStorage.setItem("postDesc", e.target.value, 600000);
  }

  // map pin location
  const onPinMyLocation = () => {
    navigator.geolocation.getCurrentPosition(function(position) {
      setAddressLatLng({
        latVal: position.coords.latitude,
        lngVal: position.coords.longitude,
      })
    });
    setMapShow(false);
  }

  
  useEffect(() => {
    if(addressLatLng) {
      Geocode.fromLatLng(addressLatLng.latVal, addressLatLng.lngVal).then(
        (response) => {
          const address = response.results[0].formatted_address;
          setAddressName(address);
        },
        (error) => {
          console.error(error);
        }
      );
    }

  }, [addressLatLng]);


  const onSetToDropOff = () => {
    setChangeClaimType("drop_off")
  }

  const onSetToPickUp = () => {
    setChangeClaimType("pick_up")
  }


  //lessor post deposit show info
  const showTip = (index) => {
    if(index === 0){
      setToolTipHidden0(!toolTipHidden0);
    }
  }

  //change property title
  const onChangePropertyTitle = (e) => {
    localStorage.setItem('propertyTitle', e.target.value, 600000);
  }


  // time
  const onClickTime = (type, index, lease) => {
    setShowModalTimeStart(true);
    setTimeChosenLeaseType(type);
    setTimeChosenLeaseIndex(index);

    if(type === "time_in") {
      let str = lease.time_in.slice(0, -3);
      setTimeChosenStart(str);

      let splt = lease.time_in.split(" ");
      if(splt[1] === "PM") {
        setTimeStart(true);
      }
      else {
        setTimeStart(false);
      }
    }
    else {
      let str = lease.time_out.slice(0, -3); 
      setTimeChosenStart(str);

      let splt = lease.time_out.split(" ");
      if(splt[1] === "PM") {
        setTimeStart(true);
      }
      else {
        setTimeStart(false);
        
      }
    }
    
  }
  const handleCloseModalStart = () => setShowModalTimeStart(false);
  const onChangeTimeStart = (e) => setTimeStart(e.target.checked);
  const onClickContinueStart = () => {
    let timeSet = timeStart ? "PM" : "AM";
    let timeString = timeChosenStart + " " + timeSet;


    if(timeChosenLeaseType === "time_in") {
      leaseTermChosen[timeChosenLeaseIndex].time_in = timeString;
      setLeaseTermChosen(leaseTermChosen);
    }
    else {
      leaseTermChosen[timeChosenLeaseIndex].time_out = timeString;
      setLeaseTermChosen(leaseTermChosen);
    }
    setShowModalTimeStart(false);
  }

  //camera

  // images
  const onChangeImages = (e) => {
    // let reader = new FileReader();
    // let url = reader.readAsDataURL(e.target.files[0])
    // reader.onloadend = function (e) {
    //   setPhotoUrl([reader.result]);
    // }.bind();
    let listImages = [...photoUrl];
    let listImagesChosen = new Array();
    if(photoUrl.length !== 0) {
      listImages = [...photoUrl];
      listImagesChosen = [...listPhoto];
    }
    const files = e.target.files;
    for (let i=0; i < files.length; i++){
      listImages.push(URL.createObjectURL(files[i]));
      listImagesChosen.push(files[i]);
    }
    setListPhoto(listImagesChosen);
    setPhotoUrl(listImages);
  }


  // add another lease term
  const addAnotherLeaseTerm = (e) => {
    let newTermArr = [...leaseTermChosen];
    if(newTermArr.length > 2){
      setNumTermShow(true);
    }
    else{
      let termObj = {
        term: "",
        price: "",
        deposit: 0,
        date_avail: "",
        time_in: "",
        time_out: "",
        duration: "",
      }
      newTermArr.push(termObj);
      setLeaseTermChosen(newTermArr);
    }
  }

  // remove lease term
  const onClickRemoveLeaseTerm = (index) => {
    setLeaseTermLoading(true);

    // for lease term enable/disable
    if(leaseTermChosen[index].term !== "") {
      if(leaseTermChosen[index].term === "hourly") {
        leaseTerm[0].status = true;
        setLeaseTerm(leaseTerm);
      }
      else if(leaseTermChosen[index].term === "daily") {
        leaseTerm[1].status = true;
        setLeaseTerm(leaseTerm);
      }
      else if(leaseTermChosen[index].term === "monthly") {
        leaseTerm[2].status = true;
        setLeaseTerm(leaseTerm);
      }
    }
    // end for lease term enable/disable

    let newTermArr = [...leaseTermChosen];
    newTermArr.splice(index, 1);
    setLeaseTermChosen(newTermArr);
    localStorage.removeItem(`depositToggle${index}`);
    setTimeout(() => setLeaseTermLoading(false), 800);
    
  }

  

  // price
  const onChangePrice = (e, index) => {
    let val = e.target.value;
    localStorage.setItem("price", e.target.value);
    // leaseTermChosen[index].price = val;
    leaseTermChosen[index].price = val;
    setLeaseTermChosen(leaseTermChosen);
  }

  //quantity 
  const onChangeQuantity = (e, index) => {
    // let val = e.target.value;
    localStorage.setItem("quantity", e.target.value);
    // leaseTermChosen[index].quantity = val;
    // setLeaseTermChosen(leaseTermChosen);
  }

  const onChangeQuantitySelect = (e) => {
    if(e.en === "Custom"){
      setCustomQuantity(false);
      localStorage.removeItem("quantity");
    }
    else(
      localStorage.setItem("quantity", e.id)
    )
  }

  //deposit
  const onChangeDeposit = (e, index) => {
    let val = e.target.value;
    localStorage.setItem("deposit", e.target.value);
    leaseTermChosen[index].deposit = val;
    // leaseTermChosen[index].deposit = localStorage.getItem("deposit");
    setLeaseTermChosen(leaseTermChosen);
  }

  // duration
  const onChangeDuration = (e, index) => {
    let val = e.target.value;
    leaseTermChosen[index].duration = val;
    setLeaseTermChosen(leaseTermChosen);
  }

  // availability
  const onChangeAvailability = (e) => {
    setLeaseTermLoading(true);
    setMonthlyAvailability(e.target.value);
    setTimeout(() => setLeaseTermLoading(false), 800);
  }

  // date monthly
  const onChangeDateMonthly = (e, index) => {
    if(e.constructor === Array) {
      if(e.length <= 1) {
        Swal.fire({
          title: 'Warning',
          text: "Please select end date",
          icon: 'warning',
          confirmButtonColor: '#FF5833',
        });
      }
      else {
        let dtArr = new Array();
        e.map((dt) => {
          let dd = dt ? dt.year + "-" + dt.month.number + "-" + dt.day : "";
          dtArr.push(dd);
        });
        leaseTermChosen[index].date_avail = dtArr;
        setLeaseTermChosen(leaseTermChosen);
      }
    }
    else {
      let dateString = e ? e.year + "-" + e.month.number + "-" + e.day : "";
      let dtArr = new Array();
      dtArr.push(dateString);
      leaseTermChosen[index].date_avail = dtArr;
      setLeaseTermChosen(leaseTermChosen);
    }
  }

    //get dates from new My Calendar
  const getSelectedDates = (data, index, term) => {
    if(index === 0){
      setAddDates0(data);
    }
    else if(index === 1){
      setAddDates1(data);
    }
    else if(index === 2){
      setAddDates2(data);
    }

    if(term === "monthly"){
        let dtArr = new Array();
        dtArr.push(data);
        leaseTermChosen[index].date_avail = dtArr[0];
        setLeaseTermChosen(leaseTermChosen);
    }

    if(term === "hourly") {
          let dtArr = new Array();
          dtArr.push(data);
          leaseTermChosen[index].date_avail = dtArr[0];
          setLeaseTermChosen(leaseTermChosen);
    }
    else{
          let dtArr = new Array();
          dtArr.push(data);
          leaseTermChosen[index].date_avail = dtArr[0];
          setLeaseTermChosen(leaseTermChosen);
    } 
  }

  


  // date daily and hourly
  // const onChangeDateDH = (e, index, term) => {
  //   if(term === "hourly") {
  //     let dtArr = new Array();
  //     e.map((dt) => {
  //       let dd = dt ? dt.year + "-" + dt.month.number + "-" + dt.day : "";
  //         dtArr.push(dd);
  //     });
  //     console.log(dtArr);
  //     leaseTermChosen[index].date_avail = dtArr;
  //     setLeaseTermChosen(leaseTermChosen);
  //   }
  //   else {
  //     //date daily --------------------------------------------------------------------------------------------------
  //     //new way of selecting dates here
  //     let dtArr = new Array();
  //     e.map((dt) => {
  //       let dd = dt ? dt.year + "-" + dt.month.number + "-" + dt.day : "";
  //       dtArr.push(dd);
  //     });

  //     console.log(dtArr);
  //     leaseTermChosen[index].date_avail = dtArr;
  //     setLeaseTermChosen(leaseTermChosen);
  //     //old way of selecting dates here
  //     // let startDaily = e[0].year + "-" + e[0].month.number + "-" + e[0].day;
  //     // let endDaily = e[1].year + "-" + e[1].month.number + "-" + e[1].day;
  //     // let newStartDaily = new Date(startDaily);
  //     // let newEndDaily = new Date(endDaily);
  //     // let datesDaily = getDatesInRange(startDaily, endDaily);
  //     // leaseTermChosen[index].date_avail = datesDaily;
  //     // setLeaseTermChosen(leaseTermChosen);
  //   }
  // }

  // terms
  const onChangeTerm = (e, index) => {
    let val = e.target.value;
    let termArrUnAvail = leaseTermChosen.map((un) => {
      return un.term;
    });

    if(index === 0){
      setAddDates0([]);
    }
    else if(index === 1){
      setAddDates1([]);
    }
    else{
      setAddDates2([]);
    }

    setLeaseTermLoading(true);

    // for lease term enable/disable
    let chosenLt = leaseTerm.filter((e) => e.term === val);
    let oldLt;
    if(leaseTermChosen[index].term !== "") {
      oldLt = leaseTerm.filter((e) => e.term === leaseTermChosen[index].term);
    }
    
    if(chosenLt[0].term === "hourly") {
      leaseTerm[0].status = false;
      setLeaseTerm(leaseTerm);
    }
    else if(chosenLt[0].term === "daily") {
      leaseTerm[1].status = false;
      setLeaseTerm(leaseTerm);
    }
    else if(chosenLt[0].term === "monthly") {
      leaseTerm[2].status = false;
      setLeaseTerm(leaseTerm);
    }

    if(oldLt !== undefined) {
      if(oldLt[0].term === "hourly") {
        leaseTerm[0].status = true;
        setLeaseTerm(leaseTerm);
      }
      else if(oldLt[0].term === "daily") {
        leaseTerm[1].status = true;
        setLeaseTerm(leaseTerm);
      }
      else if(oldLt[0].term === "monthly") {
        leaseTerm[2].status = true;
        setLeaseTerm(leaseTerm);
      }
    }
    // end for lease term enable/disable


    if(termArrUnAvail.includes(val)) {
      Swal.fire({
        title: 'Error',
        text: "Cannot choose the same term. refresh component",
        icon: 'warning',
        confirmButtonColor: '#FF5833',
      }).then((e) => {
        // update lease term chosen
        leaseTermChosen[index].term = "";
        setLeaseTermChosen(leaseTermChosen);
        setLeaseTermLoading(false);
      });
    }
    else {
      // update lease term chosen
      leaseTermChosen[index].term = val;
      setLeaseTermChosen(leaseTermChosen);
      setTimeout(() => setLeaseTermLoading(false), 800);
    }
  }


  const onChangeCity = (value) => {
    getArea(value.id);
  }

  


  const onChangeCategory = (e) => {
    getSubCategories(e.target.value);
  }

  const onClickFieldMap = () => {
    if (subCategoryValue !== undefined) {
      setMapShow(true);
    }
    else {
      Swal.fire({
        title: 'Warning',
        text: "Please choose subcategory first.",
        icon: 'warning',
        confirmButtonColor: '#FF5833',
      });
    }
  }
  

  const onSubmitPost = async (e) => {
      e.preventDefault();
    setLoader(true);
    let deposit_val = localStorage.getItem("deposit");
    const formData = new FormData(e.target);
    let formdataObj = Object.fromEntries(formData.entries());

    let postTermsArr = new Array();
    let availStr = `available`;
    leaseTermChosen.length !== 0 && leaseTermChosen.map((term) => {
      if(deposit_val === null){
        deposit_val = 0;
      }
      let termObj = {
        type: `${availStr}`,
        lease_term: `${term.term}`,
        price: term.price,
        // deposit: term.deposit,
        // price: localStorage.getItem("price"),
        deposit: term.deposit === null || term.deposit === "" ? 0 : term.deposit,
        dates: term.date_avail
      }
      if(term.term === "monthly") {
        // if(monthlyAvailability !== "unlimited") {
          termObj.duration = term.duration;
          // let finalEnd = addMonths(term.date_avail[0], term.duration);
          // termObj.end_date = finalEnd;
          let finalEndDateSplit = term.date_avail[term.date_avail.length - 1].split("-");
          let finalEndDateSplitMonth = finalEndDateSplit[1].split("");
          let finalEndDateSplitDate = finalEndDateSplit[2].split("");
          let finalEndDateFinalMonth = finalEndDateSplitMonth.length === 1 ? "0" + finalEndDateSplitMonth[0] : finalEndDateSplit[1];
          let finalEndDateFinalDate = finalEndDateSplitDate.length === 1 ? "0" + finalEndDateSplitDate[0] : finalEndDateSplit[2];
          let finalEndDate = finalEndDateSplit[0] +"-"+ finalEndDateFinalMonth +"-"+ finalEndDateFinalDate;
          
          termObj.end_date = finalEndDate;
        // }
        // else {
        //   termObj.duration = term.duration;
        //   termObj.end_date = "null";
        // }
      }
      else if(term.term === "hourly") {
        let timeIN = term.time_in;
        let timeOUT = term.time_out;

        const timeInArr = timeIN.split(" ");
        const timeOutArr = timeOUT.split(" ");

        if(timeInArr[1] === "PM") {
          let tInArr = timeInArr[0].split(":");
          let tInStr = parseFloat(tInArr[0]) + parseFloat(12);
          timeIN = tInStr + ":00";
        }
        else {
          timeIN = timeInArr[0];
        }

        if(timeOutArr[1] === "PM") {
          let tOutArr = timeOutArr[0].split(":");
          let tOutStr = parseFloat(tOutArr[0]) + parseFloat(12);
          timeOUT= tOutStr + ":00";
        }
        else {
          timeOUT = timeOutArr[0];
        }


        termObj.time_in = timeIN;
        termObj.time_out = timeOUT;
      }

      postTermsArr.push(termObj);
    });


    let input = {
      images: null,
      title: formdataObj.name,
      quantity:formdataObj.quantity,
      claim_type: changeClaimType,
      // title: localStorage.getItem('propertyTitle'),
      category_id: formdataObj.category,
      subcategory_id: formdataObj.subcategory,
      location: formdataObj.location,
      latitude: addressLatLng.latVal,
      longitude: addressLatLng.lngVal,
      area_id: formdataObj.area,
      city_id: formdataObj.city,
      description: formdataObj.description,
      // description: localStorage.getItem("postDesc"),
      post_lease_terms: postTermsArr,
    };

    // image is mandatory
    if(formdataObj.images.name !== "" && listPhoto.length !== 0) {
      // console.log("really?", listPhoto.length, listPhoto, formdataObj.images.name, photoUrl)
      if(formdataObj.featured_post) {

        // const query = `
        //   mutation($input: PostStoreInput!){
        //     ValidatePost(input:$input){
        //       id
        //       message
        //       status
        //     }
        //   }
        // `;
  
        // const operations = JSON.stringify({ query, variables: { input } });
        // formData.append("operations", operations);
  
        // const map = {
        //     "0": ["variables.input.images"],
        // };
        // formData.append("map", JSON.stringify(map));
  
        // listPhoto.forEach(file=>{
        //   formData.append("0[]", file);
        // });
        
        // let result = await axios({
        //     url: baseURL,
        //     headers: apiHeaders,
        //     method: "post",
        //     data: formData
        // });
  
  
        // console.log(result)
        // if(result.data.data) {
        //   cookies.set('featured_post_id', result.data.data.ValidatePost.id);

        //   // upload image
        //   const formDataImg = new FormData();
        //   let input2 = {
        //     post_id: result.data.data.ValidatePost.id,
        //     images: null,
        //   };

        //   const query = `
        //     mutation($input2: uploadPostImageInput!){
        //       UploadPostImage(input:$input2){
        //         id
        //         message
        //         status
        //       }
        //     }
        //   `;

        //   const operations2 = JSON.stringify({ query, variables: { input2 } });
        //   formDataImg.append("operations", operations2);

        //   const map2 = {
        //       "0": ["variables.input2.images"],
        //   };
        //   formDataImg.append("map", JSON.stringify(map2));

        //   // const images = formdataObj.images ? formdataObj.images : null;
        //   // formDataImg.append("0", images);
        //   listPhoto.forEach(file=>{
        //     console.log(file);
        //     formData.append("0[]", file);
        //   });

        //   let resultImg = await axios({
        //     url: baseURL,
        //     headers: apiHeaders,
        //     method: "post",
        //     data: formDataImg
        //   });

        //   console.log(resultImg);
        //   // end upload image
        //   if(resultImg.data.data) {
        //     window.location.href = "/lessor-payment";
        //   } else {
        //     Swal.fire({
        //       title: 'Adding Post Images Failed',
        //       text: resultImg.data.errors[0].message,
        //       icon: 'warning',
        //       confirmButtonColor: '#FF5833',
        //     }).then((e) => {
        //       // setLoader(false);
        //       window.location.reload();
        //     });
        //   }
        // }
        // else {
        //   Swal.fire({
        //     title: 'Adding Post Failed',
        //     text: result.data.errors[0].message,
        //     icon: 'warning',
        //     confirmButtonColor: '#FF5833',
        //   }).then((e) => {
        //     setLoader(false);
        //     // window.location.reload();
        //   });
        // }
      }
      else {
        const query = `
          mutation($input: PostStoreInput!){
            StorePost(input:$input){
              id
              message
              status
            }
          }
        `;
  
        const operations = JSON.stringify({ query, variables: { input } });
        console.log("Mao ni operations", operations);
        formData.append("operations", operations);
  
        const map = {
            "0": ["variables.input.images"],
        };
        formData.append("map", JSON.stringify(map));

        listPhoto.forEach(file=>{
          formData.append("0[]", file);
        });
  
        let result = await axios({
            url: baseURL,
            headers: apiHeaders,
            method: "post",
            data: formData
        });
  
        if(result.data.data) {

          // upload image
          const formDataImg = new FormData();
          let input2 = {
            post_id: result.data.data.StorePost.id,
            images: null,
          };

          const query = `
            mutation($input2: uploadPostImageInput!){
              UploadPostImage(input:$input2){
                id
                message
                status
              }
            }
          `;

          const operations2 = JSON.stringify({ query, variables: { input2 } });
          formDataImg.append("operations", operations2);

          const map2 = {
              "0": ["variables.input2.images"],
          };
          formDataImg.append("map", JSON.stringify(map2));

          // const images = formdataObj.images ? formdataObj.images : null;
          // formDataImg.append("0", images);
          listPhoto.forEach(file=>{
            formData.append("0[]", file);
          });

          let resultImg = await axios({
            url: baseURL,
            headers: apiHeaders,
            method: "post",
            data: formDataImg
          });
          // end upload image
          if(resultImg.data.data) {
            Swal.fire({
              title: 'Adding Post Complete',
              text: "",
              icon: 'success',
              confirmButtonColor: '#FF5833',
            }).then((e) => {
              setLoader(false);
              localStorage.removeItem("deposit");
              localStorage.removeItem("propertyTitle");
              localStorage.removeItem("depositToggle0");
              localStorage.removeItem("depositToggle1");
              localStorage.removeItem("depositToggle2");
              localStorage.removeItem("price");
              localStorage.removeItem("postDesc");
              localStorage.removeItem("quantity");
              window.location.href = "/my-lessor-profile";
            });
          } else {
            Swal.fire({
              title: 'Adding Post Images Failed',
              text: resultImg.data.errors[0].message,
              icon: 'warning',
              confirmButtonColor: '#FF5833',
            }).then((e) => {
              // setLoader(false);
              window.location.reload();
            });
          }
        }
        else {
          Swal.fire({
            title: 'Adding Post Failed',
            text: result.data.errors[0].message,
            icon: 'warning',
            confirmButtonColor: '#FF5833',
          }).then((e) => {
            setLoader(false);
            // window.location.reload();
          });
        }
      }
      
    }
    else {
      Swal.fire({
        title: 'Adding Post Failed',
        text: "Please add a photo",
        icon: 'warning',
        confirmButtonColor: '#FF5833',
      }).then((e) => {
        setLoader(false);
        localStorage.removeItem("deposit");
        localStorage.removeItem("propertyTitle");
        localStorage.removeItem("depositToggle0");
        localStorage.removeItem("depositToggle1");
        localStorage.removeItem("depositToggle2");
        localStorage.removeItem("price");
        localStorage.removeItem("postDesc");
        localStorage.removeItem("quantity");
        // window.location.reload();
      });
    }
    
  }

  const onClickDeleteImage = (img, index) => {
    console.log(listPhoto)
    let listImages = [...photoUrl];
    let x = listImages.filter((pic)=>{
      return pic !== img;
    });
    let listImagesChosen = new Array();
    listImagesChosen = [...listPhoto]

    let y = listImagesChosen.slice(index+1)
    console.log(y)
    setListPhoto(y);
    setPhotoUrl(x);

    // console.log("y, x", y, x)
    // let listImagesChosen = new Array();
    // if(photoUrl.length !== 0) {
    //   listImages = [...photoUrl];
    //   listImagesChosen = [...listPhoto];
    // }
    // for (let i=0; i < files.length; i++){
    //   listImages.push(URL.createObjectURL(files[i]));
    //   listImagesChosen.push(files[i]);
    // }
    // setListPhoto(listImagesChosen);
    // setPhotoUrl(listImages);
  }


  return (
    <div>
      <div className="container-fluid leases-single-div py-5">
        <div className="container main-wrap">
          <form className="row" onSubmit={onSubmitPost}>
            <div className="col-12">
              <h1 className="fw700 cblack2 mb-4">
                <FormattedMessage 
                  id="addpost.text"
                  defaultMessage="Add a post"
                  description="Add a post"
                />
              </h1>
            </div>
            <div className="col-12 col-lg-4">
              <input type="text" name="name" onChange={(e)=>onChangePropertyTitle(e)} className="form-control editpostForm" placeholder={intl.formatMessage({
                id: 'productname.text',
                defaultMessage: "Product Name",
                description: "Product Name",
              })}
              defaultValue={localStorage.getItem('propertyTitle')}
               required />
            </div>
            <div className="col-12 col-lg-4">
              <select className="form-select editpostForm" name="category" onChange={onChangeCategory} required>
                <option value="">
                  {intl.formatMessage({
                    id: 'category.text',
                    defaultMessage: 'Category',
                    description: 'Category',
                  })}
                </option>
                {categories && categories.map((cat, index) => (
                  <option key={index} value={cat.id}>{intl.locale === "en" ? cat.en : cat.ar}</option>
                ))}
              </select>
            </div>
            <div className="col-12 col-lg-4">
              <select className="form-select editpostForm" name="subcategory" onChange={(e) => {
                console.log(e.target.value)
                setSubCategoryValue(e.target.value)}} required>
                <option value="">
                  {intl.formatMessage({
                    id: 'subcategory.text',
                    defaultMessage: 'Subcategory',
                    description: 'Subcategory',
                  })}
                </option>
                {subCategories && subCategories.map((subcat, index) => (
                  <option key={index} value={subcat.id}>{intl.locale === "en" ? subcat.en : subcat.ar}</option>
                ))}
              </select>
            </div>
            <div className="col-12 col-lg-6">
              {/* <input type="text" className="form-control editpostForm" placeholder="Location" name="location" 
                onClick={() => setMapShow(true)} 
              required/> */}

              <textarea className="form-control editpostForm" name="location" defaultValue={addressName ? addressName : ""} placeholder={intl.formatMessage({
                id: 'location.text',
                defaultMessage: "Location",
                description: "Location",
              })} rows={2} onClick={onClickFieldMap} required></textarea>
            </div>

            <div className="col-12 col-lg-6">
              {/* <select className="form-select editpostForm" name="city" required onChange={onChangeCity}>
                <option value={""}>
                  {intl.formatMessage({
                    id: 'city.text',
                    defaultMessage: 'City',
                    description: 'City',
                  })}
                </option>
                {city && city.map((cit, index) => (
                  <option key={index} value={cit.id}>{intl.locale === "en" ? cit.en : cit.ar}</option>
                ))}
              </select> */}

              {/* Select City */}

              <Select
                styles={customStyles}
                className="basic-single"
                classNamePrefix="select"
                placeholder="Select City"
                isSearchable
                name="city"
                options={city}
                onChange={(choice=>onChangeCity(choice))}
                getOptionValue={(option) => `${option['id']}`}
                getOptionLabel={option =>`${intl.locale === "en" ? option['en']:option['ar']}`}
              />

            </div>

            {/* Select Area */}
            <div className="col-12 col-lg-4">
            <Select
                styles={customStyles}
                className="basic-single"
                classNamePrefix="select"
                placeholder="Select Area"
                isSearchable
                name="area"
                options={area}
                // onChange={(choice=>onChangeCity(choice))}
                getOptionValue={(option) => `${option['id']}`}
                getOptionLabel={option =>`${intl.locale === "en" ? option['en']:option['ar']}`}
              />
              {/* <select className="form-select editpostForm" name="area" required>
                <option value="">
                  {intl.formatMessage({
                    id: 'area.text',
                    defaultMessage: 'Area',
                    description: 'Area',
                  })}
                </option>
                {area && area.map((ar, index) => (
                  <option key={index} value={ar.id}>{intl.locale === "en" ? ar.en : ar.ar}</option>
                ))}
              </select> */}
            </div>

            {
              customQuantity === false
              ?
              <div className="col-12 col-lg-4 position-relative">
                <input type="text" name="quantity" className="form-control editpostForm text-center"
                  onChange={(e) => onChangeQuantity(e)}  
                  placeholder={intl.formatMessage({
                    id: 'quantity.text',
                    defaultMessage: "Quantity",
                    description: "Quantity",
                  })} 
                  defaultValue={localStorage.getItem('quantity')} 
                  required
                  />
              </div>
              :
              <div className="col-12 col-lg-4 position-relative">
                {console.log(city)}
                <Select
                  styles={customStyles}
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Quantity"
                  isSearchable
                  name="quantity"
                  options={customChoices}
                  onChange={(choice=>onChangeQuantitySelect(choice))}
                  getOptionValue={(option) => `${option['id']}`}
                  getOptionLabel={option =>`${intl.locale === "en" ? option['en']:option['ar']}`}
                />
              </div>
            }
            

            <div className="d-flex col-12 col-lg-4 position-relative">
              <div className={`form-control ${changeClaimType === "pick_up" ? "claimBG" : ""} claimContainer mx-1`} onClick={onSetToPickUp}>
                <div className="mx-2">
                  {intl.formatMessage({
                    id: 'pickup.text',
                    defaultMessage: 'Pick Up',
                    description: 'Pick Up',
                  })}
                </div>
                <img className={"claimIcon"} src={PickUpIcon}/>
              </div>
              <div className={`form-control ${changeClaimType === "drop_off" ? "claimBG" : ""}  claimContainer mx-1`} onClick={onSetToDropOff}>
                <div className="mx-2">
                  {intl.formatMessage({
                    id: 'dropoff.text',
                    defaultMessage: 'Drop Off',
                    description: 'Drop Off',
                  })}
                </div>
                <img className={"claimIcon"} src={DropOffIcon}/>
              </div>
            </div>
            
            
            { !leaseTermLoading ? 
              leaseTermChosen.map((chosen, index) => (
              <div key={index} className="row">
                <div className="col-12 position-relative">
                  <div className="div-2px blue mb-4 mt-4"/>
                  {index > 0 && (
                    <svg onClick={() => onClickRemoveLeaseTerm(index)} width="18" height="18" style={{cursor:"pointer", position:"absolute", top:"35px", right:"0"}} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="18" height="18" rx="9" fill="#FF0000"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.86348 3.86363C4.03226 3.6949 4.26114 3.60012 4.49978 3.60012C4.73843 3.60012 4.96731 3.6949 5.13608 3.86363L8.99978 7.72733L12.8635 3.86363C12.9465 3.77767 13.0458 3.7091 13.1556 3.66194C13.2654 3.61477 13.3835 3.58994 13.503 3.5889C13.6225 3.58786 13.741 3.61063 13.8516 3.65589C13.9622 3.70114 14.0627 3.76797 14.1472 3.85247C14.2317 3.93697 14.2986 4.03746 14.3438 4.14807C14.3891 4.25867 14.4118 4.37719 14.4108 4.49669C14.4098 4.61619 14.3849 4.73429 14.3378 4.84409C14.2906 4.95389 14.222 5.0532 14.1361 5.13623L10.2724 8.99993L14.1361 12.8636C14.3 13.0334 14.3907 13.2607 14.3887 13.4967C14.3866 13.7327 14.292 13.9584 14.1251 14.1253C13.9583 14.2921 13.7325 14.3868 13.4965 14.3888C13.2606 14.3909 13.0332 14.3002 12.8635 14.1362L8.99978 10.2725L5.13608 14.1362C4.96634 14.3002 4.739 14.3909 4.50302 14.3888C4.26705 14.3868 4.04131 14.2921 3.87445 14.1253C3.70758 13.9584 3.61293 13.7327 3.61088 13.4967C3.60883 13.2607 3.69954 13.0334 3.86348 12.8636L7.72718 8.99993L3.86348 5.13623C3.69476 4.96745 3.59998 4.73857 3.59998 4.49993C3.59998 4.26128 3.69476 4.0324 3.86348 3.86363Z" fill="white"/>
                    </svg>
                  )}
                </div>

                <div className="col-12 col-lg-6">
                  <input type="text" name="price" className="form-control editpostForm text-center"
                  onChange={(e) => onChangePrice(e, index)}  
                  placeholder={intl.formatMessage({
                    id: 'price.text',
                    defaultMessage: "Price",
                    description: "Price",
                  })} 
                  defaultValue={chosen.price} 
                  required
                  />
                </div>

                <div className="col-12 col-lg-6">
                  <select className="form-select editpostForm" defaultValue={chosen.term} onChange={(e) => onChangeTerm(e, index)}>
                    <option value="">
                      {intl.formatMessage({
                        id: 'leaseterm.text',
                        defaultMessage: 'Lease Term',
                        description: 'Lease Term',
                      })}
                    </option>
                    {leaseTerm.map((term, index) => {
                      if(term.status) {
                        return (
                          <option key={index} value={term.term}>{term.term}</option>
                        )
                      }
                      else {
                        return (
                          <option key={index} value={term.term} disabled>{term.term}</option>
                        )
                      }
                    })}
                  </select>
                </div>

                {/* 
                  Add Deposit Code Starts Here ---------------------------
                */}
                <div className="form-check form-switch ps-3 mb-4 d-flex flex-column select-time-toggle">
                  <div className="d-flex">
                    <p className="fw600 fs16 cblack2 mb-2 me-3">
                      <FormattedMessage 
                        id="adddeposit.text"
                        defaultMessage="Add Deposit"
                        description="Add Deposit"
                      />
                    </p>
                    <input className={`form-check-input`} onChange={()=>depositToggle(index)} type="checkbox" checked={localStorage.getItem(`depositToggle${index}`) === "true" ? "checked" : ""} name="add_deposit_toggle"/>
                    <div className={`${index !== 0 ? "hidden": null}`}>
                      <svg onMouseEnter={()=>showTip(index)} onMouseLeave={()=>showTip(index)} style={{cursor: "pointer", marginRight:"10px", color: "#808080", position:"relative"}} xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                        <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
                      </svg>
                      <div className={`${toolTipHidden0 ? "hidden": null} ${intl.locale === "en" ? "deposit-info-toggle":"deposit-info-toggle-ar"}`}>
                            <FormattedMessage 
                              id="depositinsurance.text"
                              defaultMessage="A deposit serves as insurance for the lessor by providing a financial buffer against potential damages, or breaches of the lease agreement by the tenant. It offers reassurance that if the tenant fails to fulfill their obligations, the lessor has a means to cover costs and mitigate losses, ensuring the item's upkeep and financial security."
                              description="A deposit serves as insurance for the lessor by providing a financial buffer against potential damages, or breaches of the lease agreement by the tenant. It offers reassurance that if the tenant fails to fulfill their obligations, the lessor has a means to cover costs and mitigate losses, ensuring the item's upkeep and financial security."
                            />
                      </div>
                    </div>
                  </div>
                  {
                  localStorage.getItem(`depositToggle${index}`) === "true" ? 
                  <div className="col-12 col-lg-4">
                    <input type="text" name="deposit" className="form-control editpostForm text-center" placeholder={intl.formatMessage({
                      id: 'deposit.text',
                      defaultMessage: "Deposit",
                      description: "Deposit",
                    })} defaultValue={chosen.deposit} onChange={(e)=>onChangeDeposit(e, index)}/>

                  </div> 
                  :
                  null
                  }
                </div>

                
                <div className="col-12 col-lg-6">
                  {chosen.term === "monthly" ?
                    // monthlyAvailability === "unlimited" ? (
                    //   <MonthlyCalendar
                    //   sendSelectedDates={getSelectedDates}
                    //   index = {index}
                    //   term = {"monthly"}
                    //   postDates0={addDates0}
                    //   postDates1={addDates1}
                    //   postDates2={addDates2}
                    // />
                      // <DatePickerMulti
                      //   value={chosen.date_avail[0]} 
                      //   minDate={dtTomorrow}
                      //   onChange={(e) => onChangeDateMonthly(e, index)}
                      //   className="rmdp-mobile"
                      //   style={{
                      //     color: "#212529",
                      //     fontSize: "14px",
                      //     background: "transparent",
                      //     border: "1px solid #C9C9C9",
                      //     height: "46px",
                      //     marginBottom: "46px",
                      //     display: "block",
                      //     width: "100%",
                      //     padding: ".375rem .75rem",
                      //   }}
                      // />
                    // ) : (
                      <MonthlyCalendar
                      sendSelectedDates={getSelectedDates}
                      index = {index}
                      term = {"monthly"}
                      postDates0={addDates0}
                      postDates1={addDates1}
                      postDates2={addDates2}
                    />
                      // <DatePickerMulti
                      //   range
                      //   value={chosen.date_avail[0]} 
                      //   minDate={dtTomorrow}
                      //   onChange={(e) => onChangeDateMonthly(e, index)}
                      //   className="rmdp-mobile"
                      //   style={{
                      //     color: "#C9C9C9",
                      //     fontSize: "14px",
                      //     background: "transparent",
                      //     border: "1px solid #C9C9C9",
                      //     height: "46px",
                      //     marginBottom: "46px",
                      //     display: "block",
                      //     width: "100%",
                      //     padding: ".375rem .75rem",
                      //   }}
                      // />
                    // )
                  : chosen.term === "daily" ? (
                    <MyCalendar
                      sendSelectedDates={getSelectedDates}
                      index = {index}
                      term = {"daily"}
                      postDates0={addDates0}
                      postDates1={addDates1}
                      postDates2={addDates2}
                    />
                    // <DatePickerMulti
                    //   multiple
                    //   value={chosen.date_avail} 
                    //   minDate={dtTomorrow}
                    //   onChange={(e) => onChangeDateDH(e, index, "daily")}
                    //   className="rmdp-mobile"
                    //   style={{
                    //     color: "#C9C9C9",
                    //     fontSize: "14px",
                    //     background: "transparent",
                    //     border: "1px solid #C9C9C9",
                    //     height: "46px",
                    //     marginBottom: "46px",
                    //     display: "block",
                    //     width: "100%",
                    //     padding: ".375rem .75rem",
                    //   }}
                    // />
                  ) : (
                    <MyCalendar
                      sendSelectedDates={getSelectedDates}
                      index = {index}
                      term = {"hourly"}
                      postDates0={addDates0}
                      postDates1={addDates1}
                      postDates2={addDates2}
                    />
                    // <DatePickerMulti
                    //   multiple
                    //   value={chosen.date_avail}
                    //   minDate={dtTomorrow}  
                    //   onChange={(e) => onChangeDateDH(e, index, "hourly")}
                    //   className="rmdp-mobile"
                    //   style={{
                    //     color: "#C9C9C9",
                    //     fontSize: "14px",
                    //     background: "transparent",
                    //     border: "1px solid #C9C9C9",
                    //     height: "46px",
                    //     marginBottom: "46px",
                    //     display: "block",
                    //     width: "100%",
                    //     padding: ".375rem .75rem",
                    //   }}
                    // />
                  )}
                </div>
                {/* prev placement: at the top of the datepickers */}
                {/* {chosen.term === "monthly" && (
                  <div className="col-12 col-lg-6 d-flex mt-3">
                    <select className="form-select editpostForm" defaultValue={monthlyAvailability} onChange={onChangeAvailability}>
                      <option value="">
                        {intl.formatMessage({
                          id: 'typeavail.text',
                          defaultMessage: 'Type of Availability',
                          description: 'Type of Availability',
                        })}
                      </option>
                      <option value="unlimited">
                        {intl.formatMessage({
                          id: 'unlimited.text',
                          defaultMessage: 'Unlimited',
                          description: 'Unlimited',
                        })}
                      </option>
                      <option value="limited">
                        {intl.formatMessage({
                          id: 'limited.text',
                          defaultMessage: 'Limited',
                          description: 'Limited',
                        })}
                      </option>
                    </select>
                  </div>
                )} */}

                <div className="col-12 col-lg-6">
                  {chosen.term === "monthly" ? (
                    <>
                    <p className="fw600 fs16 cblack2 mb-2">
                      <svg width="18" height="18" style={{marginRight:"8px", marginTop:"-3px"}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24ZM13.5 6C13.5 5.60218 13.342 5.22064 13.0607 4.93934C12.7794 4.65804 12.3978 4.5 12 4.5C11.6022 4.5 11.2206 4.65804 10.9393 4.93934C10.658 5.22064 10.5 5.60218 10.5 6V12C10.5001 12.3978 10.6582 12.7793 10.9395 13.0605L15.1815 17.304C15.3209 17.4434 15.4863 17.5539 15.6684 17.6293C15.8505 17.7048 16.0457 17.7436 16.2428 17.7436C16.4398 17.7436 16.635 17.7048 16.8171 17.6293C16.9992 17.5539 17.1646 17.4434 17.304 17.304C17.4434 17.1646 17.5539 16.9992 17.6293 16.8171C17.7048 16.635 17.7436 16.4398 17.7436 16.2428C17.7436 16.0457 17.7048 15.8505 17.6293 15.6684C17.5539 15.4863 17.4434 15.3209 17.304 15.1815L13.5 11.379V6Z" fill="#444444"/>
                      </svg>
                      <FormattedMessage 
                        id="duration.text"
                        defaultMessage="Duration"
                        description="Duration"
                      />
                    </p>
                    <select className="form-select editpostForm" onChange={(e) => onChangeDuration(e, index)} defaultValue={chosen.duration} required>
                      <option value="">
                        {intl.formatMessage({
                          id: 'selectduration.text',
                          defaultMessage: 'Select duration',
                          description: 'Select duration',
                        })}
                      </option>
                      <option value="1">1 month</option>
                      <option value="2">2 months</option>
                      <option value="3">3 months</option>
                      <option value="4">4 months</option>
                      <option value="5">5 months</option>
                      <option value="6">6 months</option>
                      <option value="7">7 months</option>
                      <option value="8">8 months</option>
                      <option value="9">9 months</option>
                      <option value="10">10 months</option>
                      <option value="11">11 months</option>
                      <option value="12">12 months</option>
                    </select>
                    </>
                  ) : chosen.term === "hourly" ? (
                    <div className="row">
                      <div className="col-12 col-lg-6">
                        <p className="fw600 fs16 cblack2 mb-2">
                          <svg width="18" height="18" style={{marginRight:"8px", marginTop:"-3px"}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24ZM13.5 6C13.5 5.60218 13.342 5.22064 13.0607 4.93934C12.7794 4.65804 12.3978 4.5 12 4.5C11.6022 4.5 11.2206 4.65804 10.9393 4.93934C10.658 5.22064 10.5 5.60218 10.5 6V12C10.5001 12.3978 10.6582 12.7793 10.9395 13.0605L15.1815 17.304C15.3209 17.4434 15.4863 17.5539 15.6684 17.6293C15.8505 17.7048 16.0457 17.7436 16.2428 17.7436C16.4398 17.7436 16.635 17.7048 16.8171 17.6293C16.9992 17.5539 17.1646 17.4434 17.304 17.304C17.4434 17.1646 17.5539 16.9992 17.6293 16.8171C17.7048 16.635 17.7436 16.4398 17.7436 16.2428C17.7436 16.0457 17.7048 15.8505 17.6293 15.6684C17.5539 15.4863 17.4434 15.3209 17.304 15.1815L13.5 11.379V6Z" fill="#444444"/>
                          </svg>
                          <FormattedMessage 
                            id="from.text"
                            defaultMessage="From"
                            description="From"
                          />
                        </p>
                        <input type="text" className="form-control editpostForm w-75" placeholder={intl.formatMessage({
                          id: 'enterhere.text',
                          defaultMessage: "Enter here",
                          description: "Enter here",
                        })} onClick={() => onClickTime("time_in", index, chosen)} value={chosen.time_in} />
                      </div>
                      <div className="col-12 col-lg-6">
                        <p className="fw600 fs16 cblack2 mb-2">
                          <svg width="18" height="18" style={{marginRight:"8px", marginTop:"-3px"}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24ZM13.5 6C13.5 5.60218 13.342 5.22064 13.0607 4.93934C12.7794 4.65804 12.3978 4.5 12 4.5C11.6022 4.5 11.2206 4.65804 10.9393 4.93934C10.658 5.22064 10.5 5.60218 10.5 6V12C10.5001 12.3978 10.6582 12.7793 10.9395 13.0605L15.1815 17.304C15.3209 17.4434 15.4863 17.5539 15.6684 17.6293C15.8505 17.7048 16.0457 17.7436 16.2428 17.7436C16.4398 17.7436 16.635 17.7048 16.8171 17.6293C16.9992 17.5539 17.1646 17.4434 17.304 17.304C17.4434 17.1646 17.5539 16.9992 17.6293 16.8171C17.7048 16.635 17.7436 16.4398 17.7436 16.2428C17.7436 16.0457 17.7048 15.8505 17.6293 15.6684C17.5539 15.4863 17.4434 15.3209 17.304 15.1815L13.5 11.379V6Z" fill="#444444"/>
                          </svg>
                          <FormattedMessage 
                            id="to.text"
                            defaultMessage="To"
                            description="To"
                          />
                        </p>
                        <input type="text" className="form-control editpostForm w-75" placeholder={intl.formatMessage({
                          id: 'enterhere.text',
                          defaultMessage: "Enter here",
                          description: "Enter here",
                        })} onClick={() => onClickTime("time_out", index, chosen)} value={chosen.time_out} />
                      </div>
                    </div>
                  ) : ""}
                </div>
              </div>
              ))
            : (
              <div className="loader"></div>
            )} 
            <div className="col-12 mt-2">
              <p className="fw600 fs16 cblue cur-pointer" onClick={addAnotherLeaseTerm}>
                <svg width="15" height="15" style={{marginRight:"10px"}} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill="#1036CE" fillRule="evenodd" clipRule="evenodd" d="M8.99998 0.599976C9.31823 0.599976 9.62346 0.726404 9.8485 0.951447C10.0735 1.17649 10.2 1.48172 10.2 1.79998V7.79998H16.2C16.5182 7.79998 16.8235 7.9264 17.0485 8.15145C17.2735 8.37649 17.4 8.68172 17.4 8.99998C17.4 9.31823 17.2735 9.62346 17.0485 9.8485C16.8235 10.0735 16.5182 10.2 16.2 10.2H10.2V16.2C10.2 16.5182 10.0735 16.8235 9.8485 17.0485C9.62346 17.2735 9.31823 17.4 8.99998 17.4C8.68172 17.4 8.37649 17.2735 8.15145 17.0485C7.9264 16.8235 7.79998 16.5182 7.79998 16.2V10.2H1.79998C1.48172 10.2 1.17649 10.0735 0.951447 9.8485C0.726404 9.62346 0.599976 9.31823 0.599976 8.99998C0.599976 8.68172 0.726404 8.37649 0.951447 8.15145C1.17649 7.9264 1.48172 7.79998 1.79998 7.79998H7.79998V1.79998C7.79998 1.48172 7.9264 1.17649 8.15145 0.951447C8.37649 0.726404 8.68172 0.599976 8.99998 0.599976Z"/>
                </svg>
                <FormattedMessage 
                  id="addleaseterm.text"
                  defaultMessage="Add Another Lease Term"
                  description="Add Another Lease Term"
                />
              </p>
            </div>


            <div className="col-12">
              <p className="fw600 fs16 cgray3 mb-4">
                <FormattedMessage 
                  id="createdescpost.text"
                  defaultMessage="Create Description on your post"
                  description="Create Description on your post"
                />
              </p>
              <textarea onChange={(e)=>setPostDesc(e)}className="form-control editpostFormTextarea" rows="2" name="description"
                placeholder={intl.formatMessage({
                  id: 'productdescription.text',
                  defaultMessage: "Product Description",
                  description: "Product Description",
                })}
                ></textarea>
            </div>


            <div className="col-12">
              <p className="fw600 fs16 cgray3 mb-4">
                <FormattedMessage 
                  id="uploadphotos.text"
                  defaultMessage="Upload Photos"
                  description="Upload Photos"
                />
              </p>
              <div className="upload-img-post-div">
              {
              photoUrl?.length !== 0 ? photoUrl?.map((img, index) => (
                  <div key={index} className="position-relative upload-col">
                    <div className="per-upload-img">
                      <img src={img} className="imgenclose" />
                    </div>
                    <svg onClick={() => onClickDeleteImage(img, index)} className="delete-icon-img" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="18" height="18" rx="9" fill="#FF0000"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M3.86348 3.86363C4.03226 3.6949 4.26114 3.60012 4.49978 3.60012C4.73843 3.60012 4.96731 3.6949 5.13608 3.86363L8.99978 7.72733L12.8635 3.86363C12.9465 3.77767 13.0458 3.7091 13.1556 3.66194C13.2654 3.61477 13.3835 3.58994 13.503 3.5889C13.6225 3.58786 13.741 3.61063 13.8516 3.65589C13.9622 3.70114 14.0627 3.76797 14.1472 3.85247C14.2317 3.93697 14.2986 4.03746 14.3438 4.14807C14.3891 4.25867 14.4118 4.37719 14.4108 4.49669C14.4098 4.61619 14.3849 4.73429 14.3378 4.84409C14.2906 4.95389 14.222 5.0532 14.1361 5.13623L10.2724 8.99993L14.1361 12.8636C14.3 13.0334 14.3907 13.2607 14.3887 13.4967C14.3866 13.7327 14.292 13.9584 14.1251 14.1253C13.9583 14.2921 13.7325 14.3868 13.4965 14.3888C13.2606 14.3909 13.0332 14.3002 12.8635 14.1362L8.99978 10.2725L5.13608 14.1362C4.96634 14.3002 4.739 14.3909 4.50302 14.3888C4.26705 14.3868 4.04131 14.2921 3.87445 14.1253C3.70758 13.9584 3.61293 13.7327 3.61088 13.4967C3.60883 13.2607 3.69954 13.0334 3.86348 12.8636L7.72718 8.99993L3.86348 5.13623C3.69476 4.96745 3.59998 4.73857 3.59998 4.49993C3.59998 4.26128 3.69476 4.0324 3.86348 3.86363Z" fill="white"/>
                    </svg>
                  </div>
                ))
                : null}
                {/* {photoUrl.length !== 0 ? 
                  photoUrl.map((e, index) => (
                    <div key={index} className="per-upload-img upload-col">
                      <img src={e} className="imgenclose" />
                    </div>
                  ))
                : null} */}
                
                <div className="upload-col">
                  <input type="file" name="images" accept="image/gif, image/jpeg, image/png, image/jpg" id="images-btn" onChange={onChangeImages} multiple hidden/>
                  <label htmlFor="images-btn" className="btn btn-upload-images">
                    <div>
                      <svg width="36" height="37" viewBox="0 0 43 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M21.4999 0.650024C22.3088 0.650024 23.0846 0.971363 23.6566 1.54335C24.2286 2.11533 24.5499 2.89111 24.5499 3.70002V18.95H39.7999C40.6088 18.95 41.3846 19.2714 41.9566 19.8433C42.5286 20.4153 42.8499 21.1911 42.8499 22C42.8499 22.8089 42.5286 23.5847 41.9566 24.1567C41.3846 24.7287 40.6088 25.05 39.7999 25.05H24.5499V40.3C24.5499 41.1089 24.2286 41.8847 23.6566 42.4567C23.0846 43.0287 22.3088 43.35 21.4999 43.35C20.691 43.35 19.9152 43.0287 19.3432 42.4567C18.7712 41.8847 18.4499 41.1089 18.4499 40.3V25.05H3.1999C2.39099 25.05 1.61521 24.7287 1.04323 24.1567C0.471241 23.5847 0.149902 22.8089 0.149902 22C0.149902 21.1911 0.471241 20.4153 1.04323 19.8433C1.61521 19.2714 2.39099 18.95 3.1999 18.95H18.4499V3.70002C18.4499 2.89111 18.7712 2.11533 19.3432 1.54335C19.9152 0.971363 20.691 0.650024 21.4999 0.650024Z" fill="#FF5833"/>
                      </svg>
                      <p className="fs16 cred mb-0 mt-3">
                        <FormattedMessage 
                          id="addphoto.text"
                          defaultMessage="Add Photo"
                          description="Add Photo"
                        />
                      </p>
                    </div>
                  </label>
                </div>
                {/* <div onClick={onChangeImagesCamera}>
                  Camera
                </div> */}
              </div>


              {/* Featured Post toggle */}
              {/* <div className="form-check form-switch ps-0 d-flex select-time-toggle mb-4">
                <p className="fw600 fs16 cblack2 mb-2 me-5">
                  <FormattedMessage 
                    id="featurepost.text"
                    defaultMessage="Feature your Post"
                    description="Feature your Post"
                  />
                </p>
                <input className="form-check-input " type="checkbox" name="featured_post" />
              </div> */}


              {loader ? (
                <div className="loader ms-0"></div>
              ) : (
                <button type="submit" className="btn btn-save-post">
                  <FormattedMessage 
                    id="save.text"
                    defaultMessage="Save"
                    description="Save"
                  />
                </button>
              )}
            </div>

          
          
          </form>
        </div>
      </div>


      {/* Modal start time */}
      <Modal className="modalChat" show={showModalTimeStart} onHide={handleCloseModalStart}>
        <Modal.Body>
          <p className="fw700 cblue fs16">
            <FormattedMessage 
              id="selecttime.text"
              defaultMessage="Select a Time"
              description="Select a Time"
            />
          </p>
          <div className="form-check form-switch select-time-toggle">
            <p className={`label1 fw700 fs16 ${timeStart ? 'cgray' : 'cblue'}`}>AM</p>
            <input className="form-check-input" type="checkbox" id="toggleTimeStart" onChange={onChangeTimeStart} checked={timeStart} />
            <label className={`form-check-label fw700 fs16 ${timeStart ? 'cred' : 'cgray'}`} htmlFor="toggleTimeStart">PM</label>
          </div>


          <div className="time-list-wrapper">
            <div className="time-list-per-div">
              <span className={`${timeChosenStart === "12:00" ? "active" : ""}`} onClick={() => setTimeChosenStart("12:00")}>12:00</span>
              <span className={`${timeChosenStart === "1:00" ? "active" : ""}`} onClick={() => setTimeChosenStart("1:00")}>1:00</span>
            </div>
            <div className="time-list-per-div">
              <span className={`${timeChosenStart === "2:00" ? "active" : ""}`} onClick={() => setTimeChosenStart("2:00")}>2:00</span>
              <span className={`${timeChosenStart === "3:00" ? "active" : ""}`} onClick={() => setTimeChosenStart("3:00")}>3:00</span>
            </div>
            <div className="time-list-per-div">
              <span className={`${timeChosenStart === "4:00" ? "active" : ""}`} onClick={() => setTimeChosenStart("4:00")}>4:00</span>
              <span className={`${timeChosenStart === "5:00" ? "active" : ""}`} onClick={() => setTimeChosenStart("5:00")}>5:00</span>
            </div>
            <div className="time-list-per-div">
              <span className={`${timeChosenStart === "6:00" ? "active" : ""}`} onClick={() => setTimeChosenStart("6:00")}>6:00</span>
              <span className={`${timeChosenStart === "7:00" ? "active" : ""}`} onClick={() => setTimeChosenStart("7:00")}>7:00</span>
            </div>
            <div className="time-list-per-div">
              <span className={`${timeChosenStart === "8:00" ? "active" : ""}`} onClick={() => setTimeChosenStart("8:00")}>8:00</span>
              <span className={`${timeChosenStart === "9:00" ? "active" : ""}`} onClick={() => setTimeChosenStart("9:00")}>9:00</span>
            </div>
            <div className="time-list-per-div">
              <span className={`${timeChosenStart === "10:00" ? "active" : ""}`} onClick={() => setTimeChosenStart("10:00")}>10:00</span>
              <span className={`${timeChosenStart === "11:00" ? "active" : ""}`} onClick={() => setTimeChosenStart("11:00")}>11:00</span>
            </div>
          </div>

          <button className="btn btn-orang-def w-100 btn-continue-time mt-3" onClick={onClickContinueStart}>Continue</button>
        </Modal.Body>
      </Modal>


      {/* map modal */}
      <Modal className="modal-map-wrapper" show={mapShow} onHide={() => setMapShow(false)}>
        <Modal.Body className="modal-postshipment-div modal-map">
          
          <div className="map-modal-div">
            <MapContainer setAddressLatLng={setAddressLatLng}/>
          </div>

          <div style={{marginTop:"30px"}}>
            <button className="btn btn-sm btn-secondary" onClick={onPinMyLocation}>Pin My Location</button>
          </div>

          <div style={{marginTop:"50px", marginBottom:"50px"}}>
            <p>
              Drag pin to your desired location
            </p>
            <button className="btn btn-primary" onClick={() => setMapShow(false)}>Pick Location</button>
          </div>
        </Modal.Body>
      </Modal>

      {/* More than 3 terms */}

      <Modal className="modal-lease-term-wrapper" show={numTermShow} onHide={() => setNumTermShow(false)}>
        <Modal.Body className="modal-postshipment-div modal-map">
          {/* <div style={{marginTop:"10px"}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 24 24" fill="none" stroke="#fb2626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
          </div> */}
          <div style={{marginTop:"25px", fontSize:"18px"}}>
            <FormattedMessage 
              id="maximumleaseterm.text"
              defaultMessage="You have exceeded the maximum number of lease terms."
              description="You have exceeded the maximum number of lease terms."
            />
          </div>

          <div style={{marginTop:"25px", marginBottom:"30px"}}>
            <button className="btn btn-danger" onClick={() => setNumTermShow(false)}>Close</button>
          </div>
        </Modal.Body>
      </Modal>
      
    </div>
  )
}

export default AddPost;
